<template>
    <div class="main-content-area">
        <div class="main-content-playground">
            
            <div style="
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                z-index: 2;
                font-size:25px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-color: rgba(255,255,255,0.6);
                border:1px solid white;
                padding: 20px;
                border-radius: 3px;
                ">
                    <div>
                       Under maintenance
                    </div>
                    <div>
                    <DataLoading />
                    </div>
            </div>

            <v-img
                lazy-src="background.jpg"
                src="background.jpg"
                :cover=true
                justify="center"
                height='100vh'
                style="position: absolute; left:0;top:0;right: 0px; bottom: 0; z-index: 1;"
            ></v-img>
            
            <v-img
                lazy-src="logo.png"
                src="logo.png"
                width="40px"
                style="position: absolute; right: 20px; top: 20px; z-index: 1;"
            ></v-img>
        </div>
    </div>
</template>

<script setup>

    //IMPORTS
    import { reactive } from 'vue'
    import { useAppStore } from '@/stores/app'
    import { useRouter } from 'vue-router';
    import { App } from '@capacitor/app';
    import { Device } from '@capacitor/device';
    import { Capacitor } from '@capacitor/core';



    //INJECTIONS
    const router = useRouter();
    
    

    //CONSTANTS
    const store = useAppStore()


    
    //METHODS
    
</script>

<script>
export default {
    name: 'MaintenancePage',
    customOptions: {}
}
</script>