<template>
    <div class="ag-floating-filter-input">
        <input 
            type="text" 
            v-model="currentValue" 
            @keyup="onInputBoxChanged" 
            class="ag-input-field-input ag-text-field-input"
            style="height:45%"
        />
    </div>
</template>
    
<script>


    //IMPORTS
    import { ref, watch } from 'vue';    
    


    export default {
        name: 'GridSelectFloatingFilter',
		setup(props, context){    
            //PROPS



            //CONSTANTS
            const currentValue=ref(null);

            

            //VARIABLES
            let justUpdatedParentFilter = false;



            //WATCHERS
            /*watch(currentValue, (newCurrentValue) => {
                if (newCurrentValue===null){
                    currentValueFormatted.value = '';
                }else{
                    //Use the value formatter if there is one set
                    if (props.params.column.colDef.valueFormatter){
                        let obj = {
                            colDef: props.params.column.colDef,
                            value: newCurrentValue
                        }
                        currentValueFormatted.value = props.params.column.colDef.valueFormatter(obj);
                    }else{
                        currentValueFormatted.value = newCurrentValue;
                    }
                }
            })*/

            

            //METHODS
            const onInputBoxChanged = () => {
                justUpdatedParentFilter = true;
                if (currentValue.value === null) {
                    // clear the filter
                    props.params.parentFilterInstance(instance => {
                        instance.onFloatingFilterChanged(null, null);
                    });
                    return;
                }
                props.params.parentFilterInstance(instance => {
                    instance.onFloatingFilterChanged(currentValue.value);    //Defined in parent filter
                });
            }
            
            const onParentModelChanged = (parentModel) => {
                if (justUpdatedParentFilter){
                    justUpdatedParentFilter = false;
                    return;
                }else{
                    let val= parentModel ? parentModel : null;
                    // When the filter is empty we will receive a null value here
                    if (!val) {
                        currentValue.value = null;
                    } else{
                        //If val and currentValue are equal then it means that the update was comming from this floating filter so no need to reupdate with the same value.
                        //Updating this floating filter updates the parent filter which updates the floating filter creating a circular call
                        currentValue.value = val;
                    }
                }
            }
            
            const afterGuiAttached = (params) => {
                //
            }

            return {
                currentValue,
                onInputBoxChanged,
                onParentModelChanged,
                afterGuiAttached
            }
        }
    }
</script>