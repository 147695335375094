<template>
    <v-text-field
        v-model="value"
        :ref="'input'" 
        label=""
        variant="contained" 
        class="positionAdjust"
        style="font-size: 14px"
        tabIndex="-1"
    ></v-text-field>
</template>

<script>
    import {ref, onMounted, onUnmounted, watch, nextTick} from 'vue';
    import { useUtilities } from  '@/composables/useUtilities';



    export default {
        name: 'GridTextEditor',
        setup(props, context){



            //COMPOSABLES
            const { utilities } = useUtilities();


            //CONSTANTS
            const value=ref('');
            const lastValue=ref('');
            const regex = ref(new RegExp(props.params.pattern));
            const highlightAllOnFocus=ref(true);
            
            const input = ref(null)
            
            const getValue = () => {
                if (value.value=="" && props.params.emptyStringIsNull==true){
                    return null;
                }
                return value.value;
            };

            const setInitialState = (params) => {
                let startValue;
                highlightAllOnFocus.value = true;

                if (params.key === 'Backspace' || params.key === 'Delete') {
                    // if backspace or delete pressed, we clear the cell
                    startValue = '';
                } else if (params.charPress) {
                    // if a letter was pressed, we start with the letter
                    startValue = params.charPress;
                    highlightAllOnFocus.value = false;
                } else {
                    // otherwise we start with the current value
                    startValue = params.value==null ? '' : params.value;
                    if (params.key === 'F2') {
                        highlightAllOnFocus.value = false;
                    }
                }

                value.value = startValue;
                lastValue.value = startValue;
            };

            const isCancelAfterEnd = () => {
                return false;
            }

            /*const onKeyUp = (event) => {
                if (event.keyCode===13){
                    runOnUpdateCallback();
                }*/
                /*if (event.keyCode===8 || event.keyCode===46){
                    lastValue.value = value.value;
                }else{*/
            /*}*/

            watch(value, (newValue, oldValue) => {
                let partialMatchRegex = regex.value.toPartialMatchRegex();
                let result = partialMatchRegex.exec(newValue);
                let match = regex.value.exec(input) ? "F" : result && result[0] ? "P" : "N";  //F - full match, P - Partial match, N - No match
                if (match==='F' || match==='P'){
                    lastValue.value = newValue;
                    nextTick(() => {
                        runOnUpdateCallback(oldValue, newValue);
                    })
                }else{
                    if (newValue==''){
                        lastValue.value = newValue;
                    }else{
                        nextTick(()=>{
                            value.value = oldValue;
                        });
                    }
                }
            })
            
            //This method is called when pressing the TAB key when editing
            const activateCellEditor = () => {
                input.value.focus();
            }
            
            onMounted(() => {
                setInitialState(props.params);
            })
            onUnmounted(() => { });
            
            const runOnUpdateCallback = (oldValue, newValue) => {
                if (typeof props.params.onUpdate==='function'){
                    let processedValue = props.params.onUpdate(oldValue, newValue);
                    if (newValue != processedValue){
                        value.value = processedValue;
                        lastValue.value = value.value;
                    }
                }
            }
            
            
            //CODE
            RegExp.prototype.toPartialMatchRegex = utilities.toPartialMatchRegex;

            return{
                value,
                getValue,
                input,
                activateCellEditor,
                runOnUpdateCallback
            }
        }
    }
</script>

<style>
    .positionAdjust{
        height: 100%;
        width:100%;
        background-color: white;
    }
    .positionAdjust .v-input__control{
        width: 100%;
        height: 100%;
        display: flex;
    }
    .positionAdjust .v-input__control .v-field{
        box-shadow: unset;
    }
    .positionAdjust .v-input__control .v-field__field{
        min-height: unset;
    }
    .positionAdjust .v-input__control .v-field__field .v-field__input{
        width: 100%;
        height: 100%;
    }
    .positionAdjust .v-input__details{
        display: none;
    }
    .positionAdjust .v-field__input input {
        margin: 0 !important;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 14px;
    }    
</style>