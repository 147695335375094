<template>
    <div v-if="theType=='1'" v-show="delayShow" class="preloader-1">
        <img :src="Preloader" alt="preloader" />
    </div>
    <div v-if="theType=='2'" v-show="delayShow"  class="preloader-2">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
        <img class="globe" :src="Globe" alt="logo" />
        <img class="globe" :src="Spikes" alt="logo" />
    </div>
    <div v-if="theType=='3'" v-show="delayShow"  class="preloader-3">
        <img :src="Logo" alt="logo" />
    </div>
    <div v-if="theType=='4'" v-show="delayShow"  class="preloader-3">
        <img :src="Globe" alt="logo" />
        <img :src="Spikes" alt="logo" />
    </div>
</template>

<script setup>
    //IMPORTS
    import { ref, defineProps, computed, onMounted } from 'vue'
    import Preloader from '@/assets/ripple-thin.svg'
    import Logo from '@/assets/logo.svg'
    import Globe from '@/assets/globe.svg'
    import Spikes from '@/assets/spikes.svg'
    
    
    
    //PROPS
    const props = defineProps({
        type: {
            type: Number,
            required: false,
            default: 2
        },
        delay: {
            type: Number,
            required: false,
            default: 0
        }
    });
    
    
    
    //CONSTANTS
    const delayShow = ref(false);
    const theType = computed(() => {
        if (props.params){
            return props.params.type;
        }
        return props.type;
    });



    //EVENTS
    onMounted(() => {
        if (props.delay){
            setTimeout(function(){
                delayShow.value = true;
            }, props.delay);
        }else{
            delayShow.value = true;
        }
    })

</script>