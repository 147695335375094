export default {
    install: function (Vue, user) {

        //To use the directive
        //v-user-can="'API-Read"

        //To use the helper
        //v-if="$user_can('API-Read')"

        //USER_CAN
        //Directive
        let userCan = function(el, params, vnode, prevNode){
            let permission = params.arg;
            let modifier = params.modifiers;
            let value = null;
            let model = null;
            if (typeof params.value=='object' ){
                value = params.value.permission;
                model =  params.value.model;
            }else{
                value = params.value;
            }
            if (!user.permissions.includes(value)) {
                if (modifier.disable) {
                    //Disable
                    vnode.el.disabled = true;
                } else {
                    //Hide
                    vnode.el.hidden = true;
                }
            }else{
                vnode.el.hidden = true;
            }
            return vnode.el;
        }

        Vue.directive('user-can', {
            created: userCan,
            updated: userCan
        });
        //Helper
        Vue.config.globalProperties.$user_can= (permission, modifiers, value) => {
            return (!user.permissions.includes(permission));
        };



        //USER_IS
        //Directive
        let userIs = function(el, params, vnode, prevNode){
            let permission = params.arg;
            let modifier = params.modifiers;
            let role = params.value;
            if (!user.roles.includes(role)) {
                if (modifier.disable) {
                    //Disable
                    vnode.el.disabled = true;
                } else {
                    //Hide
                    vnode.el.hidden = true;
                }
            }else{
                vnode.el.hidden = true;
            }
            return vnode.el;
        }

        Vue.directive('user-is', {
            created: userIs,
            updated: userIs
        });
        //Helper
        Vue.config.globalProperties.$user_is= (permission, modifiers, value) => {
            return (!user.roles.includes(permission));
        };

    }
}

function commentNode(el, vnode) {
    const comment = document.createComment(' ')

    Object.defineProperty(comment, 'setAttribute', {
        value: () => undefined
    })

    vnode.text = ' '
    vnode.elm = comment
    vnode.isComment = true
    vnode.context = undefined
    vnode.tag = undefined
    //vnode.data.directives = undefined

    if (vnode.componentInstance) {
        vnode.componentInstance.$el = comment
    }

    if (el.parentNode) {
        el.parentNode.replaceChild(comment, el)
    }
}