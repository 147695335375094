<template>
    <div class="main-content-area" ref="focusTarget">
        <div class="main-content-toolbar">
            <v-toolbar :height="50">
            </v-toolbar>
            <v-toolbar :height="50">
                History
            </v-toolbar>
            <v-toolbar :height="50">
                <v-btn icon="mdi-close-thick" size="small" variant="text" @click="$emit('close')"></v-btn>
            </v-toolbar>
        </div>

        <div class="main-content-playground">
            <ag-grid-vue ref="historyGrid" style="width: 100%; height: 100%;" class="ag-theme-alpine"
                :columnDefs="columnDefs" :defaultColDef="defaultColDef" :components="components" :rowData="data.rowData"
                :pinnedTopRowData="data.pinnedTopRowData" :gridOptions="gridOptions" :editType="editType">
            </ag-grid-vue>
        </div>
        <div class="main-content-footer">
            <AppMessageNotifications />
            <v-toolbar :height="40">
            </v-toolbar>
            <v-toolbar :height="40">
                <div style="display: flex;justify-content: right;align-items: center;"><strong>Total records:</strong>
                    &nbsp; {{ totalHistoryRecords }}
                </div>
            </v-toolbar>
        </div>
    </div>
</template>

<script setup>



//IMPORTS
import { inject, toRaw, ref, reactive, onMounted, onUnmounted, nextTick, defineEmits, defineProps, getCurrentInstance, computed, onActivated, onDeactivated } from 'vue';
import { useAppStore } from '@/stores/app';
import { useCommon } from '@/components/aggrid/common/useCommon';
// If focusTrap is used then one can't select values from select boxes because these are appended to the body which are outside of the dialog window
//import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { useAdvancedAxios } from '@/composables/useAdvancedAxios';
import { AgGridVue } from "ag-grid-vue3";
import { useKeyboard } from '@/composables/useKeyboard';
import { useUtilities } from '@/composables/useUtilities';
import Papa from 'papaparse'
import GridDateFilter from '@/components/aggrid/filters_new/GridDateFilter';



//PROPS
const store = useAppStore();

const props = defineProps({
    'history': {
        type: Object,
        required: true
    }
});



//REFERENCES
const historyGrid = ref(null);



//INJECTIONS
const axios = inject('axios')
const storage = inject('storageManager');
const dayJs = inject("dayJs")



//EMITS
const emits = defineEmits(['close']);



//VARIABLES
let gridApi = null;
let gridApi2 = ref(null);
let columnApi = null;
let allColumnIds = [];
let instance = ref(getCurrentInstance());
let deactivatingCellEditors = false;



//CONSTANTS
const componentName = getCurrentInstance().type.name;
const componentId = getCurrentInstance().uid;
const editType = 'fullRow';
const components = {
    AgGridVue
};
const data = reactive({
    rowData: null,
    pinnedTopRowData: [],
    pinnedBottomRowData: []

})
const fields = [

];
const state = reactive({
    dialog: false,
    gridReady: false,
    firstVisibleColumn: null
})
const triggerButtonsUpdate = ref(true);



//COMPUTED
const totalHistoryRecords = computed(() => {
    return data.rowData ? data.rowData.length : '';
})



//COMPOSABLES
const { common } = useCommon(historyGrid, state, gridApi2);
const { advancedAxios } = useAdvancedAxios(axios);
const { keyboard } = useKeyboard();
const { utilities } = useUtilities(store);

const historyDefinition = () => {
    let originalColumnDefs = [];
    toRaw(props.history.columnDefs).forEach(item => {
        originalColumnDefs.push({ ...item });
    });
    let newColumnDefs = [
        ...[
            {
                pinned: 'left',
                headerName: 'Updated',
                field: 'history_updated_at',
                valueFormatter: function (params) {
                    if (utilities.isDateTimeValid(params.value)) return utilities.formatDateTimeSecondMillisecond(params.value);
                    return params.value;
                },
                //Filter
                filter: GridDateFilter,
                filterParams: {}
            },
            {
                pinned: 'left',
                headerName: 'Updated by User',
                field: 'history_updated_by',
                valueFormatter: function (params) {
                    return params.value ?? '';
                }
            }
        ],
        ...originalColumnDefs
    ];
    for (let i in newColumnDefs) {
        if (newColumnDefs[i].field == 'id') {
            newColumnDefs[i].hide = true;
        }
        newColumnDefs[i].editable = false;

        //We need to set the cellStyle here and not in the defaultColDef because if there is any cellStyle set on columns it takes precedence and default cel style is not applied
        newColumnDefs[i].originalCellStyle = newColumnDefs[i].cellStyle ? newColumnDefs[i].cellStyle : {};
        newColumnDefs[i].cellStyle = function (params) {
            let originalCellStyle = typeof newColumnDefs[i].originalCellStyle === 'function' ? newColumnDefs[i].originalCellStyle(params) : newColumnDefs[i].originalCellStyle;
            let nextNode = gridApi.getDisplayedRowAtIndex(params.rowIndex + 1);
            if (params.colDef.field == 'history_updated_at' || params.colDef.field == 'history_updated_by') {
                return null;
                //return { backgroundColor: '#d7e2ec', border: '1px solid #b3c3dd' };
            } else {
                if (nextNode && params.value !== nextNode.data[params.colDef.field]) {
                    return { ...originalCellStyle, ...{ backgroundColor: '#e8ded5', border: '1px solid #dbcbbd' } };
                } else {
                    return originalCellStyle;
                }
            }
        }
        //---------
    }

    //Add the overrides particular for each grid from where the history grid is triggered
    if (props.history.columnDefsOverride) {
        let index = null;
        for (let i in props.history.columnDefsOverride) {
            index = newColumnDefs.findIndex(obj => obj.field === props.history.columnDefsOverride[i].field);
            if (index) {
                newColumnDefs[index] = {
                    ...newColumnDefs[index],
                    ...props.history.columnDefsOverride[i]
                }
            }
        }
    }

    return newColumnDefs;
};

const columnDefs = ref([])
const defaultColDef = reactive({
    ...common.defaultColDef, ...{
        floatingFilter: true,
        floatingFilterComponentParams: {
            suppressFilterButton: false
        }
    }
});
const gridOptions = /*reactive(*/{
    ...common.gridOptions,
    overlayNoRowsTemplate: "<strong>No history</strong>",
    noRowsOverlayComponent: null,
    noRowsOverlayComponentParams: {
        click: () => { gridApi.hideOverlay(); }
    },
    onCellContextMenu: (params) => {
        //common.onCellContextMenu(params, gridApi, state);
    },

    onGridReady: (params) => {
        gridApi = params.api;
        gridApi2.value = params.api;
        columnApi = params.columnApi;
        common.onGridReady(params, historyDefinition, columnDefs, state, populateGridWithData);
    },
    onRowDataChanged: () => {
        common.onRowDataChanged(columnApi);
    },
    onRowEditingStarted: (params) => {
        triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
        common.onRowEditingStarted(params, state, componentId);
    },
    onRowEditingStopped: (params) => {
        triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
    },
    onRowClicked: (params) => {
        if (params.rowPinned == 'top') {
            gridApi.startEditingCell({ rowIndex: params.rowIndex, colKey: state.firstVisibleColumn, rowPinned: 'top' });
        }
    },
    onRowSelected: (params) => {
        //
    },
    onSelectionChanged: (params) => {
        triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
    },
    onRowDoubleClicked: (params) => {
    },
    onCellMouseDown: (params) => {
        common.onCellMouseDown(params, state);
    },
    tabToNextCell(params) {
        return common.tabToNextCell(params, gridApi);
    },
    onBodyScroll: (event) => {
        if (!deactivatingCellEditors && event.direction == 'horizontal') {
            deactivatingCellEditors = true;
            common.deactivateAllCellEditors(gridApi);
            setTimeout(function () { deactivatingCellEditors = false; }, 2000);    //We do this so we don't trigger this method too many times and because bodyScrollEnd is not being triggered
        }
    },
    onBodyScrollEnd: (event) => {
        //This is currently not working but we will use it once the bug it's fixed by ag-grid developers
        deactivatingCellEditors = false;
    }
}



//EVENTS
onActivated(() => {
    keyboard.addListener(componentId, onKeyPressHandler);
    keyboard.activate(componentId);
});
onDeactivated(() => {
    keyboard.removeListener(componentId);
});
onMounted(() => {
    keyboard.addListener(componentId, onKeyPressHandler);
    keyboard.activate(componentId);
});
onUnmounted(() => {
    gridApi = null;
    gridApi2.value = null;
    keyboard.removeListener(componentId);
})



//METHODS
const onKeyPressHandler = (event) => {
    if (state.containersDialog === false) {
        if (event.shiftKey) {
            if (event.key == '+') {
                //
            }
        } else {
            common.onKeyPressHandler(event, gridApi, data, state);
        }
    }
}

const openFileDialog = async () => {
    return new Promise((resolve, reject) => {
        let input = document.createElement('input');
        input.type = 'file';
        input.onchange = (e) => {
            let file = e.target.files[0];
            resolve(file);
        }
        input.click();
    });
}


const populateGridWithData = () => {
    state.gridReady = false;
    setTimeout(() => {
        gridApi.showLoadingOverlay();
    });
    common.normalServerReload('/history/' + props.history.model + '/' + props.history.record.id, axios, state, gridApi2, fields, store, modelSubmodelMap, columnDefs, prepareRecord, common.prepareListRecord, data, onSetupGrid);
}

const onSetupGrid = (response) => {
    /*console.log('RESPONSE');
    console.log(response);*/

    /*if (response.data.models.history.length>0){
        let columns = [];
        for (let index in response.data.models.history[0]){
            columns.push({
                headerName: index, field: index
            });
        }
        columnDefs.value = columns;
    }*/
    state.gridReady = true;
    triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
}

const toggleFilterToolbar = () => {
    defaultColDef.floatingFilter = !defaultColDef.floatingFilter;
}

const numberComparator = (a, b) => {
    return a - b;
}

const validateRecord = (params) => {
    return {
        valid: true
    }
}

const prepareRecord = (item) => {
    return {
        ...props.history.prepareRecord(item),
        ...{
            history_updated_at: item.history_updated_at,
            history_updated_by: item.history_updated_by
        }
    };
}

const modelSubmodelMap = {
    model: {
        'history': { prepareRecord: prepareRecord, target: () => { if (data.rowData === null) { data.rowData = []; } return data.rowData } }
    },
    submodels: {
    }
};

</script>

<script>
export default {
    name: 'HistoryList',
    customOptions: {}
}
</script>