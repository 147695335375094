<template>
    <div class="custom-tooltip" ref="root">

        <div v-if="loading" ref='tooltiper' class="dialog-container no-center" :style="{ zIndex: zIndex, display: 'flex' }">
            <div class="dialog-wrapper">
                <div class="center-flex">
                    <DataLoading :type=2 :delay="1000" />
                </div>
            </div>
        </div>

        <div v-if="type == 'grid' && !loading" ref='tooltiper' class="dialog-container"
            :style="{ zIndex: zIndex, display: 'flex' }">
            <div class="dialog-wrapper" :style="{ 'width': 'auto', 'height': 'auto' }">
                <div class="dialog-header">
                    <div class="dialog-toolbar-left">
                    </div>
                    <div class="dialog-title">
                        {{ title }}
                    </div>
                    <div class="dialog-toolbar-right">
                    </div>
                </div>
                <div class="dialog-content">
                    <div class="center-flex">
                        <ag-grid-vue ref="theGrid" style="width: 30vw; height: 40vh;" class="ag-theme-alpine"
                            :columnDefs="columnDefs" :defaultColDef="defaultColDef" :components="components"
                            :rowData="rowData" :gridOptions="gridOptions">
                        </ag-grid-vue>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="(type == 'text' || type == 'simple') && !loading" ref='tooltiper' class="dialog-container no-center"
            :style="{ zIndex: zIndex, display: 'flex' }">
            <div class="dialog-wrapper" :style="{ 'width': 'auto', 'height': 'auto' }">
                <div class="center-flex">
                    <v-table v-if="type == 'text'">
                        <tbody>
                            <tr v-for="(item, index) in rowData" :key="item[0]">
                                <td :style="{ whiteSpace: 'nowrap' }">{{ index }}</td>
                                <td>{{ item }}</td>
                            </tr>
                        </tbody>
                    </v-table>
                    <div v-if="type == 'simple'">
                        <v-table>
                            <tbody>
                                <tr>
                                    <td>{{ props.params.value }}</td>
                                </tr>
                            </tbody>
                        </v-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
//IMPORTS
import { ref, reactive, onBeforeMount, onMounted, onUnmounted, onActivated, onDeactivated, defineProps, inject } from 'vue';
import { AgGridVue } from "ag-grid-vue3";
import jQuery from "jquery";
import _ from 'lodash';



//CONSTANTS
const $ = jQuery;
const props = defineProps({
    params: {}
})
const columnDefs = ref([]);
const defaultColDef = reactive({
    resizable: true,
    editable: false,
    sortable: true,
    filter: false,
    floatingFilter: false,
    suppressMenu: true,
    floatingFilterComponentParams: {
        suppressFilterButton: true
    }
});
const gridOptions = {
    ...{
        suppressLoadingOverlay: true,
        onGridReady: (params) => {
            gridApi = params.api;
            columnApi = params.columnApi;
            gridApi.sizeColumnsToFit();
        },
        defaultColDef: {
            sortable: true,
        },
        sortModel: [
            { colId: 'unit_number', sort: 'asc' }
        ],
    },
    ...(props.params.gridOptions ? props.params.gridOptions : {})
}
const type = ref(null);
const rowData = ref(null);
const title = ref(null);
const subtitle = ref(null);
const zIndex = ref(0);
const components = {
    AgGridVue
};
const data = ref(null);
const loading = ref(false);


//REFERENCES
const root = ref();
const tooltiper = ref();
const theGrid = ref();



//INJECTIONS
const axios = inject('axios');



//VARIABLES
let gridApi = null;
let columnApi = null;
let cell = null;


onBeforeMount(() => {
    let dat = null;

    if (props.params.type) {
        type.value = props.params.type;
    }
    if (props.params.title) {
        title.value = props.params.title;
    }


    //type == 'grid'
    if (type.value === 'grid') {
        columnDefs.value = props.params.columns;

        if (props.params.source === 'data') {
            if (typeof props.params.sourceData === 'function') {
                dat = props.params.sourceData(props.params.data);
            } else {
                dat = props.params.sourceData;
            }
            processData(dat);
        } else if (props.params.source === 'attribute') {
            dat = props.params.source[props.params.data[props.params.sourceData]];
            processData(dat);
        } else if (props.params.source === 'url') {
            loading.value = true;
            let url = typeof props.params.sourceData === 'function' ? props.params.sourceData(props.params.data) : props.params.sourceData;
            if (url !== null) {
                axios.get(url).then(response => {
                    dat = (typeof props.params.extractData === 'function') ? props.params.extractData(response) : response;
                    processData(dat);
                    loading.value = false;
                })
                    .catch(error => {
                        loading.value = false;
                    });
            }
        }
    }


    //type == 'text'
    if (type.value === 'text') {

        if (props.params.source === 'data') {
            if (typeof props.params.sourceData === 'function') {
                dat = props.params.sourceData(props.params.data);
            } else {
                dat = props.params.sourceData;
            }
            processData(dat);
        } else if (props.params.source === 'attribute') {
            if (props.params.sourceData === '') {
                dat = props.params.data;
            } else {
                dat = props.params.data[props.params.sourceData];
            }
            processData(dat);
        } else if (props.params.source === 'url') {
            loading.value = true;
            let url = typeof props.params.sourceData === 'function' ? props.params.sourceData(props.params.data) : props.params.sourceData;
            if (url !== null) {
                axios.get(url).then(response => {
                    dat = (typeof props.params.extractData === 'function') ? props.params.extractData(response) : response;
                    processData(dat);
                    loading.value = false;
                })
                    .catch(error => {
                        console.log('Errored');
                        loading.value = false;
                    });
            }
        }
    }

})

onMounted(() => {
    if (props.params.type === 'grid') {
        let gridApi = props.params.api;
        const rowNode = gridApi.getRowNode(props.params.data.uniqueId);
        cell = gridApi.getCellRendererInstances({
            rowNodes: [rowNode]
        });

        cell = cell[0].eGui;
        cell.addEventListener('wheel', onScrollHandler);
    }
})

onUnmounted(() => {
    if (props.params.type === 'grid') {
        cell.removeEventListener('wheel', onScrollHandler);
    }
})

onActivated(() => { })

onDeactivated(() => { })



//METHODS
function getMaxZIndex() {
    let max = Math.max(...Array.from(document.querySelectorAll('#app *:not(*[always-on-top])'), el => parseFloat(window.getComputedStyle(el).zIndex)).filter(zIndex => !Number.isNaN(zIndex)), 0);
    return max < 1000 ? 1000 : max;
}

const onScrollHandler = (event) => {
    let deltaY = event.wheelDeltaY;
    if (theGrid.value) {
        const gridScroll = theGrid.value.$el.querySelector('.ag-body-viewport');
        gridScroll.scrollTo({
            top: gridScroll.scrollTop - deltaY,
            behavior: 'smooth'
        });
    }
    event.stopPropagation();
    event.preventDefault();
}

let processData = function (data) {
    let newData = [];
    if (Array.isArray(data)) {
        data.forEach(item => {
            newData.push(mapIt(item));
        });
        rowData.value = newData;
    } else {
        rowData.value = mapIt(data);
    }
}

let mapIt = function (mapItem) {
    let newItem = {};
    let theVal = null;

    for (let item of props.params.columns) {
        theVal = mapItem[item['field']]
        if (type.value === 'text' && item.valueFormatter) {
            theVal = item.valueFormatter({ formatterParams: item.formatterParams, value: theVal });
        }
        if (props.params.type === 'text') {
            newItem[item['headerName']] = theVal;
        }
        if (props.params.type === 'grid') {
            newItem[item['field']] = theVal;
        }
    }
    return newItem;
}

</script>