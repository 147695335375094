<template>
    <!--<div class="main-content-area">-->
    <div class="main-content-area" v-touch:swipe.left="internalButton">
        <div class="main-content-toolbar" v-touch:swipe.bottom="reloadList">
            <v-toolbar>
                <v-btn>
                    <div class="avatar-wrapper">
                        <v-avatar size="48">
                            <v-icon size="36" color="white">mdi-account-circle</v-icon>
                        </v-avatar>
                    </div>
                    <div class="greeting-text">
                        {{ greeting }}
                        <br />
                        {{ store.user.firstname }} {{ store.user.lastname }}
                    </div>
                    <v-menu start activator="parent" class="custom-menu">
                        <v-list class="custom-list">
                            <v-list-item v-for="(item, index) in data.menu" :key="index" :value="index"
                                class="custom-list-item" @click="item.action">
                                <v-list-item-title class="custom-list-item-title">
                                    <i :class="['custom-icon', item.icon]"></i>
                                    {{ item.text }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-btn>
            </v-toolbar>

            <v-toolbar>
                <v-toolbar-title class="text-center">
                    <div class="toolbar-title">
                        <h3>CONTAINER STACKER OPERATIONS</h3>
                        <span class="job-status">{{ completedTasks ? "COMPLETED JOBS" : "ACTIVE JOBS" }}</span>
                    </div>
                </v-toolbar-title>
            </v-toolbar>

            <v-toolbar class="flex text-right">
                <v-spacer></v-spacer>
                <v-btn @click="internalButton">
                    <div class="internal-text">
                        Internal
                        <br />
                        Movement
                    </div>
                    <div class="avatar-wrapper">
                        <v-avatar size="48">
                            <v-icon size="36" color="orange">mdi-cached</v-icon>
                        </v-avatar>
                    </div>
                </v-btn>
            </v-toolbar>
        </div>

        <!--<div class="main-content-toolbar">
            <v-toolbar>
                <v-btn v-if="completedTasks" :disabled ="enabledDisabled" icon="mdi-playlist-play" size="x-large" variant="text" @click="changeCompletedContainers"></v-btn>
                <v-btn v-if="!completedTasks" :disabled ="enabledDisabled" icon="mdi-playlist-check" size="x-large" variant="text" @click="changeCompletedContainers"></v-btn>
                <v-btn :disabled ="enabledDisabled" icon="mdi-cached" size="x-large" variant="text" @click="internalButton"></v-btn>
                <v-btn :disabled ="enabledDisabled" icon="mdi-refresh" size="x-large" variant="text" @click="reloadList"></v-btn>
                <v-btn v-user-is="'System Administrator'" v-if="completedTasks && !undoEnabled" icon="mdi-arrow-u-left-top" size="x-large" variant="text" @click="toggleUndo"></v-btn>
                <v-btn v-user-is="'System Administrator'" v-if="completedTasks && undoEnabled" icon="mdi-close" size="x-large" variant="text" @click="toggleUndo"></v-btn>
            </v-toolbar>
        </div>-->

        <div class="main-content-playground">
            <div style="width: auto;height: 100%; text-align: center; margin: auto;">
                <ag-grid-vue id="tasksGrid" style="width: 100%; height: 100%;" class="ag-theme-alpine"
                    :columnDefs="columnDefs" :defaultColDef="defaultColDef" :components="components" :rowData="data.rowData"
                    :gridOptions="gridOptions" :rowHeight="70" :headerHeight="70" :floatingFiltersHeight="70">
                </ag-grid-vue>
            </div>
        </div>

        <div class="main-content-footer">
            <v-toolbar :height="40">
                <v-switch :input-value="completedTasks" @change="changeCompletedContainers" label="Completed Jobs"
                    style="width: 100%; margin-left: 10px; height: 55px;" color="light-green darken-1"
                    class="ag-theme-alpine">
                </v-switch>
            </v-toolbar>
            <AppMessageNotifications />
            <v-toolbar :height="40">
                <v-btn icon="mdi-circle" size="small" color="light-green darken-1" variant="text"></v-btn>
                <v-btn size="x-small" variant="text" @click="reloadList">
                    <h2>Status: online</h2>
                </v-btn>
            </v-toolbar>

        </div>


    </div>
</template>

<script setup>



//IMPORTS
import jQuery from "jquery";
import { inject, ref, reactive, onActivated, onDeactivated, onMounted, onBeforeMount, computed } from 'vue';
import { useAppStore } from '@/stores/app';
import DataLoading from '@/components/ui/DataLoading';
import GridIconRenderer from '@/components/aggrid/cell_renderers/GridIconRenderer';
import TaskSettingsRenderer from '@/components/aggrid/cell_renderers/TaskSettingsRenderer.vue';
import TaskRenderer from '@/components/yamax/TaskRenderer';
import InternalRenderer from '@/components/yamax/InternalRenderer';
import YamaxValidator from '@/validations/YamaxValidator';
import GridSelectEditorNeo from '@/components/aggrid/cell_editors/GridSelectEditorNeo';
import { useRouter } from 'vue-router';
import { useCommon } from '@/components/aggrid/common/useCommon';
import { AgGridVue } from "ag-grid-vue3";
import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { Capacitor } from '@capacitor/core';
import dayJs from 'dayjs';
import _ from 'lodash';



//INJECTIONS
const axios = inject('axios');
const router = useRouter();


//VARIABLES
let gridApi = null;
let columnApi = null;



//METHODS
const logout = () => {
    axios({
        method: 'post',
        url: '/logout',
    }).then(response => {
        store.logout();
        router.push({ name: 'LoginPage', params: { next: 'RsoApp' } });
    }).catch(function (error) { });
};

const loadSettingsPage = () => {
    router.push({ name: 'AppSettings' });
}
const reloadList = (event) => {
    if (common) {
        common.reloadHandler(state, data, populateGridWithData);
    }
}


//COMPUTED
const enabledDisabled = computed(() => {
    return undoEnabled.value;
});

const greeting = computed(() => {
    const now = new Date();
    const hours = now.getHours();

    if (hours >= 5 && hours < 11) {
        return "Good morning";
    } else if (hours >= 11 && hours < 15) {
        return "Good day";
    } else if (hours >= 15 && hours < 18) {
        return "Good afternoon";
    } else if (hours >= 18 && hours < 22) {
        return "Good evening";
    } else {
        return "Good night";
    }
});


const completedView = computed(() => {
    return !completedTasks.value ? true : false;
});

const normalView = computed(() => {
    return completedTasks.value ? true : false;
});

const hideCustomerColumn = computed(() => {
    // get the user permissions
    const userPermissions = store.user.permissions;
    // check if the user has the permission to view the customer column
    const hasPermission = userPermissions.includes('System Root/CSO Stacker App/Customer Column-Read');
    // return true if the user has the permission, false otherwise
    return !hasPermission;
});

const hidePropertiesColumn = computed(() => {
    // get the user permissions
    const userPermissions = store.user.permissions;
    // check if the user has the permission to view the properties column
    const hasPermission = userPermissions.includes('System Root/CSO Stacker App/Properties Column-Read');
    // return true if the user has the permission, false otherwise
    return !hasPermission;
});

const hideFacilitiesColumn = computed(() => {
    // get the user permissions
    const userPermissions = store.user.permissions;
    // check if the user has the permission to view the properties column
    const hasPermission = userPermissions.includes('System Root/CSO Stacker App/Facility Column-Read');
    // return true if the user has the permission, false otherwise
    return !hasPermission;
});

const hidePtiStatusColumn = computed(() => {
    // get the user permissions
    const userPermissions = store.user.permissions;
    // check if the user has the permission to view the properties column
    const hasPermission = userPermissions.includes('System Root/CSO Stacker App/PTI Status Column-Read');
    // return true if the user has the permission, false otherwise
    return !hasPermission;
});

const hideBuildYearColumn = computed(() => {
    // get the user permissions
    const userPermissions = store.user.permissions;
    // check if the user has the permission to view the properties column
    const hasPermission = userPermissions.includes('System Root/CSO Stacker App/Build Year Column-Read');
    // return true if the user has the permission, false otherwise
    return !hasPermission;
});

//CONSTANTS
const $ = jQuery;
const store = useAppStore()
const editType = 'fullRow';
const components = {
    AgGridVue
};
const data = reactive({
    rowData: null,
    pinnedTopRowData: [],
    pinnedBottomRowData: [],
    menu: [
        { text: 'Refresh', icon: 'mdi mdi-refresh', action: () => reloadList() },
        { text: 'About', icon: 'mdi mdi-information-outline', action: () => loadAbout() },
        //{ text: 'Settings', icon: 'mdi mdi-cog', action: () => loadSettingsPage() },
        { text: 'Logout', icon: 'mdi mdi-logout-variant', action: () => logout() },
    ],
})
const completedTasks = ref(false);
const undoEnabled = ref(false);

const state = reactive({
    firstVisibleColumn: null,
    showContainerSummary: false,

    gridReady: false
})



//COMPOSABLES
const { common } = useCommon();

const columnDefs = ref([])
const defaultColDef = reactive({
    filter: false,
    floatingFilter: false,
})

const gridOptions = /*reactive(*/{
    pagination: false,
    rowClassRules: {
        "unvalidated": params => !params.data.valid
    },
    loadingOverlayComponent: DataLoading,
    loadingOverlayComponentParams: {
        type: 2
    },

    onGridReady: (params) => {
        gridApi = params.api;
        columnApi = params.columnApi;

        columnDefs.value = [
            {
                headerName: 'Id', field: 'id',
                //minWidth: 100,
                editable: false,
                hide: true
            },
            {
                headerName: '', field: 'type',
                filter: false,
                cellRenderer: GridIconRenderer,
                minWidth: 50,
                maxWidth: 100,
                editable: false,
                cellStyle: { 'text-align': 'center' },
                cellRendererParams: {
                    'valueToUse': 'value',
                    'valueList': {
                        'return': { icon: 'mdi-truck-delivery', color: 'green darken-1', styles: 'font-size: 50px' },
                        'release': { icon: 'mdi-truck-delivery', color: 'red darken-1', styles: 'font-size: 50px; transform: scaleX(-1)' },
                        'other': { icon: 'mdi-cached', color: 'orange darken-1', styles: 'font-size: 50px; transform: scaleX(-1)' },
                    }
                }
            },
            {
                headerName: 'Unit Number', field: 'unit_number',
                cellStyle: { 'fontWeight': 'bold' },
                cellEditor: 'agTextCellEditor',
            },
            {
                headerName: 'Build Year', field: 'build_year', hide: hideBuildYearColumn,
                cellStyle: { 'flex-direction': 'column', 'white-space': 'normal' },
                cellEditor: 'agTextCellEditor',
            },
            {
                headerName: 'Facility/Depot', field: 'depot_id', hide: hideFacilitiesColumn,
                cellStyle: { 'flex-direction': 'column', 'white-space': 'normal' },
                cellEditor: 'agTextCellEditor',
            },
            {
                headerName: 'Licence Plate', field: 'mode_id',
                cellStyle: { 'fontWeight': 'bold' },
                cellEditor: 'agTextCellEditor',
            },
            {
                headerName: 'Customer', field: 'customer', hide: hideCustomerColumn,
                cellStyle: { 'flex-direction': 'column', 'white-space': 'normal' },
                cellEditor: 'agTextCellEditor',
            },
            {
                headerName: 'Container Type', field: 'iso_code',
                cellEditor: 'agTextCellEditor',
            },
            {
                headerName: 'Properties', field: 'proprieties', hide: hidePropertiesColumn,
                cellEditor: 'agTextCellEditor',
                cellStyle: { 'white-space': 'pre' }
            },
            {
                headerName: 'Settings', field: 'settings',
                cellRenderer: TaskSettingsRenderer,
                cellStyle: { 'white-space': 'pre' }
            },
            {
                headerName: 'PTI Status', field: 'pti_status', hide: hidePtiStatusColumn,
                cellStyle: { 'flex-direction': 'column', 'white-space': 'normal' },
                cellEditor: 'agTextCellEditor',
            },
            {
                headerName: 'Position', field: 'position', hide: completedView,
                cellEditor: 'agTextCellEditor',
            },
            {
                headerName: 'Created', field: 'created_at',
                cellRenderer: dateTimeCellRenderer,
                hide: normalView,
            },
            {
                headerName: 'Done', field: 'updated_at',
                cellRenderer: doneCellRenderer,
                hide: completedView,
            },
            {
                headerName: '', field: 'status',
                filter: false,
                cellRenderer: GridIconRenderer,
                minWidth: 50,
                maxWidth: 100,
                editable: false,
                cellStyle: { 'text-align': 'center' },
                cellRendererParams: {
                    'valueToUse': 'value',
                    'valueList': {
                        'open': { icon: 'mdi-arrow-right-drop-circle', color: 'green darken-1', styles: 'font-size: 50px' },
                        'in_progress': { icon: 'mdi-chart-donut-variant', color: 'orange darken-1', styles: 'font-size: 50px' },
                        'completed': { icon: 'mdi-check-circle', color: 'blue darken-1', styles: 'font-size: 50px' },
                        'deleted': { icon: 'mdi-delete-circle', color: 'red darken-1', styles: 'font-size: 50px' },
                    }
                }
            },
            {
                headerName: 'Container Data', field: 'container_data',
                cellEditor: 'agTextCellEditor',
                hide: true
            },
            {
                headerName: 'Reference Data', field: 'reference_data',
                cellEditor: 'agTextCellEditor',
                hide: true
            }
        ]
    },

    onRowClicked: (params) => {
        if (!undoEnabled.value) {
            if (params.data.status == 'open' || params.data.status == 'in_progress') {
                router.push({ name: 'TaskRenderer', params: { id: params.data.id } })
            } else {
                store.addNotificationMessage('Container is not open', 'error');
            }
        } else {
                axios({
                    method: "put",
                    url: "/api/yamaxTasks/" + params.data.id + "/revert",
                }).then(function (response) {
                    let delIndex = data.rowData.findIndex(o => o.id === params.data.id);
                    if (delIndex >= 0) {
                        data.rowData.splice(delIndex, 1);
                        gridApi.setRowData(data.rowData);
                    }
                }).catch(function () {

            });
        }
    },

    onRowDataChanged: (params) => {
        gridApi.sizeColumnsToFit();
    },
}


//EVENTS
onActivated(() => {
    populateGridWithData();
})



//METHODS
const toggleUndo = () => {
    undoEnabled.value = !undoEnabled.value;
    if (undoEnabled.value) {
        store.addNotificationMessage('Click tasks that you want to revert', 'info');
    }
}

const changeCompletedContainers = () => {
    completedTasks.value = !completedTasks.value;
    gridApi.setColumnDefs(columnDefs.value);
    reloadList();
}

const populateGridWithData = () => {
    state.gridReady = false;
    if (gridApi) {
        gridApi.showLoadingOverlay();
    }
    axios.get('/api/yamaxTasks').then(function (response) {
        gridApi.hideOverlay();
        setupGrid(response);
    }).catch(function (error) {     //!!! should be false
        gridApi.hideOverlay();
    });
}

const setupGrid = (response) => {
    state.gridReady = true;
    data.rowData = [];

    response.data.tasks.map(item => {

        if ((item.status != 'completed' && !item.deleted_at && completedTasks.value == false) || ((item.status == 'completed' || item.deleted_at) && completedTasks.value == true)) {

            if ((item.type == 'return' && item.task__stock_container) || (item.type == 'other' && item.task__stock_container)) {
                let container_condition = _.get(item, 'task__stock_container.report__report.damaged', '');
                let freightKind = _.get(item, 'task__stock_container.report__report.freight_kind', '');
                let buildYear = _.get(item, 'task__stock_container.report__report.construction_year', '');
                let ptiStatus = _.get(item, 'task__stock_container.report__report.machinery_info__machinery_info.pti_description', '');

                data.rowData.push({
                        valid: true,
                        id: item.id,
                        type: item.type,
                        mode_id: item.data,
                        customer: _.get(item, 'task__stock_container.shipping_line__company.name', '') || '',
                        iso_code: _.get(item, 'task__stock_container.survey_size_type__survey_size_type.code', '') || '',
                        proprieties:
                            (freightKind && freightKind.length > 0 ? (freightKind[0].toUpperCase() + freightKind.substring(1) + '\n') : '') +
                            (_.get(item, 'task__stock_container.report__report.cargo_condition', '') || '') + '\n' +
                            ((container_condition == '0' || container_condition == '2') ? 'Intact' : (container_condition == '1' ? 'Damaged' : '')) + '\n',
                        settings: {
                        color: (_.get(item, 'task__stock_container.color', '') || ''),
                        floor: (_.get(item, 'task__stock_container.floor', '') || ''),
                        temperature: (_.get(item, 'task__stock_container.temperature', '') || ''),
                        humidity: (_.get(item, 'task__stock_container.humidity', '') || ''),
                        ventilation: (_.get(item, 'task__stock_container.ventilation', '') || '')
                     },
                        status: item.deleted_at ? 'deleted' : item.status,
                        position: _.get(item, 'task__stock_container.position', '') || '',
                        created_at_date: new Date(item.created_at).toDateString(),
                        created_at_time: dayJs(item.created_at).format('HH:mm'),
                        stacker_name: _.get(item, 'task__stacker.name', '') || '',
                        completed_at_date: new Date(item.updated_at).toDateString(),
                        completed_at_time: dayJs(item.updated_at).format('HH:mm'),
                        depot_id: (_.get(item, 'task__company.name', '') || ''),
                        container_data: item.task__stock_container,
                        reference_data: item.task__reference,
                        build_year: buildYear,
                        pti_status: ptiStatus,
                        unit_number: _.get(item, 'task__stock_container.unit_number', '') || ''
                });
            }

            if (item.type == 'release' && item.task__reference) {
                let freightKind = _.get(item, 'task__reference.freight_kind', '');
                let buildYear = _.get(item, 'task__stock_container.report__report.construction_year', '');
                let ptiStatus = _.get(item, 'task__stock_container.report__report.machinery_info__machinery_info.pti_description', '');

                data.rowData.push({
                    valid: true,
                        id: item.id,
                        type: item.type,
                        mode_id: item.data,
                        customer: _.get(item, 'task__reference.operator__company.name', '') || '',
                        iso_code: _.get(item, 'task__reference.survey_size_type_id', '') || '',
                        proprieties:
                            (freightKind && freightKind.length > 0 ? (freightKind[0].toUpperCase() + freightKind.substring(1) + '\n') : '') +
                            (_.get(item, '.task__reference.grade_id', '') || '') + '\n' +
                            (_.get(item, '.task__reference.condition', '') || '') + '\n',
                        settings: {
                            color: (_.get(item, 'task__stock_container.color', '') || ''),
                            floor: (_.get(item, 'task__stock_container.floor', '') || ''),
                            temperature: (_.get(item, 'task__reference.release_stock_container__stock_container.temperature', '') || ''),
                            humidity: (_.get(item, 'task__reference.release_stock_container__stock_container.humidity', '') || ''),
                            ventilation: (_.get(item, 'task__reference.release_stock_container__stock_container.ventilation', '') || '')
                        },
                        status: item.deleted_at ? 'deleted' : item.status,
                        position: _.get(item, 'task__reference.release_stock_container__stock_container.position', '') || '',
                        created_at_date: new Date(item.created_at).toDateString(),
                        created_at_time: dayJs(item.created_at).format('HH:mm'),
                        stacker_name: _.get(item, 'task__stacker.name', '') || '',
                        completed_at_date: new Date(item.updated_at).toDateString(),
                        completed_at_time: dayJs(item.updated_at).format('HH:mm'),
                        depot_id: (_.get(item, 'task__company.name', '') || ''),
                        container_data: item.task__stock_container,
                        reference_data: item.task__reference,
                        build_year: buildYear,
                        pti_status: ptiStatus,
                        unit_number: _.get(item, 'task__stock_container.unit_number', '') || ''
                });
            }
        }
    });

}

const getNodeId = (data) => {
    return data.id;
}

const backButton = (params) => {
    router.push({ name: 'HomeScreen' });
};

const loadAbout = () => {
    let platform = Capacitor.getPlatform()
    if (platform == 'android') {
        (async () => {
                const info = await Device.getInfo();
                const appInfo = await App.getInfo();
                let data = "";
                data = data + "Version: " + appInfo.version + " \r\n Build: " + appInfo.build;
                alert(data);
        })();
    } else {
        store.addNotificationMessage('Information available when runnig in Android.', 'info');
    }
}

const dateTimeCellRenderer = (params) => {
    const date = params.data.created_at_date;
    const time = params.data.created_at_time;
    return `<div>${date}<br/>${time}</div>`;
}

const doneCellRenderer = (params) => {
    const stacker = params.data.stacker_name;
    const date = params.data.completed_at_date;
    const time = params.data.completed_at_time;
    return `<div>${stacker}</div><div>${date}<br/>${time}</div>`;
}

const internalButton = (params) => {
    router.push({ name: 'InternalRenderer' });
};

const validateRecord = (params) => {
    let ret = {
        valid: false,
        message: ''
    };

    ret.valid = true;
    return ret;
}
</script>

<style scoped>
.greeting-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 8px;
    text-align: left;
}

.internal-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 8px;
    text-align: right;
}

.avatar-wrapper {
    display: flex;
    align-items: center;
}

.custom-menu .v-menu__content {
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.custom-list {
    border-radius: 8px;
}

.custom-list-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.custom-list-item:last-child {
    border-bottom: none;
}

.custom-list-item-title {
    display: flex;
    align-items: center;
    font-weight: 500;
}

.custom-icon {
    font-size: 1.2rem;
    margin-right: 8px;
}

.toolbar-title {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    letter-spacing: 1.2px;
    color: #ffffff;
}

.job-status {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 0.9em;
    letter-spacing: 1px;
    color: #ffffff;
    margin-left: 8px;
}
</style>