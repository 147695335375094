
<template>
    <v-app>
        <v-card v-if="store.csrfReceived && inIframe===null && store.user.id!==null">
        <v-navigation-drawer expand-on-hover rail :width="200" :rail-width="50">
            <v-list height="50" style="padding:0;padding-top:5px;padding-left:7px;">
                <v-list-item style="padding:0;">
                    <v-avatar size="35">
                      <v-img src="./assets/logo-sc.png" alt="logo"></v-img>
                    </v-avatar>                    
                </v-list-item>
            </v-list>
            <v-list density="compact" nav style="padding:4px;">
                <v-list-item prepend-icon="mdi-page-next-outline" title="Reference Admin" value="myfiles" @click="router.push({ name: 'ReferenceList' });"></v-list-item>
                <v-list-item prepend-icon="mdi-wallet-travel" title="Stock List" value="stocklist" @click="router.push({ name: 'StockList' });"></v-list-item>
                <!--<v-list-item prepend-icon="mdi-cog" title="Settings" value="settings" @click="router.push({ name: 'SettingsPage' });"></v-list-item>-->
                <v-list-item v-user-is="'System Administrator'" prepend-icon="mdi-account-star" title="Admin" value="admin" @click="router.push({ name: 'AdminPage' });"></v-list-item>
                <v-list-item prepend-icon="mdi-logout-variant" title="Logout" value="logout" @click="logout"></v-list-item>
            </v-list>
        </v-navigation-drawer>
        </v-card>
        <v-main >
            <router-view v-slot="{ Component }">
                <keep-alive exclude="['LoginPage']">
                    <component :is="Component" />
                </keep-alive>
            </router-view>
        </v-main>
    </v-app>
</template>



<script setup>


    //IMPORTS
    import { ref, reactive, onMounted, inject } from 'vue'
    import "ag-grid-community/dist/styles/ag-grid.css";
    import "ag-grid-community/dist/styles/ag-theme-alpine.css";
    import { useAppStore } from '@/stores/app'
    import { useRouter } from 'vue-router';

    import ('./assets/sass/global.sass');


    //CODE
    let inIframe=ref(null);
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    if( urlParams.has('component') ){
        inIframe.value = urlParams.get('component');
    }    

    
    
    //INJECTIONS
    const axios=inject('axios')    
    const router = useRouter();
    

    
    //CONSTANTS
    const store = useAppStore()
    //Setup interceptors
    axios.setupInterceptors(store, router);
    
    const data=reactive({
        loading: true,
        dialog: null,
    })



    //EVENTS
    onMounted(() => {
        axios.get('/sanctum/csrf-cookie').then(response => {
            store.csrfReceived=true;
            router.push({ name: 'LoginPage', params: { next: (inIframe.value ? inIframe.value : 'ReferenceList') } });
        }).catch(function (error) { });
    })
  
  
  
    //METHODS
    const logout = () => {
        axios({
            method: "post",
            url: "/logout",
        }).then(function () {
            store.logout();
            router.push({ name: 'LoginPage', params: { next: 'ReferenceList' } });
        }).catch(function () {

        });              
    }



    //CODE
    if( urlParams.has('authToken') ){
        store.authToken = '?authToken='+urlParams.get('authToken');
    } 



</script>

