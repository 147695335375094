import useValidate from '@vuelidate/core';
import { helpers, required, maxLength } from '@vuelidate/validators';
import constants from '@/constants/constants';
import Validator from '@/validations/Validator';
import { inspection_criterias, types, freights } from '@/constants/constants';



class YamaxValidator extends Validator {

    constructor() {
        super();

        
        
        //CUSTOM VALIDATIONS
        this.isType = function(value){
            value.includes('return', 'release');
        };



        //VALIDATIONS
        this.validations = {
            depot_id: { 
                required: helpers.withMessage('<strong>Depot</strong> must be set', required)
            },
            iso_code: { 
                required: helpers.withMessage('<strong>Iso code</strong> must be set', required)
            }
        };
        
        
        
        this.$v = useValidate(this.validations, this.validationData);        
    }

}

export default new YamaxValidator();