<!-- CustomCellRenderer.vue -->
<template>
    <v-btn icon="mdi-eye" size="small" variant="text"
                    @click="handleDownload"></v-btn>
  </template>
  
  <script>
  export default {
    props: ['params'],
    methods: {
      handleDownload() {
        window.open(this.params.value, '_blank');
      }
    }
  }
  </script>
  