<template>
    <div class="main-content-area">
        <div class="main-content-toolbar">
            <v-toolbar :height="50">
                <v-btn icon="mdi-reload" size="small" variant="text" 
                    @click="common.reloadHandler(state, data, populateGridWithData)"
                    v-tippy="{ content: 'Reload container list' }"></v-btn>
                    <v-btn icon="mdi-pencil" size="small" variant="text" 
                       @click="editOption"
                       v-tippy="{ content: 'Edit selected reference' }"
                       ></v-btn>
                <v-btn icon="mdi-check" size="small" variant="text" :disabled="state.isEditing == 'no'"
                    @click="finishEditingOption" v-tippy="{ content: 'Finish editing container' }"></v-btn>
                <v-btn icon="mdi-content-save" size="small" variant="text" :disabled="!state.isDirty"
                    @click="saveSettings" v-tippy="{ content: 'Save changes' }"></v-btn>
                <v-menu :close-on-content-click=false transition="none">
                    <template v-slot:activator="{ props }">
                        <v-btn icon="mdi-cog" dark v-bind="props" v-tippy="{ content: 'Show / Hide columns' }"></v-btn>
                    </template>
                    <v-list>
                        <v-list-item v-for="(columnContent, i) in columnDefs" :key="i" active-color="primary"
                            style="cursor:pointer;" @click="columnDefs[i].hide = !columnDefs[i].hide;">
                            <template v-slot:prepend>
                                <v-icon v-show="columnContent.hide" large color="#4E6F8E"
                                    style="margin-right: 15px;">mdi-checkbox-blank-circle-outline</v-icon>
                                <v-icon v-show="!columnContent.hide" large color="#4E6F8E"
                                    style="margin-right: 15px;">mdi-checkbox-marked-circle</v-icon>
                                <div style="font-size: 14px;"
                                    v-html="columnContent.headerName != '' ? columnContent.headerName : columnContent.headerComponentParams.template">
                                </div>
                            </template>
                        </v-list-item>
                    </v-list>
                </v-menu>

            </v-toolbar>

            <v-toolbar :height="50">
                <v-btn icon="mdi-help-circle" size="small" variant="text"
                    v-tippy="{ content: 'Activate help system. <br>[ Development in progress ]' }"></v-btn>
                <AppNotifications />
            </v-toolbar>
        </div>
        <div class="main-content-playground">
            <!--Id is important for the few jquery selects that we are doing below in order not to affect another grid-->
            <ag-grid-vue 
                id="stockGrid" 
                ref="stockGrid" style="width: 100%; height: 100%;" 
                class="ag-theme-alpine"
                :columnDefs="columnDefs" 
                :defaultColDef="defaultColDef" 
                :rowData="data.rowData"
                :pinnedTopRowData="data.pinnedTopRowData" 
                :gridOptions="gridOptions" 
                :editType="editType" 
                @contextmenu="stopEditingOnRightclick">
            </ag-grid-vue>
        </div>

        <div class="main-content-footer">
            <AppMessageNotifications />
            <v-toolbar :height="50">
                Settings: <b>{{ rowCount }}</b>
            </v-toolbar>
        </div>

    </div>
</template>

<script setup>

const theValue = ref(1);
const updateTheValue = (data) => {
    theValue.value = data.value;
}


//IMPORTS
import jQuery from "jquery";
import { inject, toRaw, ref, nextTick, reactive, onActivated, onDeactivated, onMounted, onBeforeMount, getCurrentInstance, computed } from 'vue'
import { useAppStore } from '@/stores/app'
import GridTooltip from '@/components/aggrid/GridTooltip'
import { useUtilities } from  '@/composables/useUtilities';
import { jsPDF } from "jspdf";
import { useAdminPageDefinition } from '@/components/aggrid/definitions/useAdminPageDefinition';
import { useCommon } from '@/components/aggrid/common/useCommon';
import { useRouter } from 'vue-router';
import { useAdvancedAxios } from '@/composables/useAdvancedAxios';
import { AgGridVue } from "ag-grid-vue3";
import { useKeyboard } from '@/composables/useKeyboard';
import _ from 'lodash';
import KeypadInput from '@/components/ui/KeypadInput';



//REFERENCES
const stockGrid = ref(null);



//INJECTIONS
const axios = inject('axios');
const dayJs = inject("dayJs")
const router = useRouter();



//VARIABLES
let gridApi = null;
let gridApi2 = ref(null);
let columnApi = null;
let completedContainers = false;
let deactivatingCellEditors  = false;



//CONSTANTS
const componentId = getCurrentInstance().uid;
const fields = {};
const $ = jQuery;
const store = useAppStore()
const editType = 'fullRow';
const components = {
    AgGridVue
};
const data = reactive({
    rowData: null,
    pinnedTopRowData: [],
    pinnedBottomRowData: []
})
const state = reactive({
    isEditing: 'no',
    dialog: false,
    gridReady: false,
    document: null,
    isDirty: false
})
const keypadAllCharacters = ref(["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"]);
const keypadAllowedList = ref(['121123', '111']);


//CONSTANTS
const triggerButtonsUpdate = ref(true);



//COMPOSABLES
const { common } = useCommon(stockGrid, state, gridApi2);
const { advancedAxios } = useAdvancedAxios(axios);
const { getAdminPageDefinition } = useAdminPageDefinition(store, dayJs);
const { keyboard } = useKeyboard();
const { utilities } = useUtilities();



const columnDefs = ref([])
const defaultColDef = reactive({
    resizable: true,
    editable: true,
    sortable: true,
    filter: true,
    floatingFilter: true,
    suppressMenu: true,        //To be set to true, hides filter in header
    filterParams: {
        debounceMs: 500,
        suppressAndOrCondition: true
    },
    floatingFilterComponentParams: {
        suppressFilterButton: true //To be set to true, hides filter button in floating filter
    },
    tooltipComponent: GridTooltip,
    suppressKeyboardEvent: function (params) {
        let event = params.event;
        let key = event.key;
        let deniedKeys = ['Escape'];
        let suppress = params.editing && deniedKeys.indexOf(key) >= 0;
        return suppress;
    },
})

const gridOptions = /*reactive(*/{
    ...common.gridOptions,
    onCellContextMenu: (params) => {
        //common.onCellContextMenu(params, gridApi, state);
    },
    onGridReady: (params) => {
        gridApi = params.api;
        gridApi2.value = params.api;
        columnApi = params.columnApi;
        common.onGridReady(params, getAdminPageDefinition, columnDefs, state, populateGridWithData);
    },
    onRowEditingStarted: (params) => {
        triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
        common.onRowEditingStarted(params, state, componentId);
    },
    onCellValueChanged(params) {
        common.onCellValueChanged(params, columnApi);
    },
    onRowValueChanged: (params) => {
        if (params.data.id=='' || common.editedRowChanged(state, params.data)){
            state.isDirty = true;
        }
        state.isEditing='no';
        triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
    },

    onSelectionChanged: (params) => {
        triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
    },
    tabToNextCell(params) {
        return common.tabToNextCell(params, gridApi);
    },
    onBodyScroll: (event) => {
        if (!deactivatingCellEditors && event.direction=='horizontal'){
            deactivatingCellEditors=true;
            common.deactivateAllCellEditors(gridApi);
            setTimeout( function(){ deactivatingCellEditors=false; }, 2000);    //We do this so we don't trigger this method too many times and because bodyScrollEnd is not being triggered
        }
    },
    onBodyScrollEnd: (event) => {
        //This is currently not working but we will use it once the bug it's fixed by ag-grid developers
        deactivatingCellEditors=false;
    },
    onRowDoubleClicked: (params) => {
        switch (params.data.id){
            case 1:
                gridApi.startEditingCell({ rowIndex: params.rowIndex, colKey: state.firstVisibleColumn });
                break;
            case 3:
                
                break;
        }
    }
}



//EVENTS
onBeforeMount(() => { })
onMounted(() => { })
onActivated(() => {
    keyboard.addListener(componentId, onKeyPressHandler);
    keyboard.activate(componentId);
})
onDeactivated(() => {
    keyboard.removeListener(componentId);
})



//COMPUTED
const rowCount = computed(() => {
    return data.rowData ? data.rowData.length : 0;
})



//METHODS

const stopEditingOnRightclick = () => {
    common.stopEditingOnRightClick(state, gridApi);
}

const unpinRows = () => {
    common.unpinRows('containers', data, gridApi, store);
}

const onKeyPressHandler = (event) => {
    if (event.shiftKey) {
        if (event.key == '+') { addSetting(); }
    } else {
        common.onKeyPressHandler(event, gridApi, data, state);
    }
}

const populateGridWithData = () => {
    state.gridReady = false;
    setTimeout(() => {
        gridApi.showLoadingOverlay();
    });
    common.normalServerReload('/settings', axios, state, gridApi2, fields, store, modelSubmodelMap, columnDefs, prepareRecord, common.prepareListRecord, data, onSetupGrid);
}

const onSetupGrid = (response) => {
    state.gridReady = true;
    triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
}

const addSetting = () => {
    gridApi.hideOverlay();
    state.isAdding = true;
    data.rowData.unshift(prepareRecord({}));
    nextTick(() => {
        gridApi.startEditingCell({ rowIndex: 0, colKey: state.firstVisibleColumn });
        state.isEditing = 'existing';
        state.isAdding = false;
    })
    triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
}

const prepareRecord = (item) => {
    return {
        ...common.prepareRecord(item)
        ,
        ...{
            valid: true,
            id: item.id || '',
            name: item.name,
            value: item.value, 
            description: item.description,
            options: item.options
        }
    }
}

const editOption = () =>{
    gridApi.stopEditing();
    let rows=gridApi.getSelectedNodes();
    if (rows.length>0){
        let row=rows[0];
        gridApi.startEditingCell({rowIndex: row.rowIndex, colKey: state.firstVisibleColumn});
        state.isEditing='existing';
    }
}

const finishEditingOption = (cancel = false) => {
    gridApi.stopEditing();
    state.isEditing = 'no';
    triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
}

const changeCompletedContainers = () => {
    completedContainers = !completedContainers;
    common.reloadHandler(state, data, populateGridWithData)
}

const saveSettings = () => {
    if (state.isDirty){
        let sendData = data.rowData;
        advancedAxios.sendRequest(componentId, {method: 'put', url: '/settings', data: sendData})
        .then(function (response) {
            if (response.status===200){
                //Update row with changes from backend
                /*response.data.model.uniqueId = params.data.uniqueId;
                response.data.model.valid = true;
                common.updateChangesFromBackendOnUpdatingRows(response.data.model, gridApi, data, prepareRecord);*/
                store.addNotificationMessage('<strong>Settings</strong> saved!', 'success');
                state.isDirty=false;
            }else{
                /*params.data.valid=false;
                params.node.setDataValue('valid', false);*/
            }
        }).catch(function (error) {
            if (error.message!=='canceled'){
                /*params.data.valid=false;
                params.node.setDataValue("valid",false);*/
            }
        });
    }
    state.isEditing='no';
    triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
}

const validateRecord = (params) => {
    let ret = {
        valid: true,
        message: ''
    };

    ret.valid = true;
    return ret;
}

const modelSubmodelMap = {
    model: {
        'options': { target: function () { if (data.rowData === null) { data.rowData = []; } return data.rowData } }
    }
};


const inputDialog = () => {

}

</script>

<script>
export default {
    name: 'AdminPage',
    customOptions: {}
}
</script>