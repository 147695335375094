<template>
    <div 
        ref="root"
        tabindex="0" 
        @keydown="keyDownHandler"  
        @keyup="keyPressHandler" 
        class="neo-inputer">
        
        <input 
            type="text" 
            v-model="formattedValue" 
            ref="input" 
            readonly
            @click="toggleEditorPopup"
        />
            
        <Teleport to="body">
            <div
            class="neo-selector" 
            v-click-outside="hideEditorPopup"
            v-show="opened"
            :style="{ left: (popupPosition.left+'px'), top: (popupPosition.top+'px'), right: (popupPosition.right+'px'), bottom: (popupPosition.bottom+'px'), maxHeight: ((popupPosition.maxHeight+'px')) }"
            @click="toggleEditorPopup" 
            @mouseup="mouseUpHandler"
            >
                <v-card>
                    <v-list v-if="filteredItemsLength==0" density="compact" :style="{ minWidth: ((popupPosition.minWidth+'px')) }">
                        <v-list-item>
                            <v-list-item-title v-text="'No Matches'"></v-list-item-title>
                        </v-list-item>
                    </v-list>
                    <v-list density="compact" :style="{ minWidth: ((popupPosition.minWidth+'px')) }">
                        <v-list-item
                            ref="itemsRef"
                            v-for="(item, i) in filteredItems"
                            :key="i"
                            :value="item"
                            active-color="primary"
                            :class="item.id==value ? 'v-list-item-selected' : ''"
                            @click="selectOption(item.id)"
                            >
                            <v-list-item-title 
                                v-text="item.name"
                                >
                            </v-list-item-title>
                            <v-text-field 
                                v-model='amount'
                                style="width:50px; margin-left:50px; margin-right:5px;"
                                hide-details
                                variant="underlined"
                                v-if="item.name==props.params.colDef.cellEditorParams.selectWithText"
                                ref="amountInput"
                                @keydown="keyDownAmountInputHandler"
                                @keyup="keyUpHandler($event)"
                                @click.stop="onClickHandler($event)"
                                ></v-text-field>
                            <span v-if="item.name==props.params.colDef.cellEditorParams.selectWithText">{{ props.params.colDef.cellEditorParams.textSymbol }}</span>
                        </v-list-item>
                    </v-list>
                </v-card>
            </div>
        </Teleport>
    </div>
</template>



<script>
    import { ref, watch, computed, nextTick, onMounted, onBeforeMount, onActivated, reactive } from 'vue';
    import jQuery from "jquery";
    
    export default {
        name: 'GridSelectAndTextEditor',
        setup(props, context){
            //PROPS

                    
                    
            //CONSTANTS
            const $ = jQuery;
            const value = ref('');
            const formattedValue = ref('');
            const items=ref([]);
            const opened=ref(false);
            const amount=ref(0);
            const filteredItems = computed(()=>{
                return items.value;
            });
            const filteredItemsLength = computed(()=>{
                return filteredItems.value.length;
            });
            const getValue = () => {
                if (isNaN(amount.value)){
                    amount.value=0;
                }
                return {select: value.value, text: amount.value};
            }
            const isPopup = () => {
                return false;
            }
            const popupPosition=reactive({
                left: 'auto',
                top: 'auto',
                right: 'auto',
                bottom: 'auto',
                maxHeight: 'auto',
                minWidth: 'auto'
            });

            
            
            //REFERENCES
            const input=ref();
            const amountInput=ref();
            const itemsRef=ref();
            const root=ref();


            
            //WATCHERS
            watch(opened, (newOpened) => {
                if (newOpened){
                    let position = input.value.getBoundingClientRect();
                    
                    if (position.top>window.innerHeight/2){
                        popupPosition.top='auto';
                        popupPosition.bottom=window.innerHeight-position.top;
                        popupPosition.maxHeight=position.top;
                    }else{
                        popupPosition.bottom='auto';
                        popupPosition.top=position.bottom;
                        popupPosition.maxHeight=window.innerHeight-position.bottom;
                    }
                    
                    if (position.left>window.innerWidth/2){
                        popupPosition.left='auto';
                        popupPosition.right=window.innerWidth-position.right-10;
                    }else{
                        popupPosition.left=position.left;
                        popupPosition.right='auro';
                    }
                    popupPosition.minWidth=position.width;
                    
                    nextTick(() => {
                        if (value.value){
                            let list=filteredItems.value;
                            let index=list.findIndex(item => item.id==value.value);
                            if (index>=0){
                                itemsRef.value[index].$el.scrollIntoView({block: "nearest", inline: "nearest"});
                            }
                        }
                    });
                }
            });

            
            
            //EVENTS
            onBeforeMount(() => {
                if (props.params.value[props.params.colDef.cellEditorParams.select]!=''){
                    value.value=props.params.value[props.params.colDef.cellEditorParams.select];
                    let obj={ data: {}};
                    obj.data[props.params.colDef.cellEditorParams.select] = props.params.value[props.params.colDef.cellEditorParams.select];
                    obj.data[props.params.colDef.cellEditorParams.text] = props.params.value[props.params.colDef.cellEditorParams.text];
                    formattedValue.value=props.params.colDef.valueFormatter(obj);
                }
                amount.value=props.params.value[props.params.colDef.cellEditorParams.text];
                
                let tmpItems=(typeof props.params.colDef.cellEditorParams === 'function') ? props.params.colDef.cellEditorParams(props.params).valueList : props.params.colDef.cellEditorParams.valueList;
                items.value=[...(tmpItems.sort((a,b) => b.id==null ? 1 : ( a.id==null ? -1 : ((a.name>b.name) ? 1 : ((b.name > a.name) ? -1 : 0))) )) ];
            });

            

            //WATCHERS
            watch([value, amount], ([newValue, newAmount], [prevValue, prevAmount]) => {
                let obj={ data: {}};
                obj.data[props.params.colDef.cellEditorParams.select] = value.value;
                obj.data[props.params.colDef.cellEditorParams.text] = amount.value;
                formattedValue.value=props.params.colDef.valueFormatter(obj);
            });
            
            
            
            //METHODS
            const onClickHandler =(event) => {
                event.preventDefault();
                event.stopPropagation();
            }
            const toggleEditorPopup = () => {
                opened.value=!opened.value;
            }
            
            const hideEditorPopup = () => {
                opened.value=false;
            }
            
            const keyUpHandler = (event) => {
                amount.value=isNaN(parseInt(amount.value)) ? 0 : parseInt(amount.value);
                if (event.keyCode==32){
                    selectOption(value.value);
                    opened.value=false;
                    input.value.focus();
                }
            }
            const keyDownAmountInputHandler = (event) => {
                if (event.keyCode==9){
                    setTimeout(function(){props.params.api.tabToNextCell();},1);    //Need this settimeout because otherwise it's not working.
                }else if (event.keyCode==38 || event.keyCode==40){
                    input.value.focus();
                }        
            }

            const selectOption = (sel) => {
                value.value=sel;
                let obj={ data: {}};
                obj.data[props.params.colDef.cellEditorParams.select] = sel;
                obj.data[props.params.colDef.cellEditorParams.text] = amount.value;
                formattedValue.value=props.params.colDef.valueFormatter(obj);
            }
            
            const keyDownHandler = (event) => {
                if ((event.keyCode>=97 && event.keyCode<=105) || (event.keyCode>=49 && event.keyCode<=57) || event.keyCode==8 || event.keyCode==37 || event.keyCode==39){
                    opened.value=true;
                    value.value='amount';
                    nextTick(() => {
                        amountInput.value[0].focus();
                    });
                }
            }
            
            const keyPressHandler = (event) => {
                switch (event.keyCode){
                    case 38:{
                        if (opened.value){
                            let list=items.value;
                            let index=list.findIndex(item => item.id==value.value);
                            if (index<0 && list.length>0){
                                index=list.length-1;
                            }else if (index>0){
                                index--;
                            }else{
                                index=list.length-1;
                            }
                            if (index>=0){
                                value.value=list[index].id;
                                itemsRef.value[index].$el.scrollIntoView({block: "nearest", inline: "nearest"});
                            }
                        }
                        break;
                    }
                    case 40:{
                        if (opened.value){
                            let list=items.value;
                            let index=list.findIndex(item => item.id==value.value);
                            if (index<0 && list.length>0){
                                index=0;
                            }else if (index<list.length-1){
                                index++;
                            }else{
                                index=0;
                            }
                            if (index>=0){
                                value.value=list[index].id;
                                itemsRef.value[index].$el.scrollIntoView({block: "nearest", inline: "nearest"});
                            }
                        }
                        break;
                    }
                    case 32: 
                    case 13:{
                        opened.value=!opened.value;
                        selectOption(value.value);
                        break;
                    }
                }
            }
            
            const focusIn = () => {
                input.value.focus();
                if (!opened.value){
                    opened.value=true;
                }
            }
            const focusOut = () => { 
                if (opened.value){
                    // !!! opened.value=false;
                }
            }    

            return {
                getValue,
                input,
                amountInput,
                value,
                amount,
                formattedValue,
                items,
                opened,
                onClickHandler,
                toggleEditorPopup,
                hideEditorPopup,
                keyUpHandler,
                keyDownHandler,
                keyPressHandler,
                keyDownAmountInputHandler,
                focusIn,
                focusOut,
                selectOption,
                popupPosition,
                filteredItems,
                props,
                itemsRef
            }

        }
    }
</script>

<style>
    .neo-inputer input{
        width: 100%;
        background-color: #fff;
        padding-left: 5px;
    }
    .neo-selector .v-list{
        padding: 0;
    }
    .neo-selector .v-list .v-list-item{
        padding: 12px 5px 12px 5px;
    }
    .neo-selector .v-list .v-list-item .v-input__control .v-field__field{
        padding: 0 !important;
        min-height: auto !important;
    }
    .neo-selector .v-list .v-list-item input{
        font-size: 14px;
        text-align: right;
    }
    .neo-selector .v-field__field{
        padding: 0 !important;
    }
    .neo-selector .v-list-item-selected{
        color: #fff;
        background-color: #4E6F8E;
    }
</style>