<template>
    <div class="main-content-area" v-touch:swipe.right="backButton">
        <div class="main-content-toolbar">
            <v-toolbar>
                <v-btn icon="mdi-arrow-left" size="x-large" variant="text" @click="backButton"></v-btn>
            </v-toolbar>
            <v-toolbar>
                <v-toolbar-title class="text-center">
                    <div class="toolbar-title">
                        <h3>CONTAINER STACKER OPERATIONS</h3>
                        <span class="job-status">INTERNAL MOVES</span>
                    </div>
                </v-toolbar-title>
            </v-toolbar>
            <v-toolbar>
            </v-toolbar>
        </div>

        <div class="center-flex" v-if="!ready">
            <DataLoading :type=4 />
        </div>
        <div v-if="ready" class="main-content-playground">
            <div class="container-section">
                <v-row>
                    <v-col class="rowBtnCol center">
                        <v-btn color="primary" size="x-large" width="100%" height="100%" class="white--text"
                            @click="selectContainerButton">
                            <v-icon v-if="containerId" class="text-h3" color="green">
                                mdi-cached
                            </v-icon>
                            <v-icon v-else class="text-h3" style="color: orange;">
                                mdi-cached
                            </v-icon>
                            <span class="text-h3 container-btn-text">
                                {{ selectedStockContainer }}
                            </span>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>

            <div v-if="containerShippingLine && !hideCustomerColumn"
                style="padding-top:10px; display: flex;flex-direction: column;">
                <v-row>
                    <v-col v-if="containerShippingLine" class="flex text-center">
                        <v-card class="combined-card" elevation="2">
                            <v-card-text>
                                <span class="text-h5 position-label">Customer: {{ containerShippingLine }}</span>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </div>

            <div class="position-section">
                <v-row>
                    <v-col class="center" cols="6">
                        <v-col class="flex text-center">
                            <div>
                                <span class="text-h5 position-label"> Current Yard Position:
                                </span>
                            </div>
                        </v-col>
                        <v-col class="flex text-center">
                            <div>
                                <span class="text-h1 position-value">
                                    {{ containerPosition }}
                                </span>
                            </div>
                        </v-col>
                    </v-col>
                    <v-col class="center" cols="6">
                        <v-col class="flex text-center">
                            <div>
                                <span class="text-h5 position-label"> Target Yard Position:
                                </span>
                            </div>
                        </v-col>
                        <v-col class="flex text-center">
                            <div>
                                <span class="text-h1 position-value" style="color: green;"
                                    v-text="position ? position : '...'">
                                </span>
                            </div>
                        </v-col>
                    </v-col>
                </v-row>
            </div>

            <div v-if="reportCleaning || reportPtiStatus || reportBuildYear"
                style="padding-bottom:10px; display: flex;flex-direction: column;">
                <v-row>
                    <v-col v-if="reportCleaning" class="flex text-center">
                        <v-card class="combined-card" elevation="2">
                            <v-card-text>
                                <span class="text-h5 position-label">Cleaning Status: {{ reportCleaning }}</span>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col v-if="reportPtiStatus" class="flex text-center">
                        <v-card class="combined-card" elevation="2">
                            <v-card-text>
                                <span class="text-h5 position-label">PTI Status:
                                    <v-icon v-if="reportPtiStatus" class="pti-icon"
                                        :color="reportPtiColor">mdi-circle</v-icon>
                                    {{ reportPtiStatus }}
                                </span>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col v-if="reportBuildYear" class="flex text-center">
                        <v-card class="combined-card" elevation="2">
                            <v-card-text>
                                <span class="text-h5 position-label">Build Year: {{ reportBuildYear }}</span>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </div>

            <div class="action-buttons">
                <v-row>
                    <v-col class="rowBtnCol center" cols="4">
                        <v-btn color="primary" size="x-large" width="100%" height="100%" class="white--text"
                            @click="backButton">
                            <v-icon class="text-h3">
                                mdi-stop-circle
                            </v-icon>
                            <span class="text-h3 action-btn-text">
                                Stop
                            </span>
                        </v-btn>
                    </v-col>
                    <v-col class="rowBtnCol center position-relative" cols="4">
                        <v-btn :disabled="!containerId" color="primary" size="x-large" width="100%" height="100%"
                            class="white--text" @click="enableFilter">
                            <v-icon v-if="position" color="green" class="text-h3">
                                mdi-map-marker
                            </v-icon>
                            <v-icon v-else class="text-h3">
                                mdi-map-marker
                            </v-icon>
                            <span class="text-h3 action-btn-text">
                                Position
                            </span>
                        </v-btn>
                        <div v-if="!containerId" class="action-btn-overlay" @click="handlePositionClick"></div>
                    </v-col>
                    <v-col class="rowBtnCol center position-relative" cols="4">
                        <v-btn :disabled="!position || !selectedStockContainer" color="primary" size="x-large" width="100%"
                            height="100%" class="white--text" @click="addTask">
                            <v-icon class="text-h3">
                                mdi-check-circle
                            </v-icon>
                            <span class="text-h3 action-btn-text">
                                Done
                            </span>
                        </v-btn>
                        <div v-if="!position && selectedStockContainer" class="action-btn-overlay"
                            @click="handleSecondDoneClick"></div>
                        <div v-if="!position || !selectedStockContainer" class="action-btn-overlay"
                            @click="handleDoneClick"></div>
                    </v-col>
                </v-row>
            </div>

            <div v-show="showFilter == true" class="keypad-overlay">
                <KeypadInput type="list-left" :backdrop=false connect="parent" :show="showFilter" :all="'content'"
                    :allowed="containerPositions && containerPositions.length ? containerPositions : []"
                    :key_layout="'normal'" :done_button=true :done_only_on_final=true :input_selection=true
                    :autocomplete=true :reset_on_open=true @update="updateFilter" @done="toggleFilter"
                    @cancel="cancelFilter" />
            </div>

        </div>
        <div class="main-content-footer">
            <AppMessageNotifications :height=70 :font=16 />
            <v-toolbar :height="70">
                <v-btn icon="mdi-circle" size="small" color="light-green darken-1" variant="text"></v-btn>
                <v-btn size="x-small" variant="text">
                    <h2>Status: online</h2>
                </v-btn>
            </v-toolbar>
        </div>
    </div>
</template>

<script setup>


//IMPORTS
import { reactive, inject, ref, defineProps, computed, onBeforeMount, onActivated, getCurrentInstance, watch } from 'vue';
import YamaxStock from '@/components/yamax/YamaxStock'
import { useRouter } from 'vue-router';
import { useAppStore } from '@/stores/app';
import { useTransporter } from '@/composables/useTransporter';
import { useAdvancedAxios } from '@/composables/useAdvancedAxios';
import { useNotification } from '@/composables/useNotification';
import KeypadInput from '@/components/ui/KeypadInput';



//INJECTIONS
const axios = inject('axios');



//VARIABLES
let position = ref('');
let selectedStockContainer = ref(null);
let containerPosition = ref(null);
let containerId = ref(null);
let containerCleaning = ref(null);
let containerPtiStatus = ref(null);
let containerBuildYear = ref(null);
let containerOperator = ref(null);



//CONSTANTS
const store = useAppStore();
const componentId = getCurrentInstance().uid;
const router = useRouter();
const components = {
    YamaxStock
}
const ready = ref(false);
const showFilter = ref(false);
const containerPositions = ref([]);



//COMPOSABLES
const { transporter } = useTransporter();
const { advancedAxios } = useAdvancedAxios(axios);
const { notification } = useNotification();



//PROPS
const props = defineProps({
})



//COMPUTED
const hideCustomerColumn = computed(() => {
    // get the user permissions
    const userPermissions = store.user.permissions;
    // check if the user has the permission to view the customer column
    const hasPermission = userPermissions.includes('System Root/CSO Stacker App/Customer Column-Read');
    // return true if the user has the permission, false otherwise
    return !hasPermission;
});

const reportCleaning = computed(() => {
    if (containerCleaning.value) {
        let cleaning = [];
        containerCleaning.value.forEach(item => {
            if (item.damageLocation == 'IXXX' && item.damageComponent == 'MCO' && item.repairMethod == 'SC') {
                cleaning.push('Steam Clean');
            }
            if (item.damageLocation == 'IXXX' && item.damageComponent == 'MCO' && item.repairMethod == 'AC') {
                cleaning.push('Blowout');
            }
            if (item.damageLocation == 'IXXX' && item.damageComponent == 'MCO' && item.repairMethod == 'MC') {
                cleaning.push('Special Clean');
            }
        });
        //make the elements from cleaning array unique
        cleaning = [...new Set(cleaning)];
        //make a string from the array with commas between the elements
        cleaning = cleaning.join(', ');

        return cleaning;
    } else {
        return '';
    }

})

const reportPtiStatus = computed(() => {
    if (containerPtiStatus.value) {
        return containerPtiStatus.value;
    } else {
        return '';
    }
})

const reportPtiColor = computed(() => {
    if (containerPtiStatus.value) {
        if (containerPtiStatus.value === 'Only Visual Inspection') {
            return 'green';
        } else if (containerPtiStatus.value === '*PTI/SPTI') {
            return 'red';
        } else if (containerPtiStatus.value === 'Short PTI') {
            return 'red';
        } else if (containerPtiStatus.value === 'Release to booking') {
            return 'blue';
        } else {
            return 'orange';
        }
    } else {
        return 'orange';
    }
})

const reportBuildYear = computed(() => {
    if (containerBuildYear.value) {
        return containerBuildYear.value;
    } else {
        return '';
    }
})

const containerShippingLine = computed(() => {
    if (containerOperator.value) {
        return containerOperator.value;
    } else {
        return '';
    }
})


//EVENTS
onActivated(() => {
    ready.value = false;
    let transportData = transporter.getData(componentId);
    selectedStockContainer.value = transportData ? transportData.unit_number : 'Select Container';
    containerPosition.value = transportData ? transportData.position : '';
    containerId.value = transportData ? transportData.id : null;
    containerPositions.value = transportData.positions ? transportData.positions.map(item => item.position) : [];
    containerOperator.value = transportData ? transportData.shipping_line__company.name : null;

    if (transportData.report_report) {
        containerCleaning.value = transportData.report_report.estimate_item__estimate_item ? transportData.report_report.estimate_item__estimate_item : null;
        containerPtiStatus.value = transportData.report_report.machinery_info__machinery_info ?
            transportData.report_report.machinery_info__machinery_info.pti_description : 'Unknown';
        containerBuildYear.value = transportData.report_report.construction_year ? transportData.report_report.construction_year : null;
    }
    else {
        containerCleaning.value = null;
        containerPtiStatus.value = null;
        containerBuildYear.value = transportData.construction_year;
    }
    position.value = null;
    ready.value = true;
})



//WATCHES




//METHODS

const handleDoneClick = () => {
    if (!containerId.value) {
        store.addNotificationMessage('Please select a container and a position.', 'error');
        return;
    } else {
        store.addNotificationMessage('Please select a position.', 'error');
        return;
    }
}

const handlePositionClick = () => {
    if (!containerId.value) {
        store.addNotificationMessage('Please select a container.', 'error');
        return;
    }
}

const enableFilter = () => {
    showFilter.value = true;
}

const addTask = (onUpdate) => {
    let sendData = {
        "container_id": containerId.value,
        "status": 'completed',
        "position": position.value,
        "reference_id": 'null',
        "licence_plate": 'internal',
        "carrier_id": 'null',
    }

    advancedAxios.sendRequest(containerId.value, { method: 'post', url: '/api/yamaxTasks', data: sendData })
        .then(function (response) {
            if (typeof onUpdate === 'function') {
                onUpdate();
            }
            if (selectedStockContainer.value != 'Select Container') {
                store.addNotificationMessage('Move completed.', 'success');
                notification.notify('complete', 2, 'Task Completed', () => {
                    //
                });

                selectedStockContainer.value = 'Select Container';
                containerPosition.value = '';
                containerId.value = null;
                containerCleaning.value = null;
                position.value = '';

                router.push({ name: 'InternalRenderer' });
            }
        })
}


const backButton = (params) => {
    if (showFilter.value == true) {
        toggleFilter();
    } else {
        router.push({ name: 'RsoApp' });
    }
};

const selectContainerButton = (params) => {
    router.push({ name: 'YamaxStock', params: { task_id: 'internal', transportId: componentId } });
};

const toggleFilter = () => {
    showFilter.value = !showFilter.value;
}

const updateFilter = (data) => {
    position.value = data.value;
}

const cancelFilter = () => {
    position.value = '';
    toggleFilter();
}

</script>

<style src="vue-scroll-picker/lib/style.css"></style>
<style scoped>
.main-content-playground {
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.container-section,
.position-section,
.action-buttons {
    display: flex;
    flex-grow: 1;
}

.rowBtnCol {
    width: 100%;
    height: 100%;
}

.container-btn-text,
.position-label,
.position-value,
.action-btn-text {
    font-weight: bold;
}

.action-btn-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.keypad-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.toolbar-title {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    letter-spacing: 1.2px;
    color: #ffffff;
}

.job-status {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 0.9em;
    letter-spacing: 1px;
    color: #ffffff;
    margin-left: 8px;
}

.cleaning-status-section {
    display: flex;
    flex-grow: 1;
}

.cleaning-status-label,
.cleaning-status-value {
    font-weight: bold;
}
</style>