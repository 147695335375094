import { defineStore } from 'pinia';

export const useAppStore = defineStore('app', {
    //STATE
    state: () => ({
        csrfReceived: false,
        authToken: null,
        resetKey: 0,
        user: {
            id: null,
            firstname: '',
            lastname: '',
            email: '',
            tooltip: false,
            app: null,
            csrfToken: null,
            authToken: null,
            mostUsed: {
                company: null
            },
            roles: [],
            permissions: [],
            config: {
                timezone: 'Europe/Berlin',
                dateFormat: 'YYYY-MM-DD',
                timeFormat: 'HH:mm',
                dateTimeFormat: 'YYYY-MM-DD HH:mm',
                dateTimeSecondFormat: 'YYYY-MM-DD HH:mm:ss',
                dateTimeSecondMillisecondFormat: 'YYYY-MM-DD HH:mm:ss:SSS'
            }
        },
        theme: null,
        loading: false,
        maintenance: false,
        notifications: [
        ],
        notificationMessageTimerId: null,
        notificationMessages: [
        ],
        notificationAutoindex: 0,
    }),
    //ACTIONS
    actions: {
        setLoading(payload) {
            this.loading = payload;
        },
        //User
        login() { },
        logout() {
            this.resetKey++;
            this.user = {
                ...this.user,
                ...{
                    id: null,
                    firstname: '',
                    lastname: '',
                    email: '',
                    tooltip: false,
                    mostUsed: {
                        company: null
                    },
                    roles: [],
                    permissions: []
                }
            }
        },

        //Notifications
        hideFromScreenAllNotifications() {
            this.$patch((state) => {
                let total = state.notifications.length;
                for (let i = 0; i < total; i++) {
                    state.notifications[i].onScreen = false;
                }
            })
        },
        addNotification(message, type = 'info') {
            let date = new Date();
            this.notificationAutoindex++;
            this.notifications.push({
                id: this.notificationAutoindex,
                onScreen: true,
                seen: false,
                notification: message,
                added: date.toLocaleDateString() + ' ' + date.toLocaleTimeString('en-US', { hour12: false, hour: "numeric", minute: "numeric" }),
                viewed: null,
                type: type,
                dissappear: false
            });
            let lastId = this.notificationAutoindex;
            let self = this;
            setTimeout(function () {
                let not = self.notifications.find(item => item.id == lastId);
                if (not) {
                    not.disappear = true;
                    setTimeout(function () {
                        not = self.notifications.find(item => item.id == lastId);
                        if (not) {
                            not.onScreen = false;
                        }
                    }, 2000);
                }
            }, 2000);
        },
        seeNotifications(ids) {
            if (!Array.isArray(ids)) { ids = [ids]; }
            this.$patch((state) => {
                let total = ids.length;
                for (let i = 0; i < total; i++) {
                    let notification = this.notifications.find(item => item.id == ids[i]);
                    notification.seen = true;
                }
            });
        },
        seeAllNotifications() {
            this.$patch((state) => {
                let total = state.notifications.length;
                for (let i = 0; i < total; i++) {
                    state.notifications[i].seen = true;
                }
            })
        },
        unseeNotifications(id) {
            let notification = this.notifications.find(item => item.id == id);
            notification.seen = false
        },
        deleteNotifications(ids) {
            if (!Array.isArray(ids)) { ids = [ids]; }
            this.$patch((state) => {
                let total = ids.length;
                let itemsToRemove = [];
                for (let i = 0; i < total; i++) {
                    let notificationIndex = state.notifications.findIndex(item => item.id == ids[i]);
                    if (notificationIndex != -1) {
                        state.notifications.splice(notificationIndex, 1);
                    }
                }
            });
        },

        addNotificationMessage(message, type, timer='normal') {
            if (this.notificationMessages.length === 0 || (this.notificationMessages.length > 0 && (this.notificationMessages[this.notificationMessages.length - 1].message != message || this.notificationMessages[this.notificationMessages.length - 1].type != type))) {
                //As soon as another mesage arrives expire the current one
                this.nextNotificationMessage(true);
                this.notificationMessages.push({ type: type, message: message, timer: timer });
                this.nextNotificationMessage();
            }
        },
        nextNotificationMessage(now = false) {
            let self = this;
            if (now == true) {
                if (this.notificationMessageTimerId != null) {
                    clearTimeout(this.notificationMessageTimerId);
                }
                self.notificationMessages.shift();
                this.notificationMessageTimerId = null;
            }
            if (this.notificationMessageTimerId == null && this.notificationMessages.length > 0) {
                let timer = this.notificationMessages[0].timer;
                timer = (timer == 'fast' ? 2000 : (timer == 'normal' ? 4000 : 6000) );
                this.notificationMessageTimerId = setTimeout(function () {
                    self.notificationMessages.shift();
                    self.notificationMessageTimerId = null;
                    self.nextNotificationMessage();
                }, timer );
            }
        },
        pauseNotificationMessages() {
            if (this.notificationMessageTimerId != null) {
                clearTimeout(this.notificationMessageTimerId);
                this.notificationMessageTimerId = null;
            }
        },
        resumeNotificationMessages() {
            this.nextNotificationMessage();
        }
    },
    getters: {
        //User
        getUserId: (state) => {
            return state.user.id;
        },

        //Notifications
        getAllNotifications: (state) => {
            return state.notifications;
        },
        getAllNotificationsTotal: (state) => {
            return state.notifications.length;
        },
        getUnseenNotificationsTotal: (state) => {
            let i = 0;
            state.notifications.forEach((item) => {
                if (item.seen == false) { i++; }
            });
            return i;
        }
    },
})
