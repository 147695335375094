<template>
    <div 
        ref="root"
        tabindex="0" 
        class="neo-inputer"
        @keyup="keyPressHandler" 
        >
        
        <input v-show="!props.params.colDef.cellEditorParams.onlyIcons"
            type="text" 
            v-model="formattedValue" 
            ref="input" 
            readonly
            @click="opened=!opened"
            style="font-size: 14px"
        />
            
        <div 
            v-if="props.params.colDef.cellEditorParams.onlyIcons" 
            ref="iconInput" 
            class="ag-cell" 
            @click="opened=!opened"
            style="background-color: #fff;width:100%;">
                <v-icon 
                    v-if="formattedIcon" 
                    :icon="formattedIcon.icon" 
                    :color="formattedIcon.color" 
                    :style="formattedIcon.style"
                    >
                </v-icon>
        </div>

        <Teleport to="body">
            <div
                class="neo-selector" 
                v-click-outside="open=false"
                v-show="opened"
                :style="{ left: (popupPosition.left+'px'), top: (popupPosition.top+'px'), right: (popupPosition.right+'px'), bottom: (popupPosition.bottom+'px'), maxHeight: ((popupPosition.maxHeight+'px')) }"
                @click="opened=!opened" 
                @mouseup="mouseUpHandler"
                >

                <v-list v-if="!loading && filteredItemsLength==0" density="compact" :style="{ minWidth: ((popupPosition.minWidth+'px')) }">
                    <v-list-item>
                        <v-list-item-title v-text="props.noOptions"></v-list-item-title>
                    </v-list-item>
                </v-list>

                <v-list v-if="loading && filteredItemsLength==0" density="compact" :style="{ minWidth: ((popupPosition.minWidth+'px')) }">
                    <v-list-item>
                        <DataLoading :type=2 :delay="1000"/>
                    </v-list-item>
                </v-list>

                <v-list v-if="!loading && filteredItemsLength>0" density="compact" :style="{ minWidth: ((popupPosition.minWidth+'px')) }">
                    <v-list-item 
                        ref="itemsRef"
                        v-show = "!item.noSelect" 
                        v-for="(item, i) in filteredItems"
                        :key="i"
                        :value="item"
                        active-color="primary"
                        :class="item.id==value ? 'v-list-item-selected' : ''"
                        :style=" props.params.onlyIcons ? 'justify-content:center;' : '' "
                        @click="selectOption(item.id)"
                        >
                        <template v-slot:prepend v-if="item.icon">
                            <v-icon :icon="item.icon" :color="item.color" :style="item.style"></v-icon>
                        </template>

                        <v-list-item-title v-if="!props.params.onlyIcons" v-text="item.name" :style="{ marginLeft: ((item.icon && item.name ? '15' : '0') + 'px') }">
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
                
                <div class="actions">
                    <v-btn v-for="(item, i) in props.params.actions" :key="i" icon="mdi-pencil" color="primary" size="small" @click.stop.prevent="callActionHandler($event, i)"></v-btn>
                </div>
            </div>
        </Teleport>
    </div>
</template>



<script>
    import { ref, reactive, watch, computed, nextTick, onMounted, onUnmounted, onBeforeMount, onActivated, inject } from 'vue';

    

    export default {
        name: 'GridSelectEditorNeo',
        //PROPS
        props: {
            noOptions: {
            type: String,
            default: 'No Matches'
            }
        },        
		setup(props, context){
            //CONSTANTS
            const value = ref();
            const formattedValue = ref('');
            const formattedIcon = ref('');
            const items=ref([]);
            const filter=ref('');
            const opened=ref(false);
            const popupPosition=reactive({
                left: 'auto',
                top: 'auto',
                right: 'auto',
                bottom: 'auto',
                maxHeight: 'auto',
                minWidth: 'auto'
            });
            const filteredItems = computed(()=>{
                if (filter.value==''){
                    return items.value;
                }else{
                    return items.value.filter(item => item.name && item.name.toUpperCase().indexOf(filter.value.toUpperCase())!=-1);
                }
            });
            const filteredItemsLength = computed(()=>{
                return filteredItems.value.length;
            });
            const newValueSelected = ref(false);
            const loading=ref(false);
            
            
            //REFERENCES
            const input=ref();
            const iconInput=ref();
            const itemsRef=ref();
            const root=ref();
            
            
            
            //INJECTIONS
            const axios = inject('axios');



            //VARIABLES

            
            
            
            //WATCHERS
            watch(opened, (newOpened) => {
                if (newOpened){
                    let position = props.params.onlyIcons ? iconInput.value.getBoundingClientRect() : input.value.getBoundingClientRect();
                    
                    if (position.top>window.innerHeight/2){
                        popupPosition.top='auto';
                        popupPosition.bottom=window.innerHeight-position.top;
                        popupPosition.maxHeight=position.top;
                    }else{
                        popupPosition.bottom='auto';
                        popupPosition.top=position.bottom;
                        popupPosition.maxHeight=window.innerHeight-position.bottom;
                    }
                    
                    if (position.left>window.innerWidth/2){
                        popupPosition.left='auto';
                        popupPosition.right=window.innerWidth-position.right-10;
                    }else{
                        popupPosition.left=position.left;
                        popupPosition.right='auro';
                    }
                    popupPosition.minWidth=position.width;
                    
                    nextTick(() => {
                        if (value.value){
                            let list=filteredItems.value;
                            let index=list.findIndex(item => item.id==value.value);
                            if (index>=0){
                                itemsRef.value[index].$el.scrollIntoView({block: "nearest", inline: "nearest"});
                            }
                        }
                    });
                }
            });
            watch([value, filter, newValueSelected], ([newValue, newFilter, newNewValueSelected], [prevValue, prevFilter, prevNewValueSelected]) => {
                if (props.params.colDef.cellEditorParams.onlyIcons && newValue){
                    let elem = (typeof props.params.colDef.cellEditorParams === 'function') ? props.params.colDef.cellEditorParams(props.params).valueList : props.params.colDef.cellEditorParams.valueList;
                    elem = elem.find(item => item.id == newValue);
                    if (elem){
                        formattedIcon.value = elem;    
                    }else{
                        formattedIcon.value = null;
                    }
                }
                if (!newValueSelected.value/* && filter.value!=''*/){
                    formattedValue.value = filter.value;
                }else{
                    formattedValue.value = props.params.colDef.valueFormatter ? props.params.colDef.valueFormatter({ ...props.params, value: newValue }) : newValue;
                }
            });
            
            
            //EVENTS
            onBeforeMount(() => {
                selectOption(props.params.value);
                if (!props.params.colDef.cellEditorParams.loadFromUrl){
                    let tmpItems=[];
                    setTimeout(function(){
                        tmpItems=(typeof props.params.colDef.cellEditorParams === 'function') ? props.params.colDef.cellEditorParams(props.params).valueList : props.params.colDef.cellEditorParams.valueList;
                        items.value=[...(tmpItems.sort((a,b) => b.id==null ? 1 : ( a.id==null ? -1 : ((a.name>b.name) ? 1 : ((b.name > a.name) ? -1 : 0))) )) ];
                        if (props.params.default=='first' && !props.params.value && tmpItems.length==1){
                            selectOption(tmpItems[0].id);
                        }
                    },1);
                }else{
                    loadListFromUrl(items);
                }
            });
            onMounted(() => {
                document.addEventListener('click', blurHandler);
            })
            onUnmounted(() => {
                document.removeEventListener('click', blurHandler);
            });
            
            
            //METHODS
            const getValue = () => {
                
                return value.value;
            }
            const isPopup = () => {
                return false;
            }
            const selectOption = (sel) => {
                if (value.value !== sel){
                    newValueSelected.value=true;
                    value.value=sel;
                }
            }
            
            const keyPressHandler = (event) => {
                switch (event.keyCode){
                    case 38:
                        if (opened.value){
                            newValueSelected.value=true;
                            let list=filteredItems.value;
                            let index=list.findIndex(item => item.id==value.value);
                            if (index<0 && list.length>0){
                                index=list.length-1;
                            }else if (index>0){
                                index--;
                            }else{
                                index=list.length-1;
                            }
                            if (index>=0){
                                selectOption(list[index].id);
                                itemsRef.value[index].$el.scrollIntoView({block: "nearest", inline: "nearest"});
                            }
                        }
                        break;
                    case 40:
                        if (opened.value){
                            newValueSelected.value=true;
                            let list=filteredItems.value;
                            let index=list.findIndex(item => item.id==value.value);
                            if (index<0 && list.length>0){
                                index=0;
                            }else if (index<list.length-1){
                                index++;
                            }else{
                                index=0;
                            }
                            if (index>=0){
                                selectOption(list[index].id);
                                itemsRef.value[index].$el.scrollIntoView({block: "nearest", inline: "nearest"});
                            }
                        }
                        break;
                    case 8:
                        if (opened.value){
                            if (newValueSelected.value){
                                newValueSelected.value=false;
                                filter.value='';
                                value.value=null;
                            }
                            if (filter.value.length>0){
                                filter.value = filter.value.slice(0, -1);
                            }
                        }
                        break;
                    case 13:
                        opened.value=!opened.value;
                        selectOption(value.value);
                        break;                
                    default:
                        //if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 97 && event.keyCode <= 105) || (event.keyCode >= 65 && event.keyCode <= 90) || event.keyCode==32) {
                        if (event.key.length === 1) {
                            if (newValueSelected.value){
                                newValueSelected.value=false;
                                filter.value='';
                            }
                            filter.value+=event.key;
                        }
                        //}
                }
            }
            
            const mouseUpHandler = (event) => {
                input.value.focus();
            }
            
            const callActionHandler = (event, index) => {
                if (Array.isArray(props.params.actions) && props.params.actions[index]){
                    props.params.actions[index].actionCall();
                }
            }
            
            const blurHandler = (event) => {
                if (value.value===null){
                    filter.value = '';
                }
                if (root.value!==undefined && root.value.contains(event.target)===false) {
                    opened.value=false;
                }
            }
            
            const focusIn = () => {
                input.value.focus();
                opened.value=true;
            }
            const focusOut = () => {
                opened.value=false;
            }

            const loadListFromUrl = () => {
                loading.value = true;
                let loadConfig = props.params.colDef.cellEditorParams.loadFromUrl;
                axios.get(loadConfig.url)
                    .then(response => {
                        let tmpItems=response.data.models.stockContainers;
                        tmpItems = response.data.models.stockContainers.map(({ id, unit_number }) => ({ id: unit_number, name: unit_number }));
                        items.value=[...(tmpItems.sort((a,b) => b.id==null ? 1 : ( a.id==null ? -1 : ((a.name>b.name) ? 1 : ((b.name > a.name) ? -1 : 0))) )) ];
                        if (props.params.default=='first' && !props.params.value && tmpItems.value.length==1){
                            selectOption(items.value[0]);
                        }else{
                            selectOption(props.params.value);
                        }
                        loading.value = false;
                    })
                    .catch(error => {
                        //
                    });
            }

            return{
                props,
                root,
                input,
                iconInput,
                itemsRef,
                value,
                loading,
                filter,
                opened,
                filteredItems,
                filteredItemsLength,
                newValueSelected,
                getValue,
                isPopup,
                selectOption,
                keyPressHandler,
                mouseUpHandler,
                callActionHandler,
                blurHandler,
                focusIn,
                focusOut,
                popupPosition,
                formattedValue,
                formattedIcon,
                loadListFromUrl
            }

        }
    }
</script>

<style>
    .neo-inputer{
        width: 100%;
        height: 100%;
    }
    .neo-inputer input{
        width: 100%;
        height: 100%;
        padding-left: 5px;
    }
    .neo-selector{
        position: absolute !important;
        z-index: 1;
        display: flex;
        flex-direction: row;
        background-color: transparent;
    }
    .neo-selector .actions{
        background-color: transparent;
        padding: 5px;
    }
    .neo-selector .v-list{
        padding: 0;
        max-height: 100%; 
        flex:1; 
        overflow:auto;
        border:1px solid lightgray;
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
    }
    .neo-selector .v-list .v-list-item{
        padding: 12px 5px 12px 5px;
    }
    .neo-selector .v-list .v-list-item .v-input__control .v-field__field{
        padding: 0 !important;
        min-height: auto !important;
    }
    .neo-selector .v-list .v-list-item input{
        font-size: 14px;
        text-align: right;
    }
    .neo-selector .v-field__field{
        padding: 0 !important;
    }
    .neo-selector .v-list-item-selected{
        color: #fff !important;
        background-color: #4E6F8E;
    }
</style>