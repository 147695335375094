import { createRouter, createWebHistory, createWebHashHistory } from "vue-router";
import LoginPage from '@/components/LoginPage';
import AppSettings from '@/components/AppSettings';
import HomeScreen from '@/components/HomeScreen';
import MaintenancePage from '@/components/MaintenancePage';
import RsoApp from '@/components/yamax/RsoApp';
import YamaxStock from '@/components/yamax/YamaxStock';
import TaskRenderer from '@/components/yamax/TaskRenderer';
import InternalRenderer from '@/components/yamax/InternalRenderer';
import NotFound from '@/components/NotFound';



const routes = [
    { path: "/login:next", name: "LoginPage", component: LoginPage, props: true, alias: ['/:next'] },
    { path: "/AppSettings", name: "AppSettings", component: AppSettings, props: true },
    { path: "/HomeScreen", name: "HomeScreen", component: HomeScreen, props: true },
    { path: "/RsoApp", name: "RsoApp", component: RsoApp, props: true },
    { path: "/YamaxStock/:task_id/:transportId?", name: "YamaxStock", component: YamaxStock, props: true },
    { path: "/TaskRenderer/:id", name: "TaskRenderer", component: TaskRenderer, props: true },
    { path: "/InternalRenderer", name: "InternalRenderer", component: InternalRenderer, props: false },
    { path: "/maintenancePage", name: "MaintenancePage", component: MaintenancePage, props: true },
    { path: '/:pathMatch(.*)', name: 'NotFound', component: NotFound },
];

const router = createRouter({
    history: createWebHashHistory(''),
    routes: routes
});

let firstRouteMatch = true;

router.beforeEach((to, from, next) => {
    if (firstRouteMatch) {
        firstRouteMatch = false;
        next({
            name: 'LoginPage',
            params: { next: to.name }
        });
    }else{
        next();
    }
});

export default router;