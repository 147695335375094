<template>
    <div style="text-align: center;">
        <v-btn color="primary" @click="sendTestMail" v-tippy="{ content: 'Send test mail' }" style="margin-bottom:2px;">
            SEND
        </v-btn>
    </div>
</template>

<script>
import { inject } from 'vue';
import { useAppStore } from '@/stores/app';



export default {
    name: 'GridIconRenderer',
    setup(props, context) {

        //INJECTIONS
        const axios = inject('axios');

        

        //CONSTANTS
        const store = useAppStore();



        const sendTestMail = () => {
            axios.get('/admin/sendTestEmail').then(function (response) {
                store.addNotificationMessage('Test email sent to <strong>'+response.data.mail+'</strong>', 'success');
            }).catch(function (error) {
                //
            });
        }

        return {
            props,
            sendTestMail,
            axios
        }

    }
}

</script>