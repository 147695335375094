<template>
    <div class="main-content-area">
        <div class="main-content-playground" style="display: flex;justify-content: center; align-items: center;
            background-size: cover; background-position: center;" :style="{ backgroundImage: backgroundImage }">

            <v-container v-if="login_checked === false" style="width:30%;background-color:rgba(255,255,255,0.9);">
                <v-row>
                    <v-col align='center'>
                        <DataLoading />
                    </v-col>
                </v-row>
            </v-container>

            <v-container v-if="login_checked === true"
                style="width:30%;background-color:rgba(255,255,255,0.9);min-width:300px;">
                <v-row>
                    <v-col>&nbsp;
                    </v-col>
                </v-row>
                <v-row>
                    <v-col align='center'>
                        <v-img src="logo-dark.png" width="40px"></v-img>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>&nbsp;
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="info" style="font-weight: bold;" v-html="message">
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-text-field label="" v-model="data.username" hint="" density="compact" variant="outlined"
                            hide-details="true" placeholder="Username" style="background-color: #fff;"></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-text-field label="" v-model="data.password" hint="" density="compact" variant="outlined"
                            type="password" hide-details="true" placeholder="Password"
                            style="background-color: #fff;"></v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>&nbsp;
                    </v-col>
                </v-row>

                <v-row>
                    <v-col align="center">
                        <v-btn-group block density="compact" class="d-flex flex-row">
                            <v-btn append color="primary" class="flex-grow-1" :disabled="processing == true"
                                @click="login">Login</v-btn>
                        </v-btn-group>
                    </v-col>
                </v-row>
            </v-container>

        </div>
    </div>
</template>

<script setup>



//IMPORTS
import { inject, ref, reactive, computed, onActivated, onDeactivated, onMounted, defineProps, getCurrentInstance } from 'vue'
import { useAppStore } from '@/stores/app'
import { useRouter } from 'vue-router';
import { useKeyboard } from '@/composables/useKeyboard';



//PROPS
const props = defineProps({
    next: {
        type: String,
        required: true,
        default: null
    }
});



//CONSTANTS
const processing = ref(false);
const login_checked = ref(false);
const data = reactive({
    username: '',
    password: ''
});
const message = ref('Please login');



//COMPOSABLES
const { keyboard } = useKeyboard();



//INJECTIONS
const axios = inject('axios');
const router = useRouter();



//VARIABLES
let urlComponent = false;


//CONSTANTS
const store = useAppStore();
const componentId = getCurrentInstance().uid;



//WATCHES
const backgroundImage = computed(() => {
    if (login_checked.value === true && urlComponent === false) {
        return "url('/desktop.jpg')";
    } else {
        return 'none';
    }
})

//EVENTS
// onBeforeMount(() => {
//Hide background image if loaded from Flow
let queryString = window.location.search;
let urlParams = new URLSearchParams(queryString);
if (urlParams.has('component')) {
    urlComponent = true;
}
//})
onMounted(() => {
    checkLogin();
});
onActivated(() => {
    keyboard.addListener(componentId, onKeyPressHandler);
    keyboard.activate(componentId);
});
onDeactivated(() => {
    keyboard.removeListener(componentId);
});



//METHODS
const checkLogin = () => {
    let parameters = store.authToken ? store.authToken : '';
    axios({
        method: "get",
        url: "/login" + parameters,
        silent: true
    }).then(function (response) {
        if (response.data.message === 'Logged in') {
            processUserData(response);
            router.push({ name: props.next });
        } else {
            store.user.id = null;
        }
        login_checked.value = true;
    }).catch(function (error) {
        login_checked.value = true;
    });
}

const onKeyPressHandler = (event) => {
    if (event.shiftKey) {
        if (event.key === '*') {
            //
        }
    } else {
        if (event.key === 'Escape') {
            //
        }
    }
}

const login = () => {
    processing.value = true;
    let bodyFormData = new FormData();
    bodyFormData.append('email', data.username);
    bodyFormData.append('password', data.password);
    bodyFormData.append('remember', false);
    axios({
        method: "post",
        url: "/login",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
        silent: true
    }).then(function (response) {
        if (response.data.message === 'Logged in') {
            processUserData(response);
            data.username = '';
            data.password = '';
            message.value = 'Please login';
            router.push({ name: props.next });
        } else {
            message.value = response.data.message;
            processing.value = false;
        }
    }).catch(function (response) {
        if (response.data.errors) {
            let msg = '';
            for (const m in response.data.errors) {
                if (msg !== '') {
                    msg = msg + "<br>";
                }
                msg = msg + response.data.errors[m];
            }
            message.value = msg;
        } else if (response.data.message) {
            message.value = response.data.message;
        }
        processing.value = false;
    });
}

const processUserData = (response) => {
    store.user.id = response.data.user.id;
    store.user.firstname = response.data.user.firstname;
    store.user.lastname = response.data.user.lastname;
    store.user.email = response.data.user.email;
    store.user.authToken = response.data.token ? response.data.token : null;
    global.config.workflow_url = response.data.workflow_url;

    let permissions = [];
    response.data.user.permissions.forEach(item => {
        permissions.push(item);
    });
    store.user.permissions = permissions;

    let roles = [];
    response.data.user.roles.forEach(item => {
        roles.push(item);
    });
    store.user.roles = roles;

    processing.value = false;
}

</script>

<script>
export default {
    name: 'LoginPage',
    customOptions: {}
}
</script>
