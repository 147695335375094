<template>
    <div class="main-content-area">
        <div class="main-content-toolbar">
            <v-toolbar :height="50">
                <v-btn icon="mdi-reload" size="small" variant="text" :disabled="allowAdd == false"
                    @click="common.reloadHandler(state, data, populateGridWithData)"
                    v-tippy="{ content: 'Reload container list' }"></v-btn>
                <v-btn icon="mdi-printer" size="small" variant="text" :disabled="allowPrint == false"
                    @click="printStockContainer"></v-btn>
                <v-btn icon="mdi-check" size="small" variant="text" :disabled="state.isEditing == 'no'"
                    @click="finishEditingContainer" v-tippy="{ content: 'Finish editing container' }"></v-btn>
                <v-btn icon="mdi-file-delimited-outline" size="small" variant="text" @click="exportCsv"
                    v-tippy="{ content: 'Export container list as CSV file' }"></v-btn>
                <v-menu :close-on-content-click=false transition="none">
                    <template v-slot:activator="{ props }">
                        <v-btn icon="mdi-cog" dark v-bind="props" v-tippy="{ content: 'Show / Hide columns' }"></v-btn>
                    </template>
                    <v-list>
                        <v-list-item v-for="(columnContent, i) in columnDefs" :key="i" active-color="primary"
                            style="cursor:pointer;" @click="columnDefs[i].hide = !columnDefs[i].hide;">
                            <template v-slot:prepend>
                                <v-icon v-show="columnContent.hide" large color="#4E6F8E"
                                    style="margin-right: 15px;">mdi-checkbox-blank-circle-outline</v-icon>
                                <v-icon v-show="!columnContent.hide" large color="#4E6F8E"
                                    style="margin-right: 15px;">mdi-checkbox-marked-circle</v-icon>
                                <div style="font-size: 14px;"
                                    v-html="columnContent.headerName != '' ? columnContent.headerName : columnContent.headerComponentParams.template">
                                </div>
                            </template>
                        </v-list-item>
                    </v-list>
                </v-menu>

            </v-toolbar>

            <v-toolbar :height="50">
                <v-btn icon="mdi-help-circle" size="small" variant="text"
                    v-tippy="{ content: 'Activate help system. <br>[ Development in progress ]' }"></v-btn>
                <AppNotifications />
            </v-toolbar>
        </div>
        <div class="main-content-playground">
            <!--Id is important for the few jquery selects that we are doing below in order not to affect another grid-->
            <ag-grid-vue 
                id="stockGrid" 
                ref="stockGrid" style="width: 100%; height: 100%;" 
                class="ag-theme-alpine"
                :columnDefs="columnDefs" 
                :defaultColDef="defaultColDef" 
                :rowData="data.rowData"
                :pinnedTopRowData="data.pinnedTopRowData" 
                :gridOptions="gridOptions" 
                :editType="editType" 
                @contextmenu="stopEditingOnRightclick">
            </ag-grid-vue>
        </div>

        <div class="main-content-footer">
            <AppMessageNotifications />
            <v-toolbar :height="50">
                <b>{{ containerCount }}</b> Settings
            </v-toolbar>
        </div>

        <DialogNeo dialog-title="Confirm" :show="state.dialog" dialog-width="25%" dialog-height="100px">
            <template #content>
                <div class="center-flex">
                    Are you sure you want to delete the selected container?
                    <div v-for="(item, index) in state.nodesToDelete" :key="index">
                        <!--{{ item.data.reference }}-->
                    </div>
                </div>
            </template>
            <template #dialog-header-left>
                <v-btn icon="mdi-close-thick" size="small" variant="text" @click="cancelDeleteRecord"></v-btn>
                <v-btn icon="mdi-check-bold" size="small" variant="text" @click="confirmDeleteRecord"></v-btn>
            </template>
        </DialogNeo>

        <!--<div style="position:fixed;top:0px;right:0px;left:0;bottom:0;display: flex;flex-direction:column;z-index:1000000;">
            <div>
                <input :value="theValue" type="text" style="width:100%;height:50px;background-color:white;border:1px solid #4E6F8E;"/>
            </div>
            <div style="flex:1;position:relative;">
                <KeypadInput v-model:value="theValue"
                    backdrop="false"
                    :width="'30%'"
                    :height="'30%'"
                    show="true"
                    :all="keypadAllCharacters"
                    :allowed="keypadAllowedList"
                    :characters="4"
                    :characters_from="'end'"
                    :key_layout = "'keypad'"
                    input_selection = "false"
                    @update="updateTheValue"/>
            </div>
        </div>-->

    </div>
</template>

<script setup>

const theValue = ref(1);
const updateTheValue = (data) => {
    theValue.value = data.value;
}


//IMPORTS
import jQuery from "jquery";
import { inject, toRaw, ref, nextTick, reactive, onActivated, onDeactivated, onMounted, onBeforeMount, getCurrentInstance, computed } from 'vue'
import { useAppStore } from '@/stores/app'
import GridTooltip from '@/components/aggrid/GridTooltip'
import { useUtilities } from  '@/composables/useUtilities';
import { jsPDF } from "jspdf";
import { useSettingsPageDefinition } from '@/components/aggrid/definitions/useSettingsPageDefinition';
import { useCommon } from '@/components/aggrid/common/useCommon';
import { useRouter } from 'vue-router';
import { useAdvancedAxios } from '@/composables/useAdvancedAxios';
import { AgGridVue } from "ag-grid-vue3";
import { useKeyboard } from '@/composables/useKeyboard';
import _ from 'lodash';
import KeypadInput from '@/components/ui/KeypadInput';



//REFERENCES
const stockGrid = ref(null);



//INJECTIONS
const axios = inject('axios');
const dayJs = inject("dayJs")
const router = useRouter();



//VARIABLES
let gridApi = null;
let gridApi2 = ref(null);
let columnApi = null;
let completedContainers = false;
let deactivatingCellEditors  = false;



//CONSTANTS
const componentId = getCurrentInstance().uid;
const fields = {
    'stockContainers': [
        'id', 'unit_number', 'iso_code', 'construction_year', 'depot_id', 'matched_shipping_line', 'matched_leasing_company', 'gate_in_date', 'turn_in_reference',
        'ib_actual_visit_mode', 'inbound_carrier_id', 'ib_actual_visit', 'gate_out_date', 'booking_number', 'ob_actual_visit_mode', 'outbound_carrier_id',
        'ob_actual_visit', 'color', 'floor', 'temperature', 'humidity', 'ventilation', 'comment', 'crun_id', 'created_at', 'updated_at', 'system',
        'position', 'condition'
    ]
};
const $ = jQuery;
const store = useAppStore()
const editType = 'fullRow';
const components = {
    AgGridVue
};
const data = reactive({
    rowData: null,
    pinnedTopRowData: [],
    pinnedBottomRowData: []
})
const state = reactive({
    allowUnpin: false,
    isEditing: 'no',
    dialog: false,
    gridReady: false,
    document: null
})
//const keypadSelection=ref(['Jonny bon Jovi', 'Ion cu Doua', 'Superman', 'Batman']);
//const keypadAllCharacters=ref(["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]);
//const keypadAllCharacters=ref(["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "A","B","C","D","E","F","G","H","I","J","K","L","M", "T"]);
const keypadAllCharacters = ref(["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"]);
const keypadAllowedList = ref(['121123', '111']);


//CONSTANTS
const triggerButtonsUpdate = ref(true);
const allowAdd = computed(() => {
    let t = triggerButtonsUpdate.value;
    return common.allowAdd(data, gridApi, state);
})
const allowEdit = computed(() => {
    let t = triggerButtonsUpdate.value;
    return common.allowEdit(data, gridApi, state);
})
const allowDelete = computed(() => {
    let t = triggerButtonsUpdate.value;
    return common.allowDelete(data, gridApi, state);
})
const allowPrint = computed(() => {
    let t = triggerButtonsUpdate.value;
    let rows = gridApi ? gridApi.getSelectedNodes() : 0;
    return rows.length == 1 ? true : false;
})



//COMPOSABLES
const { common } = useCommon(stockGrid, state, gridApi2);
const { advancedAxios } = useAdvancedAxios(axios);
const { getSettingsPageDefinition } = useSettingsPageDefinition(store, dayJs);
const { keyboard } = useKeyboard();
const { utilities } = useUtilities();



const columnDefs = ref([])
const defaultColDef = reactive({
    resizable: true,
    editable: true,
    sortable: true,
    filter: true,
    floatingFilter: true,
    suppressMenu: true,        //To be set to true, hides filter in header
    filterParams: {
        debounceMs: 500,
        suppressAndOrCondition: true
    },
    floatingFilterComponentParams: {
        suppressFilterButton: true //To be set to true, hides filter button in floating filter
    },
    tooltipComponent: GridTooltip,
    suppressKeyboardEvent: function (params) {
        let event = params.event;
        let key = event.key;
        let deniedKeys = ['Escape'];
        let suppress = params.editing && deniedKeys.indexOf(key) >= 0;
        return suppress;
    },
})

const gridOptions = /*reactive(*/{
    ...common.gridOptions,
    onCellContextMenu: (params) => {
        //common.onCellContextMenu(params, gridApi, state);
    },
    onGridReady: (params) => {
        gridApi = params.api;
        gridApi2.value = params.api;
        columnApi = params.columnApi;
        common.onGridReady(params, getSettingsPageDefinition, columnDefs, state, populateGridWithData);
    },
    onRowEditingStarted: (params) => {
        triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
        common.onRowEditingStarted(params, state, componentId);
    },
    onCellValueChanged(params) {
        common.onCellValueChanged(params, columnApi);
    },
    onRowValueChanged: (params) => {
        let validation = validateRecord(params);
        if (validation.valid) {
            params.data.valid = true;
            let method = '';
            let url = '/yamax';
            if (params.data.id == '') {
                method = 'post';
            } else {
                method = 'put';
                url += '/' + params.data.id;
            }
            let sendData = { ...params.data };
            //We don't need to send this data
            delete sendData.stock_containers__stock_container;
            delete sendData.depot__company;
            delete sendData.report__report;
            delete sendData.shipping_line__company;

            advancedAxios.sendRequest(componentId + (params.data.id ? params.data.id : params.data.uniqueId), { method: method, url: url, data: sendData })
                .then(function (response) {
                    if (response.status == 200) {
                        if (method == 'post') {
                            let stockContainer = response.data.stockContainer;
                            toRaw(params.data).id = stockContainer.id;
                            toRaw(params.data).created_at = stockContainer.created_at;
                            toRaw(params.data).updated_at = stockContainer.updated_at;
                            toRaw(params.data).gate_in_date = stockContainer.gate_in_date;
                            params.node.setDataValue('id', stockContainer.id);
                            params.node.setDataValue('created_at', stockContainer.created_at);
                            params.node.setDataValue('updated_at', stockContainer.created_at);
                            params.node.setDataValue('gate_in_date', stockContainer.gate_in_date);
                        } else {
                            //update lastEdited - this we might need in the future
                        }
                        params.data.valid = true;
                        store.addNotificationMessage('Stock container saved!', 'success');
                    } else {
                        params.data.valid = false;
                    }
                    params.node.setDataValue('valid', params.data.valid);
                    triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
                }).catch(function (error) {
                    if (error.message !== 'canceled') {
                        params.data.valid = false;
                        params.node.setDataValue("valid", false);
                    }
                });
            state.isEditing = 'no';
        } else {
            params.data.valid = false;
            store.addNotificationMessage('Reference: <strong>' + params.data.reference + '</strong> has invalid data.\n' + validation.message, 'error');
        }

        //Reresh styles on rows
        //gridApi.setRowData(data.rowData);
        params.node.setDataValue('valid', params.data.valid);
    },

    onSelectionChanged: (params) => {
        triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
    },
    onPinnedRowDataChanged: (params) => {
        state.allowUnpin = (data.pinnedTopRowData.length === 0) ? false : true;
    },
    tabToNextCell(params) {
        return common.tabToNextCell(params, gridApi);
    },
    onBodyScroll: (event) => {
        if (!deactivatingCellEditors && event.direction=='horizontal'){
            deactivatingCellEditors=true;
            common.deactivateAllCellEditors(gridApi);
            setTimeout( function(){ deactivatingCellEditors=false; }, 2000);    //We do this so we don't trigger this method too many times and because bodyScrollEnd is not being triggered
        }
    },
    onBodyScrollEnd: (event) => {
        //This is currently not working but we will use it once the bug it's fixed by ag-grid developers
        deactivatingCellEditors=false;
    }
}



//EVENTS
onBeforeMount(() => { })
onMounted(() => { })
onActivated(() => {
    keyboard.addListener(componentId, onKeyPressHandler);
    keyboard.activate(componentId);
})
onDeactivated(() => {
    keyboard.removeListener(componentId);
})



//COMPUTED
const containerCount = computed(() => {
    return data.rowData ? data.rowData.length : 0;
})



//METHODS

const stopEditingOnRightclick = () => {
    common.stopEditingOnRightClick(state, gridApi);
}

const unpinRows = () => {
    common.unpinRows('containers', data, gridApi, store);
}

const onKeyPressHandler = (event) => {
    if (event.shiftKey) {
        if (event.key == '+') { addStockContainerHandler(); }
    } else {
        common.onKeyPressHandler(event, gridApi, data, state);
    }
}

const populateGridWithData = () => {
    state.gridReady = false;
    setTimeout(() => {
        gridApi.showLoadingOverlay();
    });
    if (completedContainers) {
        common.normalServerReload('/api/yamax?has_gate_out_date=true', axios, state, gridApi2, fields, store, modelSubmodelMap, columnDefs, prepareRecord, common.prepareListRecord, data, onSetupGrid);
    } else {
        common.normalServerReload('/api/yamax?has_gate_out_date=false', axios, state, gridApi2, fields, store, modelSubmodelMap, columnDefs, prepareRecord, common.prepareListRecord, data, onSetupGrid);
    }
}

const onSetupGrid = (response) => {
    state.gridReady = true;
    triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
}

const addStockContainerHandler = () => {
    gridApi.hideOverlay();
    state.isAdding = true;
    data.pinnedTopRowData.unshift(prepareRecord({}));
    nextTick(() => {
        let rowNode = gridOptions.api.getPinnedTopRow(0);
        gridApi.startEditingCell({ rowIndex: 0, colKey: state.firstVisibleColumn, rowPinned: 'top' });
        state.isEditing = 'pinned';
        state.isAdding = false;
    })
    triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
}

const prepareRecord = (item) => {
    return {
        ...common.prepareRecord(item)
        ,
        ...{
            valid: true,
            id: item.id || '',
            crun_id: item.id || '',
            turn_in_reference: _.get(item, 'return__reference.reference', '') || '',
            booking_number: _.get(item, 'release__reference.reference', '') || '',
            system: item.system || '',
            unit_number: item.unit_number || '',
            depot_id: item.depot_id || '',
            matched_leasing_company: item.matched_leasing_company || null,
            matched_shipping_line: item.matched_shipping_line || null,
            operator_name: item.operator_name || '',
            iso_code: item.iso_code || '',
            gate_in_date: item.gate_in_date || '',
            gate_out_date: item.gate_out_date || '',
            position: item.position || '',
            construction_year: item.report__report ? item.report__report.construction_year : '',
            ib_actual_visit_mode: item.ib_actual_visit_mode || '',
            ib_actual_visit: item.ib_actual_visit || '',
            ob_actual_visit_mode: item.ob_actual_visit_mode || '',
            ob_actual_visit: item.ob_actual_visit || '',
            condition: item.condition || '',
            last_on_hire_date: item.last_on_hire_date || '',
            comment: item.comment || '',
            color: item.color || '',
            floor: item.floor || '',
            temperature: item.temperature || '',
            humidity: item.humidity || '',
            ventilation: item.ventilation || '',
            created_at: item.created_at || '',
            updated_at: item.updated_at || '',
            return__reference: item.return__reference,
            release__reference: item.release__reference,
            depot__company: item.depot__company,
            shipping_line__company: item.shipping_line__company,
            inbound_carrier_id: item.inbound_carrier_id || '',
            outbound_carrier_id: item.outbound_carrier_id || '',
            returning_company_name: item.returning_company_name,
            report__report: item.report__report,
            task_status: item.stock_container__yamax_task ? item.stock_container__yamax_task.status : '',
        }
    }
}

const editRecord = () => {
    gridApi.stopEditing();
    let rows = gridApi.getSelectedNodes();
    let row = rows[0];
    gridApi.startEditingCell({ rowIndex: row.rowIndex, colKey: state.firstVisibleColumn });
    state.isEditing = 'existing';
}
const cancelStockContainer = () => {
    state.dialog = false
}

const deleteRecord = () => {
    if (!common.deleteEditingRow(gridApi, data, state)) {
        let nodes = gridApi.getSelectedNodes();
        let nonDeletable = 0;
        const total = nodes.length;
        state.nodesToDelete = [];
        nodes.forEach(item => {
            state.nodesToDelete.push(item);
        });
        if (nonDeletable > 0) {
            if (total > 1) {
                if (nonDeletable == total) { store.addNotificationMessage("The containers already have a Gate-Out date and can't be deleted", 'warning'); }
                else if (nonDeletable > 0) { store.addNotificationMessage("Some containers already have a Gate-Out date and can't be deleted", 'warning'); }
            } else {
                if (nonDeletable == 1) {
                    store.addNotificationMessage("The container already has a Gate-Out date and can't be deleted", 'warning');
                }
            }
        } else {
            state.dialog = true;
        }
    } else {
        triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
    }
}
const confirmDeleteRecord = () => {
    state.dialog = false
    let delData = [];
    let max = state.nodesToDelete.length;
    for (let i = 0; i < max; i++) {
        delData.push(state.nodesToDelete[i].data.id);
    }
    axios.delete('/stockContainer/' + delData.join(',')).then(function (response) {
        if (response.status == 200) {
            let max = state.nodesToDelete.length;
            for (let i = 0; i < max; i++) {
                let delIndex = data.rowData.findIndex(o => o.id === state.nodesToDelete[i].data.id);
                data.rowData.splice(delIndex, 1);
                gridApi.setRowData(data.rowData);
            }
            state.nodesToDelete = [];
            triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
        }
    }).catch(function (error) { });
}
const cancelDeleteRecord = () => {
    state.dialog = false
}

const finishEditingContainer = (cancel = false) => {
    gridApi.stopEditing();
    state.isEditing = 'no';
    triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
}

const exportCsv = () => {
    common.exportCsv(gridApi, {
        processHeaderCallback: function (params) {
            switch (params.column.colId) {
                case 'report':
                    return 'SC Flow Report';
                default:
                    return params.column.colDef.headerName;
            }
        }
    }, {
        processCellCallback: function (cell) {
            if (cell.column.colId == 'receipts') {
                if (cell.node.data.gate_in_date != '' && cell.node.data.gate_out_date == '') {
                    return 'In';
                }
                if (cell.node.data.gate_in_date != '' && cell.node.data.gate_out_date != '') {
                    return 'In/Out';
                }
                return false;
            } else {
                return false;
            }
        }
    });
}

const savePdf = () => {
    let nodes = gridApi.getSelectedNodes();
    let data = nodes[0].data;
    let sendData = { document: state.document };
    axios({ method: 'POST', url: '/stockContainer/' + data.id + '/saveGateOutPdf', data: sendData }).then(function (response) {
        if (response.status == 200) {
            triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
        }
    });
}

const generatePdf = () => {
    let nodes = gridApi.getSelectedNodes();
    let data = nodes[0].data;
    let sendData = { document: state.document };

    axios({
        method: 'GET',
        url: '/stockContainer/' + data.id + '/generateGateOutPdf',
        responseType: 'arraybuffer',
        data: sendData
    }).then(function (response) {
        if (response.status == 200) {
            let blob = new Blob([response.data], { type: 'application/pdf' });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'Report.pdf';
            link.click();
            //triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
        }
    })
}

const printStockContainer = () => {
    let nodes = gridApi.getSelectedNodes();

    let data = nodes[0].data;

    const doc = new jsPDF();
    doc.setFont('Arial', 'normal', 'bold');
    doc.setFontSize(12);
    doc.text("Gate-In Verification", 10, 14);
    doc.addImage("assets/maersk.jpg", "JPEG", 160, 12, 35, 19);

    doc.setFont('Arial', 'normal', 'normal');
    doc.setFontSize(11);

    doc.text("Reference No:", 10, 50);
    doc.text("Facility:", 10, 55);
    doc.text("Eir No:", 10, 60);
    doc.text("Container No:", 10, 65);
    doc.text("Gate-In Date:", 10, 70);
    doc.text("Company Name:", 10, 75);
    doc.text("License Plate:", 10, 80);
    doc.text("Inspection Condition:", 10, 85);
    doc.text("Remarks:", 10, 90);
    doc.text("Tracking Link:", 10, 95);

    doc.setFont('Arial', 'normal', 'bold');

    doc.text(data.return__reference ? data.return__reference.reference + '' : '', 50, 50);
    doc.text(data.depot__company ? data.depot__company.name : '', 50, 55);
    doc.text(data.crun_id + '', 50, 60);
    doc.text(data.unit_number + '', 50, 65);
    doc.text(utilities.formatDateTime(data.gate_in_date), 50, 70);
    doc.text(data.shipping_line__company ? data.shipping_line__company.name : '', 50, 75);
    doc.text(data.ib_actual_visit, 50, 80);
    doc.text(data.comment + '', 50, 90);

    doc.addImage("assets/survey-compass.jpg", "JPEG", 85, 275, 35, 8.75);


    doc.setFont('Arial', 'normal', 'bold');
    doc.setFontSize(9);
    doc.text('Print Date & Time', 167, 115);
    doc.setFont('Arial', 'normal', 'normal');

    let dateObj = new Date();
    let month = dateObj.getUTCMonth() + 1; //months from 1-12
    if (month < 10) { month = "0" + month; }
    let day = dateObj.getUTCDate();
    let year = dateObj.getUTCFullYear();
    let newdate = year + "-" + month + "-" + day + " " + dateObj.getHours() + ":" + dateObj.getMinutes() + ":" + dateObj.getSeconds();

    doc.text(utilities.formatDateTime(newdate), 167, 120);

    let document = doc.output('datauristring');
    state.document = document;

    doc.save("print.pdf");
}

const changeCompletedContainers = () => {
    completedContainers = !completedContainers;
    common.reloadHandler(state, data, populateGridWithData)
}

const validateRecord = (params) => {
    let ret = {
        valid: true,
        message: ''
    };

    ret.valid = true;
    return ret;
}

const modelSubmodelMap = {
    model: {
        'stockContainers': { target: function () { if (data.rowData === null) { data.rowData = []; } return data.rowData } }
    },
    submodels: {
        'matched_shipping_line': { target: function () { return columnDefs.value.find(def => def.field === 'matched_shipping_line') } },
        'depot_id': { target: function () { return columnDefs.value.find(def => def.field === 'depot_id') } },
        'matched_leasing_company': { target: function () { return columnDefs.value.find(def => def.field === 'matched_leasing_company') } },
        'ib_actual_visit_mode': { target: function () { return columnDefs.value.find(def => def.field === 'ib_actual_visit_mode') } },
        'ob_actual_visit_mode': { target: function () { return columnDefs.value.find(def => def.field === 'ob_actual_visit_mode') } },
        'iso_code': { id: 'code', name: ['code', 'survey_type__survey_type.name'], prepareRecord: common.prepareListRecord, target: function () { return columnDefs.value.find(def => def.field === 'iso_code').cellEditorParams.valueList } },
        'inbound_carrier_id': { unselectedOption: false, prepareRecord: common.prepareListRecord, target: function () { return columnDefs.value.find(def => def.field === 'inbound_carrier_id').cellEditorParams.valueList } },
        'outbound_carrier_id': { unselectedOption: false, prepareRecord: common.prepareListRecord, target: function () { return columnDefs.value.find(def => def.field === 'outbound_carrier_id').cellEditorParams.valueList } },
        'color': { target: function () { return columnDefs.value.find(def => def.field === 'color') } },
        'floor': { target: function () { return columnDefs.value.find(def => def.field === 'floor') } }
    }
};


const inputDialog = () => {

}

</script>

<script>
export default {
    name: 'SettingsPage',
    customOptions: {}
}
</script>