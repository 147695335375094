<template>
    <div style="display: flex; justify-content: flex-start;">
        <a v-if="value!==null" :href="value" target='_blank' rel="noreferrer noopener" v-tippy="{ content: 'Open report'}">
            <!--<v-icon dense color="primary">mdi-file-document-outline</v-icon>-->
            {{ props.params.data.report__report.id }}
        </a>
    </div>
</template>

<script setup>



    //IMPORTS
    import {ref, defineProps, onMounted} from 'vue';    



    //PROPS
    const props = defineProps({
        params:{}
    })



    //CONSTANTS
    const value = ref(null);



    //EVENTS
    onMounted(() => {
        value.value = props.params.data.report__report ? global.config.workflow_url +'/#m_r/view/' + props.params.data.report__report.id : null;
    });

 </script>