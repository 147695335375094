
let data = {};

export function useTransporter() {
    

    const getId = () => {
        let date = new Date();
        let unique = date.getTime();
        return unique;
    };

    const getData = (id) => {
        if (data[id]){
            let returnData = data[id];
            delete data[id];
            return returnData;
        }
        return false;
    }

    const setData = (id, val) => {
        data[id] = val;
    }

    return {
        transporter: {
            getId,
            getData,
            setData
        }
    };
            
}