import { toRaw } from 'vue';



export default class Validator {
    
    constructor() {
        this.validationData = {};
    }

    validate(data){
        this.validationData = toRaw(data);
        this.$v.value.$validate();
        if (this.$v.value.$error){
            let alreadyAdded = [];
            let message='';
            
            this.$v.value.$errors.forEach(item => {
                if (alreadyAdded[item.$property]!==true){
                    message = message + item.$message + '<br>';
                    alreadyAdded[item.$property] = true;
                }
            });

            return message;
        } else{
            return true;
        }
    }

    isRequired(attribute){
        let keys = Object.keys(this.validations);
        for(let i=0;i<keys.length;i++){
            if (attribute==keys[i] && this.validations[keys[i]]['required']){
                return true;
            }
        }
        return false;
    }
}
