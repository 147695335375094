//IMPORTS
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import App from './MobileView.vue';
import { createApp, ref } from 'vue';
import { aliases, mdi } from 'vuetify/lib/iconsets/mdi';
import '@mdi/font/css/materialdesignicons.css';
import { loadFonts } from './plugins/webfontloader';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import axiosInstance from '@/plugins/axios';
import { createPinia } from 'pinia';
import appNotifications from '@/components/AppNotifications';
import appMessageNotifications from '@/components/AppMessageNotifications';
import dataLoading from '@/components/ui/DataLoading';
import noData from '@/components/ui/NoData';
import dataLoadingError from '@/components/ui/DataLoadingError';
import Vue3TouchEvents from "vue3-touch-events";
//import appSounds from '@/plugins/appSounds';
import Acl from '@/plugins/acl';
import VueClick from 'vue-click'
import dayjs from 'dayjs'
//import tooltip from "./directives/tooltip.js";
//import "@/assets/css/tooltip.css";
import router from '@/router/mobileRouter';
import quickNotifications from '@/components/QuickNotifications';
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";


//CONSTANTS
const theme = ref('light')
const pinia = createPinia()
const vuetify = createVuetify({
    components,
    directives,
    toggleTheme: () => theme.value = theme.value === 'light' ? 'dark' : 'light',
    icons: {
        defaultSet: 'mdi',
        sets: {
            mdi,
        },
        aliases: {
            ...aliases,
            home: 'mdi-home',
            account: 'mdi-account'
        }
    },
    theme: {
        defaultTheme: 'compassTheme',
        themes: {
          compassTheme: {
            dark: false,
            colors: {
                background: '#FFFFFF',
                surface: '#FFFFFF',
                    primary: '#4E6F8E',
                'primary-darken-1': '#3700B3',
                    secondary: '#DBDED9',
                'secondary-darken-1': '#018786',
                    error: '#630712',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FB8C00'
            }
          }
        }
    }
});




//CODE
loadFonts();
let app = createApp(App);
app.provide('axios', axiosInstance);
app.provide('theme', 'compassTheme');
//app.provide('appSounds', appSounds);
app.provide('dayJs', dayjs)
app.component('DataLoading', dataLoading);
app.component('NoData', noData);
app.component('DataLoadingError', dataLoadingError);
app.component('AppNotifications', appNotifications);
app.component('AppMessageNotifications', appMessageNotifications);
app.component('QuickNotifications', quickNotifications);
app.use(vuetify);
app.use(pinia);
app.use(Vue3TouchEvents, {
    swipeTolerance: 100
});
app.use(VueClick);
//app.directive("tooltip", tooltip);
app.use(router);

import { useAppStore } from '@/stores/app';
const store = useAppStore();

//SENTRY
if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        app,
        dsn: "https://dbb3bd3f836d43cc8e6c9a5767522b38@sentry.survey-compass.com/14",
        integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ["localhost", "my-site-url.com", /^\//],
        }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

global.config = {};
global.config.the_flow_url = process.env.VUE_APP_THE_FLOW_URL;
//global.config.workflow_url = process.env.VUE_APP_WORKFLOW_URL;
axiosInstance.defaults.baseURL = global.config.the_flow_url;
app.use(Acl, store.user);
store.user.app = 'mobile';
app.mount('#app');