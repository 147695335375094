import { isMobile } from 'mobile-device-detect';
import { isAndroid } from 'mobile-device-detect';
import { isIOS } from 'mobile-device-detect';
import { isTablet } from 'mobile-device-detect';
import { Capacitor } from '@capacitor/core';



let userAgent = navigator.userAgent.toLowerCase();
if (userAgent.match(/android|iphone|ipad|ipod|windows phone|iemobile|wpdesktop/)) {
    require('./mobile.js');
} else {
    require('./desktop.js');
}