<template>
    <div class="main-content-area">
        <div class="main-content-playground">
            
            <v-menu location='end'>
                <template v-slot:activator="{ props }">
                    <v-btn size="x-large" icon="mdi-menu" variant="text" v-bind="props" style="position: absolute; left: 0; top: 0; z-index: 1; color: #fff;"></v-btn>
                </template>
                <v-list>
                    <v-list-item :key="1">
                        <v-list-item-title @click="loadYamax">YAMAX</v-list-item-title>
                    </v-list-item>
                    <v-list-item :key="2">
                        <v-list-item-title @click="loadSettings">SETTINGS</v-list-item-title>
                    </v-list-item>
                    <v-list-item :key="3">
                        <v-list-item-title @click="loadAbout">ABOUT</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>            

            <v-img
                lazy-src="background.jpg"
                src="background.jpg"
                :cover=true
                justify="center"
                height='100vh'
            ></v-img>
            
            <v-img
                lazy-src="logo.png"
                src="logo.png"
                width="40px"
                style="position: absolute; right: 20px; top: 20px; z-index: 1;"
            ></v-img>
        </div>
        <div class="main-content-footer">
            <AppMessageNotifications />
            <v-toolbar :height="40">
            </v-toolbar>            
            <v-toolbar :height="40">
            </v-toolbar>            
        </div>
    </div>
</template>

<script setup>

    //IMPORTS
    import { reactive } from 'vue'
    import { useAppStore } from '@/stores/app'
    import { useRouter } from 'vue-router';
    import { App } from '@capacitor/app';
    import { Device } from '@capacitor/device';
    import { Capacitor } from '@capacitor/core';



    //INJECTIONS
    const router = useRouter();
    
    

    //CONSTANTS
    const store = useAppStore()
    const state = reactive({
        dialog: false,
        firstVisibleColumn: null
    })


    
    //METHODS
    const loadYamax = () => {
        router.push({ name: 'RsoApp' });
    }
    const loadSettings = () => {
        router.push({ name: 'AppSettings' });
    }    
    const loadAbout = () => {
        let platform = Capacitor.getPlatform()
        if (platform=='android'){
            (async () => {
                const info = await Device.getInfo();
                const appInfo = await App.getInfo();
                let data = "";
                data = data + "Version: "+appInfo.version+" \r\n Build: " +appInfo.build;
                alert(data);
            })();
        }else{
            alert("Information available when runnig in Android.")
        }
    }
    
</script>