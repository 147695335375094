
let ajaxHub = [];

export function useAdvancedAxios(axios) {
    
    //Axios call
    const sendRequest = (id, axiosObject, onRequestCancel) => {
        ajaxHub[id] = {
            id: id,
            status: 'new',
            requestHandle: null,
            abortController: null
        }
        ajaxHub[id].abortController = new AbortController();
        axiosObject = { ...axiosObject, ...{ signal: ajaxHub[id].abortController.signal } };
        return axios(axiosObject);
    };

    const cancelRequest = (id) => {
        if (ajaxHub[id] && ajaxHub[id]['abortController']!==null){
            ajaxHub[id].abortController.abort();
            ajaxHub[id].status='cancel';
            ajaxHub[id].abortController=null;
        }
    }

    return {
        advancedAxios: {
            sendRequest,
            cancelRequest
        }
    };
            
}