<template>
    <Teleport to="#app">
        <div v-if="currentNotification!=null" class="quick-notifications">
            <div class="dialog-backdrop">
            </div>
            <div>
                <div class="quick-notifications-content">
                    <div class="mdi-check-outline mdi quick-notifications-icon"></div>
                </div>
            </div>
    </div>
    </Teleport>
</template>

<script setup>
    
    
    
    //IMPORTS
    import { ref, reactive, computed, onBeforeMount } from 'vue'
    import { useNotification } from '@/composables/useNotification';


    //VARIABLES

    
    
    //CONSTANTS
    


    //COMPOSABLES
    const { notification } = useNotification();

    
    
    //EVENTS
    onBeforeMount(() => { })

    
    
    //COMPUTED
    const currentNotification = computed(() => {
        return notification.notification.value;
    })
    
    
    //METHODS



</script>
