<template>
    <div class="main-content-area" ref="focusTarget">
        <div class="main-content-toolbar">
            <v-toolbar :height="50">
            </v-toolbar>
            <v-toolbar :height="50">
                EDI Messages for {{ unitNumber }}
            </v-toolbar>
            <v-toolbar :height="50">
                <v-btn icon="mdi-close-thick" size="small" variant="text" @click="$emit('close')"></v-btn>
            </v-toolbar>
        </div>

        <div class="main-content-playground">
            <ag-grid-vue ref="ediGrid" style="width: 100%; height: 100%;" class="ag-theme-alpine"
                :columnDefs="columnDefs" 
                :defaultColDef="defaultColDef" 
                :components="components" 
                :rowData="data.rowData"
                :pinnedTopRowData="data.pinnedTopRowData" 
                :gridOptions="gridOptions" 
                :editType="editType">
            </ag-grid-vue>
        </div>
        <div class="main-content-footer">
            <AppMessageNotifications />
            <v-toolbar :height="40">
            </v-toolbar>
            <v-toolbar :height="40">
                <div style="display: flex;justify-content: right;align-items: center;"><strong>Total messages:</strong>
                    &nbsp; {{ totalMessages }}
                </div>
            </v-toolbar>
        </div>
    </div>
</template>

<script setup>



//IMPORTS
import { inject, toRaw, ref, reactive, onMounted, onUnmounted, nextTick, defineEmits, defineProps, getCurrentInstance, computed, onActivated, onDeactivated } from 'vue';
import { useAppStore } from '@/stores/app';
import { useCommon } from '@/components/aggrid/common/useCommon';
import { useAdvancedAxios } from '@/composables/useAdvancedAxios';
import { AgGridVue } from "ag-grid-vue3";
import { useKeyboard } from '@/composables/useKeyboard';
import { useUtilities } from '@/composables/useUtilities';
import DownloadFileCellRenderer from '@/components/aggrid/cell_renderers/DownloadFileCellRenderer';


//PROPS
const store = useAppStore();

const props = defineProps({
    'ediList': {
        type: Object,
        required: true
    }
});


//REFERENCES
const ediGrid = ref(null);



//INJECTIONS
const axios = inject('axios')
const storage = inject('storageManager');
const dayJs = inject("dayJs")



//EMITS
const emits = defineEmits(['close']);



//VARIABLES
let gridApi = null;
let gridApi2 = ref(null);
let columnApi = null;
let allColumnIds = [];
let instance = ref(getCurrentInstance());
let deactivatingCellEditors = false;



//CONSTANTS
const componentName = getCurrentInstance().type.name;
const componentId = getCurrentInstance().uid;
const editType = 'fullRow';
const components = {
    AgGridVue
};
const data = reactive({
    rowData: null,
    pinnedTopRowData: [],
    pinnedBottomRowData: []

})
const fields = [

];
const state = reactive({
    dialog: false,
    gridReady: false,
    firstVisibleColumn: null
})
const triggerButtonsUpdate = ref(true);



//COMPUTED
const totalMessages = computed(() => {
    return data.rowData ? data.rowData.length : '0';
})

const unitNumber = computed(() => {
    return props.ediList.record ? props.ediList.record.unit_number : 'Unknown';
})


//COMPOSABLES
const { common } = useCommon(ediGrid, state, gridApi2);
const { keyboard } = useKeyboard();

const ediDefs = () => {
    return [
        { headerName: 'ID', field: 'id', hide: true},
        { headerName: 'Name', field: 'uploadName', flex: 1},
        {
            headerName: 'View',
            field: 'resource',
            cellRendererFramework: DownloadFileCellRenderer,
            width : 75
        },
        { headerName: 'Uploaded', field: 'uploaded'},
        // Add more columns as needed
    ]
};

const ediDefinition = () => {
    let originalColumnDefs = [];
    toRaw(ediDefs.value).forEach(item => {
        originalColumnDefs.push({ ...item });
    });

    let newColumnDefs = [
        // Add any additional columns or modifications here
        ...originalColumnDefs
    ];

    return newColumnDefs;
};

const columnDefs = ref([])
const defaultColDef = reactive({
    ...common.defaultColDef, ...{
        /*  floatingFilter: true,
          floatingFilterComponentParams: {
              suppressFilterButton: false
          }*/
    }
});
const gridOptions = /*reactive(*/{
    ...common.gridOptions,
    overlayNoRowsTemplate: "<strong>No messages</strong>",
    noRowsOverlayComponent: null,
    noRowsOverlayComponentParams: {
        click: () => { gridApi.hideOverlay(); }
    },
    onCellContextMenu: (params) => {
        //common.onCellContextMenu(params, gridApi, state);
    },

    onGridReady: (params) => {
        gridApi = params.api;
        gridApi2.value = params.api;
        columnApi = params.columnApi;
        common.onGridReady(params, ediDefs, columnDefs, state, populateGridWithData);
    }
}

//EVENTS
onActivated(() => {
    keyboard.addListener(componentId, onKeyPressHandler);
    keyboard.activate(componentId);
});
onDeactivated(() => {
    keyboard.removeListener(componentId);
});
onMounted(() => {
    keyboard.addListener(componentId, onKeyPressHandler);
    keyboard.activate(componentId);
});
onUnmounted(() => {
    gridApi = null;
    gridApi2.value = null;
    keyboard.removeListener(componentId);
})

//METHODS
const onKeyPressHandler = (event) => {
    if (state.containersDialog === false) {
        if (event.shiftKey) {
            if (event.key == '+') {
                //
            }
        } else {
            common.onKeyPressHandler(event, gridApi, data, state);
        }
    }
}

const populateGridWithData = async () => {
    try {
        state.gridReady = false;
        setTimeout(() => {
            gridApi.showLoadingOverlay();
        });
        columnDefs.value = ediDefs();
        common.normalServerReload('/attachments/' + props.ediList.record.id, axios, state, gridApi2, fields, store, modelSubmodelMap, columnDefs, prepareRecord, common.prepareListRecord, data, onSetupGrid);
    } catch (error) {
        console.error('Error fetching attachments:', error);
        // Handle error
    }
};

const onSetupGrid = (response) => {
    console.log('RESPONSE');
    console.log(response);

    state.gridReady = true;
    triggerButtonsUpdate.value = !triggerButtonsUpdate.value;
    console.log(data.rowData);
}


const prepareRecord = (item) => {
    console.log(item);
    return {
        valid: true,
        id: item.id,
        uploadName: item.uploadName,
        resource: item.resource,
        uploaded: item.created,
    };
}

const modelSubmodelMap = {
    model: {
        'ediList': { prepareRecord: prepareRecord, target: () => { if (data.rowData === null) { data.rowData = []; } return data.rowData } }
    },
    submodels: {
    }
};

</script>

<script>
export default {
    name: 'AttachmentsList',
}
</script>
