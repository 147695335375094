<template>
    <div ref="datepicker" @keydown="onKeyHandler" class="neo-inputer">
        <flat-pickr 
            ref="picker" 
            v-model="date" 
            class="ag-input-field-input ag-text-field-input" 
            :config="config" 
            appendTo="'body'"
            @onOpen="onOpenHandler"
            @on-change="onChangeHandler" 
            @on-close="onCloseHandler" 
        ></flat-pickr>
        <Teleport to="body">
            <v-item-group 
                v-if="opened==true && props.params.buttons==true" 
                class="neo-flatpickr" 
                :style="{ position: 'absolute', zIndex: 1, transition: 'none', left: (popupPosition.left+'px'), top: (popupPosition.top+'px'), right: (popupPosition.right+'px'), bottom: (popupPosition.bottom+'px'), maxHeight: ((popupPosition.maxHeight+'px')) }"
                >
                <v-btn append color="primary"  class="flex-grow-1" @mousedown="selectEndOfMonth">End</v-btn>
                <v-btn append color="primary"  class="flex-grow-1" @mousedown="selectPlusDays($event, 30)">+30</v-btn>
                <v-btn append color="primary"  class="flex-grow-1" @mousedown="selectPlusDays($event, 60)">+60</v-btn>
            </v-item-group>
        </Teleport>
    </div>
</template>

<script>
    import flatPickr from 'vue-flatpickr-component';
    import 'flatpickr/dist/flatpickr.css';
    import { ref, onMounted, onBeforeMount, reactive, inject } from 'vue';
    import { useAppStore } from '@/stores/app'
    import { useUtilities } from  '@/composables/useUtilities';

    
    
    export default {
        name: 'GridDateEditor',
        components: {
            flatPickr
        },
		setup(props, context){    
            /*params:{
                //format:   datetime,null,not_defined / date 
                //buttons:  true / false
            }*/
            const store = useAppStore();
            //!!! props.params - get access to grid api and stuff
            //!!! If paramName is passed in through cellEditorParams then we can access props.params.propName
       
            const { utilities } = useUtilities(store);

            

            //INJECTIONS
            const dayJs = inject("dayJs");    


            
            //CONSTANTS
            //Initial value
            //console.log('Initial value');
            //console.log(props.params.value);
            let date=ref(props.params.value);
            const popupPosition=reactive({
                left: 'auto',
                top: 'auto',
                right: 'auto',
                bottom: 'auto',
                maxHeight: 'auto',
                minWidth: 'auto'
            });
            const opened=ref(false);
            
            
            
            //VARIABLES
            let instance = null;
            let valueUpdated = false;
            
            
            //REFERENCES
            const datepicker=ref();
            const picker=ref();
            
            
            
            //Datepicker config
            const config = {
                //wrap: true,
                altInput: true,
                altFormat: props.params.format==='date' ? store.user.config.dateFormat : store.user.config.dateTimeFormat,
                dateFormat: props.params.format==='date' ? store.user.config.dateFormat : store.user.config.dateTimeFormat,
                enableTime: props.params.format==='date' ? false : true,
                utc: true,

                parseDate: (datestr, format) => {
                    return fromUtcToLocalTimezone(datestr, format);
                },
                formatDate: function(date, format){
                    return dayJs(date).format(format);
                },

                locale: {
                    "firstDayOfWeek": 1 // start week on Monday
                },
                time_24hr: true,
                onReady: function(){ 
                    instance = this;
                },
                onValueUpdate: function(){ },
                onClick: () => {

                },
                onOpen: (params) => {
                    if ((props.params.value===null || props.params.value==='')){
                        if (props.params.config && props.params.config.autoSetDate){
                            let autoSetDate=props.params.config.autoSetDate;
                            if (autoSetDate==='now'){ autoSetDate = new Date();}
                            if (autoSetDate instanceof Date){
                                autoSetDate = autoSetDate.getFullYear() + "-" + ("0"+(autoSetDate.getMonth()+1)).slice(-2) + "-" + ("0" + autoSetDate.getDate()).slice(-2) + " " + ("0" + autoSetDate.getHours()).slice(-2) + ":" + ("0" + autoSetDate.getMinutes()).slice(-2);
                            }
                            setValue(autoSetDate);
                        }
                    }
                },
                onClose: () => {
                    //
                },
                onChange: (selectedDates, dateStr, instance) => {
                    //
                },
                onKeyDown: (selectedDates, dateStr, instance, event) => {
                    const activeElement = document.activeElement;
                    if (event.keyCode==9 && activeElement !== instance.minuteElement && activeElement !== instance.hourElement && activeElement !== instance.secondElement && 
                    activeElement !== instance.yearElements && activeElement !== instance.monthElements ){
                        setTimeout(function(){props.params.api.tabToNextCell();},1);    //Need this settimeout because otherwise it's not working.
                        event.stopPropagation();
                    }
                },
                        ...props.params.config
            }
            

            
            
            //EVENTS
            onBeforeMount(() => {
                const { context } = props.params;
            })
            
            onMounted(() => {
            })
            
            
            
            //METHODS
            const onOpenHandler = () => {
                opened.value=true;
                //Position of the edited cell
                let position = datepicker.value.parentNode.getBoundingClientRect();
                popupPosition.bottom='auto';
                popupPosition.top=position.top;
                popupPosition.maxHeight=window.innerHeight-position.bottom;

                if (window.innerWidth - (position.left + position.width) < 202 ){
                    popupPosition.left='auto';
                    popupPosition.right=window.innerWidth-position.left;
                }else{
                    popupPosition.left=position.right;
                    popupPosition.right='auto';
                }
                popupPosition.minWidth=position.width;
            }
            const onGridBlur = (event) => {
                event.preventDefault();
                event.stopPropagation();
                return false;
            }
            const onSelect = (event) => {
                //
            }
            
            const onDateChanged = (selectedDates) => {
            }
            
            //This method is called when pressing the TAB key when editing
            const activateCellEditor = () => {
                picker.value.fp.open();
            }
        
            const getValue = () => {
                //console.log('Get Value');
                //console.log(valueUpdated);
                //console.log(valueUpdated ? fromLocalToUtcTimezone(date.value) :date.value);
                return valueUpdated ? fromLocalToUtcTimezone(date.value) :date.value;
            }
                    
            const setValue = (d) => {
                valueUpdated = true;
                date.value = d;
            }

            //Called before editing starts. Posibility to cancel edit.
            const isCancelBeforeStart = () => {
                return false;
            };

            //Called when editing ends. If return true then result of edit will be ignored.
            const isCancelAfterEnd = () => {
                return false;
            };
     
            const onChangeHandler = (selectedDates) => {
                valueUpdated = true;
                /* this might need ot be run
                let theDate = selectedDates[0] || null;
                // Tell grid to run filter operation again
                props.params.onDateChanged();
                filterInstance.applyModel();*/
            }
            const onCloseHandler = () => {
                opened.value=false;
            }
            
            
            const onInputBoxChanged = () => {
                if (date.value == '') {
                    // Remove the filter
                    this.params.parentFilterInstance((instance) => {
                        instance.onFloatingFilterChanged(null, null);
                    });
                    return;
                }
                
                this.params.parentFilterInstance((instance) => {
                    instance.onFloatingFilterChanged('greaterThan', date.value);
                });
            }
                    
            const onParentModelChanged = (parentModel) => {
                // When the filter is empty we will receive a null value here
                if (!parentModel) {
                    date.value = '';
                } else {
                    date.value = parentModel.filter;
                }
            }
            
            const focusIn = () => { //Called when tab is presed entering the cell in row edit mode
                datepicker.value.children[1].focus();
            }
            const focusOut = () => { //Called when tab is pressed when on the cell in row edit mode
                opened.value=false;
            }
            
            const selectEndOfMonth = (event) => {
                let d = new Date(date.value==='' ? (new Date()) : date.value);
                d.setDate(1);
                d.setMonth(d.getMonth()+1);
                d.setDate(d.getDate()-1);
                date.value=d;
                datepicker.value.children[1].focus();
                event.preventDefault();
            }
            const selectPlusDays = (event,days) => {
                let d = new Date(date.value==''?(new Date()):date.value);
                d.setDate(d.getDate()+days);
                date.value=d;
                datepicker.value.children[1].focus();
                event.preventDefault();
            }
            
            const onKeyHandler =(event) => {
                if (event.keyCode==35){
                    selectEndOfMonth(event);
                }else
                if (event.keyCode==107){
                    selectPlusDays(event, 30);
                }
            }

            const fromUtcToLocalTimezone = (datestr, format) => {
                if (!datestr) return '';
                const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                //First get the time based on the logged in user timezone and format it without the timezone part
                let userTimezoneDateTime = dayJs(datestr).utc(true).tz(store.user.config.timezone).format(format);
                //Then set this time as the local timezone time so flatpickr does not change it any more
                let browserTimezoneDateTime = dayJs.tz(userTimezoneDateTime, timezone);
                return browserTimezoneDateTime.toDate();
            }
            const fromLocalToUtcTimezone = (date) => {
                if (!date) return '';
                //console.log('Local -> UTC');
                //console.log(store.user.config.timezone);
                //console.log(date);
                let currentDateWithoutTimezone = dayJs(date).format(config.dateFormat);
                let userTimezoneDateTime = dayJs.tz(currentDateWithoutTimezone, store.user.config.timezone);
                let utcDatetime = userTimezoneDateTime.tz('UTC');

                /*If props.params.format==='date' means that only a date is sent to this datepicker
                In this case the datepicker defaults the time to 00:00:00 which when converted to UTC -2 hours for example, the day will be substracted by 1
                To avoid this we add 23 hours because it's only the date that is important*/
                if (props.params.format==='date'){
                    utcDatetime = utcDatetime.add(23, 'hour');
                }

                //console.log(utcDatetime.format(config.dateFormat));
                return utcDatetime.format(config.dateFormat);
            }



            return {
                props,
                date,
                picker,
                datepicker,
                opened,
                popupPosition,
                config,
                onOpenHandler,
                onGridBlur,
                onSelect,
                onDateChanged,
                activateCellEditor,
                getValue,
                setValue,
                isCancelBeforeStart,
                isCancelAfterEnd,
                onChangeHandler,
                onCloseHandler,
                onInputBoxChanged,
                onParentModelChanged,
                focusIn,
                focusOut,
                selectEndOfMonth,
                selectPlusDays,
                onKeyHandler,
                fromUtcToLocalTimezone,
                fromLocalToUtcTimezone
            }

        }
    }
    
</script>

<style>
    .neo-inputer{
        width: 100%;
        height: 100%;
    }
    .neo-inputer input{
        width: 100%;
        height: 100%;
        padding-left: 5px;
    }
    .neo-flatpickr{
        position: absolute; 
        z-index: 1;
        padding: 2px;
        padding-left: 3px;
    }
    .neo-flatpickr button:first-child{
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .neo-flatpickr button:nth-child(2){
        border-radius: 0;
    }
    .neo-flatpickr button:last-child{
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    
    .flatpickr-calendar.animate.open {
        animation: none !important;
        -webkit-animation: none !important;
    }
</style>