<template>
    <div style="border:2px solid red">
    <div ref="datepicker" @keydown="onKeyHandler">
        <flat-pickr 
            ref="picker" 
            v-model="date" 
            class="ag-input-field-input ag-text-field-input" 
            :config="config" 
            appendTo="'body'"
            @onOpen="onOpenHandler"
            @on-change="onChangeHandler" 
            @on-close="onCloseHandler" 
        ></flat-pickr>
        <Teleport to="body">
            <v-item-group 
                v-if="opened==true && props.params.buttons==true" 
                class="neo-flatpickr" 
                :style="{ position: 'absolute', zIndex: 1, transition: 'none', left: (popupPosition.left+'px'), top: (popupPosition.top+'px'), right: (popupPosition.right+'px'), bottom: (popupPosition.bottom+'px'), maxHeight: ((popupPosition.maxHeight+'px')) }"
                >
                <v-btn append color="primary"  class="flex-grow-1" @mousedown="selectEndOfMonth">End</v-btn>
                <v-btn append color="primary"  class="flex-grow-1" @mousedown="selectPlusDays($event, 30)">+30</v-btn>
                <v-btn append color="primary"  class="flex-grow-1" @mousedown="selectPlusDays($event, 60)">+60</v-btn>
            </v-item-group>
        </Teleport>
    </div>
    </div>
</template>

<script setup>
    import 'flatpickr/dist/flatpickr.css';
    import { ref, defineProps, onMounted, onBeforeMount, inject } from 'vue';
    
    
    
    //INJECTIONS
    const dayJs = inject("dayJs")    
    
    
    
    //PROPS
    const props = defineProps({
        params:{
        }
    })
    //!!! props.params - get access to grid api and stuff
    //!!! If paramName is passed in through cellEditorParams then we can access props.params.propName
       
    //Initial value
    let date=ref(new Date('2000-01-01 10:00:01'));
    let autoSetDate = null;
    
    
    
    
    //REFERENCES
    const datepicker=ref();
    const picker=ref();
    
    
    
    //Datepicker config
    const config = {
        //wrap: true,
        altInput: true,
        altFormat: "Y-m-d H:i",
        dateFormat: "Y-m-d H:i",
        enableTime: true,
        position: 'below right',
        locale: {
            "firstDayOfWeek": 1 // start week on Monday
        },
        time_24hr: true,
        onReady: function(){ 
            //
        },
        onValueUpdate: function(){ },
        onClick: () => {

        },
        onOpen: (params) => {
            if ((date.value===null || date.value=='')){
                if (autoSetDate){
                    let autoSetDate=autoSetDate;
                    if (autoSetDate==='now'){ autoSetDate = new Date();}
                    if (autoSetDate instanceof Date){
                        autoSetDate = autoSetDate.getFullYear() + "-" + ("0"+(autoSetDate.getMonth()+1)).slice(-2) + "-" + ("0" + autoSetDate.getDate()).slice(-2) + " " + ("0" + autoSetDate.getHours()).slice(-2) + ":" + ("0" + autoSetDate.getMinutes()).slice(-2);
                    }
                    date.value = autoSetDate;
                }
            }
        },
        onClose: () => {
        },
        onChange: (selectedDates, dateStr, instance) => {
            //
        },
    }
        
    
    
    //EVENTS
    onBeforeMount(() => {
    })
    
    onMounted(() => {
    })
    
    
    
    //METHODS
    const onInputBoxChanged = () => {
        if (date.value == '') {
            // Remove the filter
            this.params.parentFilterInstance((instance) => {
                instance.onFloatingFilterChanged(null, null);
            });
            return;
        }
        
        this.params.parentFilterInstance((instance) => {
            instance.onFloatingFilterChanged('greaterThan', date.value);
        });
    }
</script>