//IMPORTS
import { onMounted, onUnmounted } from "vue"



//VARIABLES
let initialized = false;
let current=null;
let tree = {
    id: null,
    function: null,
    parent: null,
    children: []
};
let treeStart = tree;
let treePointer = tree;


let whitelist = ['id', 'function', 'children'];

export function useKeyboard() {
    
    const addListener = (id, func) => {
        //console.log(' ');
        //console.log('ADD');
        //console.log('START');
        //console.log(JSON.parse(JSON.stringify(tree, whitelist)));
        //console.log(JSON.parse(JSON.stringify(treePointer, whitelist)));

        let node = searchNode(tree, id);
        if (!node){
            //console.log('Added listener id: ' + id);
            let currentNode=null;
            if (treePointer.parent==null){
                currentNode = tree;
            }else{
                currentNode = searchNode(treePointer.parent, treePointer.id);
            }

            treePointer.children.push({
                id: id,
                function: func,
                parent: currentNode,
                children: []
            });
            treePointer = treePointer.children[ treePointer.children.length-1];
            //treePointer = searchNode(treePointer, id);

            //console.log('STOP');
            //console.log(JSON.parse(JSON.stringify(tree, whitelist)));
            //console.log(JSON.parse(JSON.stringify(treePointer, whitelist)));
        }else{
            //console.log('Listener already exists id: ' + id);
        }
    }

    const removeListener = (id) => {
        // console.log(' ');
        // console.log('REMOVE');
        // console.log('START');
        // console.log(JSON.parse(JSON.stringify(tree, whitelist)));
        // console.log(JSON.parse(JSON.stringify(treePointer, whitelist)));

        let parentNode = null;
        let foundNode = searchNode(treeStart, id);
        let childIndex = null;
        if (foundNode){
            parentNode = foundNode.parent;
            for(let i=0; i<=parentNode.children.length-1;i++){
                if (parentNode.children[i].id==id){
                    childIndex = i;
                }
            }

            //The node to remove is the current node. We need to set a new current node
            if (foundNode.id===treePointer.id){
                treePointer = treePointer.parent;
            }else{
                //The node to remove is an ancestor of the current node. we need to set a new current node
                let node = searchNode(foundNode, treePointer.id);
                if (node){
                    treePointer = parentNode;
                }
            }

            //Delete the found node together with all the tree
            parentNode.children.splice(childIndex, 1);

            //console.log('Removed listener with id: ' + id);

            //console.log('STOP');
            //console.log(JSON.parse(JSON.stringify(tree, whitelist)));
            //console.log(JSON.parse(JSON.stringify(treePointer, whitelist)));
        }else{
            //console.log("Can't find id to remove: " + id);
        }
    }

    const activate = (id) => {
        //console.log(' ');
        //console.log('ACTIVATE');
        //console.log('START');
        //console.log(JSON.parse(JSON.stringify(tree, whitelist)));
        //console.log(JSON.parse(JSON.stringify(treePointer, whitelist)));

        let node = searchNode(treeStart, id);
        //console.log('To Activate');
        //console.log(JSON.parse(JSON.stringify(node, whitelist)));
        if (node){
            treePointer = node;
            //console.log('Activated listener id: ' + treePointer.id);
        }else{
            console.log('Node to activate does not exist id: ' + id);
        }
        //console.log('STOP');
        //console.log(JSON.parse(JSON.stringify(tree, whitelist)));
        //console.log(JSON.parse(JSON.stringify(treePointer, whitelist)));
}

    const runListeners = (event) => {
        if (treePointer.id){
            //console.log('Running listener id: ' + treePointer.id);
            treePointer.function(event);
        }
    }

    const searchNode = (node, id) => {
        //console.log('SEARCH');
        //console.log('Node to search with id');
        //console.log(id);
        //console.log(JSON.parse(JSON.stringify(node, whitelist)));
        for (let i=0;i<=node.children.length-1;i++){
            //console.log('Search compare');
            //console.log(node.children[i].id+'---'+id);
            if (node.children[i].id===id){
                return node.children[i];
            }else{
                if (node.children[i].children.length>0){
                    let n = searchNode(node.children[i], id);
                    if (n){
                        return n;
                    }
                }
            }
        }
        return false;
    }

    onMounted(() => {
        if (!initialized){
            window.addEventListener("keyup", runListeners);
        }
    });
    onUnmounted(() => {
        if (initialized){
            window.removeEventListener("keyup", runListeners);
        }
    });

    return {
        keyboard: {
            addListener,
            removeListener,
            activate
        }
    }

}