<template>
    <div class="ag-floating-filter-input">
        <input 
            type="text" 
            v-model="currentValue" 
            @keyup="onInputBoxChanged" 
            class="ag-input-field-input ag-text-field-input"
            style="height:45%"
        />
    </div>
</template>
    
<script>


    //IMPORTS
    import { ref } from 'vue';    
    


    export default {
        name: 'GridSetFloatingFilter',
		setup(props, context){    
            //PROPS



            //CONSTANTS
            const currentValue=ref(null);

            

            //METHODS
            const onInputBoxChanged = () => {
                if (currentValue.value === null) {
                    // clear the filter
                    props.params.parentFilterInstance(instance => {
                        instance.onFloatingFilterChanged(null, null);
                    });
                    return;
                }
                props.params.parentFilterInstance(instance => {
                    instance.onFloatingFilterChanged('greaterThan', currentValue.value);    //Defined in parent filter
                });
            }
            
            const onParentModelChanged = (parentModel) => {
                // When the filter is empty we will receive a null value here
                if (!parentModel) {
                    currentValue.value = null;
                } else {
                    currentValue.value = parentModel.value;
                }
            }
            
            const afterGuiAttached = (params) => {
                //
            }

            return {
                currentValue,
                onInputBoxChanged,
                onParentModelChanged,
                afterGuiAttached
            }
        }
    }
</script>