//Imports
import Vue from "vue"
import axios from 'axios';
import VueAxios from 'vue-axios';
import { useCookies } from "vue3-cookies";
import _ from 'lodash';



//Constants
const axiosInstance = axios.create({
    withCredentials: true
})
const { cookies } = useCookies();

//Code
axiosInstance.defaults.headers.post['Accept'] = 'application/json'
axiosInstance.defaults.headers.post['Content-Type'] = 'application/json'
axiosInstance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axiosInstance.defaults.withCredentials=true;

axiosInstance.setupInterceptors=function(store, router){
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${store.user.authToken}`
    axiosInstance.interceptors.request.use(function (config) {
        //Before request
        return config;
    }, function (error) {
        //Before request error
        return Promise.reject(error);
    });
    
    // Add a response interceptor
    axiosInstance.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        if (_.has(response, 'data.message') && response.config.silent !== true){
            store.addNotificationMessage(response.data.message, 'error');
        }

        if (response.status===222){
            return Promise.reject(response);
        }

        return response;
    }, function (error) {
        if (error.response.status===503 && error.response.data.message=="Maintenance mode"){
            store.maintenance = true;
            router.push({ name: 'MaintenancePage', params: {} });
            return Promise.reject(error);
        }else if(store.maintenance==true) {
            store.maintenance = false;
        }

        if (error.message!=='canceled'){
            if (error.response && error.response.status===403){
                if (error.config.silent !== true){
                    store.addNotificationMessage( (_.has(error, 'response.data.message') ? error.response.data.message : 'You might not have access to this resource'), 'error');
                }

            }else if (error.response && (error.response.status===401 || error.response.status===419)){
                store.logout();
                router.push({ name: 'LoginPage', params: { next: (store.user.app === 'desktop' ? 'ReferenceList' : 'RsoApp') } });
                return Promise.reject(error);

            }else if (error.response && error.response.status===422){
                let message='';
                if (_.has(error, 'response.data.message') && !(_.has(error, 'response.data.errors'))){
                    message=error.response.data.message;
                }
                if (_.has(error, 'response.data.messages') && typeof error.response.data.messages==='object'){
                    for (let item in error.response.data.messages){
                        error.response.data.messages[item].forEach(msg => {
                            message = message + msg + '<br>';
                        });
                    }
                }
                if (typeof error.response.data.errors==='object'){
                    for (let item in error.response.data.errors){
                        error.response.data.errors[item].forEach(msg => {
                            message = message + msg + '<br>';
                        });
                    }
                }
                if (error.config.silent !== true){
                    store.addNotificationMessage(message, 'error');
                }
            }else{

                if (error.config.silent !== true){
                    store.addNotificationMessage('Request Error', 'error');
                }
            }
        }
        return Promise.reject(error);
    });

    // Add a request interceptor
    axiosInstance.interceptors.request.use(function (config) {
        config.headers['X-XSRF-TOKEN'] = store.user.csrfToken;
        config.headers["Authorization"] = "Bearer " + store.user.authToken;
        return config;  
    }, function (error) {
        // Do something with request error
        return Promise.reject(error);   
    });

};

export default axiosInstance