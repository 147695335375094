


//Imports
import Vue from "vue";
import axios from '@/plugins/axios';

//METHODS
function storageManager(){
    let self = this;
    let db=null;
    let table=null;
    let prefix=null;
    let dbRequest=null;
    let globalOnUpdate=null;
    let globalOnError=null;
    
    let indexedDb = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;
    
    //dbRequest = indexedDB.deleteDatabase('flow');
    dbRequest = indexedDb.open('flow', 1);
    dbRequest.addEventListener('error', () => { });
    dbRequest.addEventListener('success', (e) => {
        db = e.target.result;
    });
    dbRequest.addEventListener('upgradeneeded', (e) => {
        db = e.target.result;
        table = db.createObjectStore('status', {keyPath: 'name'});
        table.createIndex('id', 'name', { unique: true });

        //Create all tables which should normally be created dynamically when requested
        //REFERENCE
        let tables = ['reference', 'survey_size_type_id', 'transport_status_id', 'cargo_condition_id', 'booking_category_id', 'operator_id', 'depot_id', 'leasing_company_id', 'type', 'inspection_criteria', 'freight_kind'];
        let id=null;
        let prefix='reference';
        tables.forEach(table => {
            id='id';
            if (table==='survey_size_type_id'){
                id='code';
            }
            self.createTable(prefix + '__' + table, {id: id}, onSuccess);
        });
        function onSuccess(){
            
        }
    });    



    //METHODS
    //Check if indexeddb is enabled
    this.enabled = function() {
        return false;
        //return db===null ? false : true;
    }

    //Check if table exists
    this.tableExists = function (table) {
        return db.objectStoreNames.contains(table);
    }

    this.getStatusRecord = (key, onSuccess) => {
        let tranzaction = db.transaction(["status"], "readonly");
        tranzaction.onsuccess = function(){
            //db.close();
        }
        tranzaction.onerror = function(error){ }
        let table = tranzaction.objectStore("status");
        //let index = table.index("name");
        let request = table.get(key);
        request.onerror = function(){}
        request.onsuccess = function (evt) {
            if (typeof request.result==='undefined'){
                self.createEntryInStatus(key);
            }
            onSuccess(key, evt.target.result);
        }
    }
    
    this.createEntryInStatus = (key) => {
        let tranzaction = db.transaction(["status"], "readwrite");
        let table = tranzaction.objectStore("status");
        table.put({name: key, updated_at: null, deleted_at: null});
    }

    this.createTable = (key, mapItem, onSuccess) => {
        let itemId = (typeof mapItem.id!=='undefined' ? mapItem.id : 'id');
        /*console.log(' ');
        console.log('AICI: ');
        console.log(key);
        console.log(itemId);
        console.log(mapItem);*/
        let table = db.createObjectStore(key, {keyPath: itemId });
        let request = table.createIndex('id', itemId , { unique: true });
        request.onsuccess = function(){
            onSuccess(key);
        }
    }

    //MAIN CALL FROM OUTSIDE
    //Called from code with a model or a list of models to synchronize
    this.getData = function (params) {  //id, models, onError
        prefix=params.id;
        
        globalOnUpdate=params.onUpdate ? params.onUpdate : null;
        globalOnError=params.onError ? params.onError : null;
        
        params.models.forEach(item => {
            this.checkStatus(item);
        });
    }
    
    this.checkStatus = function(item){
        let models=[];
        
        //Rewrite with prefix
        for(let key in item.map){
            Object.defineProperty(item.map, prefix + '__' + key, Object.getOwnPropertyDescriptor(item.map, key));
            delete item.map[key];   
        }
        
        //Make sure all models have a record in the status table and their tables exist before getting and storing data
        for(let key in item.map){
            this.getStatusRecord(key, onSuccess);
        }

        function onSuccess(key, result){
            models[key] = result;
            if (Object.keys(models).length===Object.keys(item.map).length){
                self.loadStorageData(item);
            }
        }
    }

    //Not used for now here because we can't dynamically create objectStores
    this.checkTables = function(item){
        let checkedTables=0;
        //Make sure all models have a table in the db
        for(let key in item.map){
            if (!self.tableExists(key)){
                this.createTable(key, item.map[key], onSuccess);
            }else{
                onSuccess(key);
            }
            break;
        }

        function onSuccess(key){
            checkedTables++;
            if (checkedTables===Object.keys(item.map).length){
                self.loadStorageData(item);
            }
        }
    }
    
    this.loadStorageData = (item) => {
        let key = Object.keys(item.map)[0];
        this.getStatusRecord(key , onSuccess);
        
        function onSuccess(key, result){
            let processed=0;
            let target=null;
            for(let key in item.map){
                self.getDataFromTable(key, onSuccess);
            }
            
            function onSuccess(prefixedKey, result){
                processed++;
                if (typeof item.map[prefixedKey].target==='function'){
                    target = item.map[prefixedKey].target();
                }else{
                    target = item.map[prefixedKey].target;
                }
                
                let prepareRecord=item.map[prefixedKey]['prepareRecord'] ? item.map[prefixedKey]['prepareRecord'] : null;
                let storageData=[];
                result.forEach(row => {
                    if (prepareRecord!==null){
                        row = prepareRecord(row);
                    }
                    storageData.push(row);
                });
                target = [...storageData];

                //Start synchronizing after all data is loaded from indexeddb
                if (processed===Object.keys(item.map).length){
                    if (globalOnUpdate!==null){
                        globalOnUpdate();
                    }
                    self.keepSynchronized(item);
                }
            }
        }                
    }
    
    this.getDataFromTable = (key, onSuccess) => {
        let tranzaction = db.transaction([key], "readonly");
        tranzaction.onsuccess = function(){
            db.close();
        }
        tranzaction.onerror = function(error){ }
        let table = tranzaction.objectStore(key);
        let request = table.getAll();
        request.onerror = function(){}
        request.onsuccess = function (evt) {
            onSuccess(key, evt.target.result);
        }
    }
    
    this.keepSynchronized = (item) => {
        let key = Object.keys(item.map)[0];
        this.getStatusRecord(key , onSuccess);
        
        function onSuccess(key, result){
            let postData=result;
            delete postData.name;
            axios({method: 'post', url: item.url, data: postData}).then(function (response){
                self.updateRecordsInGrid(item, response);
                setTimeout(function(){
                    self.keepSynchronized(item);
                }, item.timer);
            }).catch(function (error) { /**/ });
        }
    }
    
    this.updateRecordsInGrid = function(item, response){
        let processed = false;
        let target = null;
        let prepareRecord = null;
        let key = null;
        let foundRow = null;
        let itemId = null;
        for(let prefixedKey in item.map){
            key=prefixedKey.split('__')[1];
            target=item.map[prefixedKey].target;
            prepareRecord=item.map[prefixedKey]['prepareRecord'] ? item.map[prefixedKey]['prepareRecord'] : null;
            itemId = item.map[prefixedKey].id ? item.map[prefixedKey].id : 'id';
            
            if (response.data[key].length>0){
                processed=true;
                if (typeof target==='function'){
                    target = target();
                }
                
                let newData=target;
                response.data[key].forEach(row => {
                    if (prepareRecord!==null){
                        row=prepareRecord(row, item.map[prefixedKey]);
                    }
                    foundRow = newData.find((o,i) => {
                        if (o.id == row.id){
                            if (row.deleted_at!==null && row.deleted_at!==''){
                                newData.splice(i,1);
                            }else{
                                newData[i] = row;
                            }
                            return true;
                        }
                    });
                    if (!foundRow){
                        if (!row.deleted_at || (row.deleted_at!==null && row.deleted_at!=='')){
                            newData.push(row);
                        }
                    }
                });
                target=newData;
                
                self.updateDatesAndData(prefixedKey, response);
            }
        }
        if (processed===true && globalOnUpdate!==null){
            globalOnUpdate();
        }
    }
    
    this.updateDatesAndData = function(prefixedKey, response){
        let tranzaction = db.transaction(["status", prefixedKey], "readwrite");
        tranzaction.onsuccess = function(){ }
        tranzaction.onerror = function(error){ }
        let key=prefixedKey.split('__')[1];
        let table = tranzaction.objectStore("status");
        let request=null;
        let item=null;
        request = table.get(prefixedKey);
        request.onerror = function(){}
        request.onsuccess = function (evt) {
            item = evt.target.result;
            if (response.data.last_updated_at!==null){ item.updated_at=response.data.last_updated_at; }
            if (response.data.last_updated_at!==null){ item.deleted_at=response.data.last_updated_at; }
            request = table.put(item);
            request.onerror = function(){ }
            request.onsuccess = function (evt) { }
        }
        
        let table_key = tranzaction.objectStore(prefixedKey);
        response.data[key].forEach(row => {
            table_key.put(row);
        });
        
    }

}

export default storageManager;