import { createRouter, createWebHistory, createWebHashHistory  } from "vue-router";
import ReferenceList from '@/components/ReferenceList';
import StockList from '@/components/StockList';
import LoginPage from '@/components/LoginPage';
import SettingsPage from '@/components/SettingsPage';
import AdminPage from '@/components/AdminPage';
import MaintenancePage from '@/components/MaintenancePage';
import NotFound from '@/components/NotFound';
import TestComponent from '@/components/TestComponent';

const routes = [
    { path: "/login:next", name: "LoginPage", component: LoginPage, props: true, alias: ['/:next'] },
    { path: "/referenceList", name: "ReferenceList", component: ReferenceList, props: true },
    { path: "/stockList", name: "StockList", component: StockList, props: true },
    { path: "/settingsPage", name: "SettingsPage", component: SettingsPage, props: true },
    { path: "/adminPage", name: "AdminPage", component: AdminPage, props: true },
    { path: "/maintenancePage", name: "MaintenancePage", component: MaintenancePage, props: true },
    { path: "/testComponent", name: "TestComponent", component: TestComponent, props: true },
    { path: '/:pathMatch(.*)', name: 'NotFound', component: NotFound },
];

const router = createRouter({
    history: createWebHashHistory (''),
    routes: routes
});

let firstRouteMatch = true;

router.beforeEach((to, from, next) => {
    if (firstRouteMatch) {
        firstRouteMatch = false;
        next({
            name: 'LoginPage',
            params: { next: to.name }
        });
    }else{
        next();
    }
});

export default router;