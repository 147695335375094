<template>
    <div class="ag-floating-filter-input">
        <v-btn-toggle block v-model="value" outlined density="compact" color="primary" class="d-flex flex-row">
            <v-btn 
                v-if="props.params.allOption" 
                value="" 
                class="flex-grow-1"
                size="large"
                selected
                style="text-transform: none !important;">
              All
            </v-btn>
            <v-btn v-for="(item) in props.params.colDef.cellEditorParams.values" size="large"
                   :key="item" 
                   :value="item" 
                   class="flex-grow-1"
                   style="text-transform: none !important;">
                {{ (typeof props.params.colDef.cellEditorParams === 'function') ? props.params.colDef.cellEditorParams(props.params).valueList[item] : props.params.colDef.cellEditorParams.valueList[item] }}
            </v-btn>
        </v-btn-toggle>    
    </div>
</template>

<script>
    
    //In colDef only set filter: CellDateFilter and it should work
    import { ref, watch } from 'vue';    
    


    export default {
        name: 'GridToggleFilter',
		setup(props, context){    
            //PROPS

            
    
            //CONSTANTS
            const value=ref();
            const operation=ref();
            
            
            
            //VARIABLES
            let combinedOptions=[];
            /* eslint-disable vue/no-setup-props-destructure */
            let cOptions=props.params.combinedOptions;
            if (cOptions){
                for (let item1 of cOptions){
                    for (let item2 of item1.values){
                        combinedOptions[item2] = item2.combinedValue;
                    }
                }
            }
            /* eslint-enable vue/no-setup-props-destructure */
            
            watch(value, (newValue) => {
                props.params.filterChangedCallback()
            })
            
            
            const doesFilterPass = (params) => {
                let field=props.params.colDef.field;
                let val=params.data[field];
                if (combinedOptions!=null){
                    val=combinedOptions[val];
                }
                return val==value.value || value.value=='all' || (val!=null && (val.toString().toLowerCase().indexOf(value.value.toLowerCase())) >= 0);
            }
            
            const isFilterActive = () => {
                return value.value != null && value.value !== '';
            }
            
            const getModel = () => {
            if (!isFilterActive()) {
                return null;
            }

            return { value: value.value };
            }
            
            const setModel = (model) => {
                value.value = model == null ? null : model.value;
            }
            
            /*Return something to show in the readonly area of the floating filter*/
            const getModelAsString = () => {
                return value.value !== null ? "" + value.value : "";
            }
            
            //Called by floating filter. It's defined in defualt components
            const onFloatingFilterChanged = (type, val) => {
                value.value=val;
                operation.value=type;
                props.params.filterChangedCallback();
            }

            return {
                value,
                operation,
                doesFilterPass,
                isFilterActive,
                getModel,
                setModel,
                getModelAsString,
                onFloatingFilterChanged
            }

        }
    }
    
</script>