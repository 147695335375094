<template>
    <v-btn v-if="store.getUnseenNotificationsTotal==0" icon="mdi-bell" size="small" variant="text" @click="showNotifications" v-tippy="{ content: 'Open notifications window' }"></v-btn>
    <v-btn v-if="store.getUnseenNotificationsTotal>0" icon="mdi-bell-ring" size="small" variant="text" @click="showNotifications" v-tippy="{ content: 'Open notifications window' }"></v-btn>
        
    <Teleport to="#app">
        <div class="notifications-on-screen" always-on-top="true" style="z-index: 3002">
            <div v-for="(item, index) in onScreenNotifications" :key="item.id" class="notification-on-screen" :class='{disappear:item.disappear}'>
                <div :class="['notification-border', item.type]"></div>
                <div class="notification-content">{{ item.notification }}</div>
                <div class="notification-close-all" v-if="index==0">
                    <v-btn icon="mdi-check-all" size="small" variant="text" @click="store.hideFromScreenAllNotifications"></v-btn>
                    <v-btn v-if="store.getUnseenNotificationsTotal==0" icon="mdi-bell" size="small" variant="text" @click="showNotifications"></v-btn>
                    <v-btn v-if="store.getUnseenNotificationsTotal>0" icon="mdi-bell-ring" size="small" variant="text" @click="showNotifications"></v-btn>
                </div>
            </div>
        </div>
    </Teleport>
    
    <DialogNeo dialog-title="Notifications" :dialog-footer=true :show="state.dialog" dialog-width="50%" dialog-height="80%" @hide="hideNotifications" :zIndex=3000 :alwaysOnTop=true>
        <template  #dialog-header-left>
            <v-btn 
                icon="mdi-delete" 
                size="small" variant="text"  
                :disabled="state.allowAction==false"
                @click="deleteNotifications"
                v-tippy="{ content: 'Delete all selected notifications' }"
                ></v-btn>
            <v-btn 
                icon="mdi-check" 
                size="small" 
                variant="text" 
                :disabled="state.allowAction==false"
                @click="seeNotifications"
                v-tippy="{ content: 'Mark all selected notifications as seen' }"
                ></v-btn>
            <v-btn 
                icon="mdi-check-all" 
                size="small" 
                variant="text" 
                :disabled="store.getAllNotificationsTotal==0"
                @click="store.seeAllNotifications"
                v-tippy="{ content: 'Mark all notifications as seen' }"
                ></v-btn>
            <v-btn 
                icon="mdi-select" 
                size="small" 
                variant="text" 
                :disabled="store.getAllNotificationsTotal==0"
                @click="selectAllNotifications"
                v-tippy="{ content: 'Select all notifications' }"
                ></v-btn>
        </template>
        <template  #dialog-header-right>
            <v-btn icon="mdi-close-thick" size="small" variant="text" @click="hideNotifications"></v-btn>
        </template>
        <template  #content>
            <div class="main-content-area">
                <div class="main-content-playground">
                    <!--Id is important for the few jquery selects that we are doing below in order not to affect another grid-->
                    <ag-grid-vue
                        id="notificationGrid"
                        style="width: 100%; height: 100%;"
                        class="ag-theme-alpine"
                        :rowSelection="'multiple'"
                        :columnDefs="columnDefs"
                        :defaultColDef="defaultColDef"
                        :components="components"
                        :rowData="data.rowData"
                        :pinnedTopRowData="data.pinnedTopRowData"
                        :gridOptions="gridOptions"
                        >
                    </ag-grid-vue>
                </div>
            </div>                
        </template>
        <template  #footer-left>

        </template>
        <template  #footer-center>

        </template>
        <template  #footer-right>
            <div style="display: flex;justify-content: right;align-items: center;"><strong>New/Total</strong>: {{ store.getUnseenNotificationsTotal }}/{{ store.getAllNotificationsTotal }}</div>
        </template>
    </DialogNeo>
</template>

<script setup>
    
    
    
    //IMPORTS
    import { ref, reactive, computed, onBeforeMount } from 'vue';
    import { useAppStore } from '@/stores/app';
    import { AgGridVue } from "ag-grid-vue3";



    //VARIABLES
    let gridApi=null;
    let allColumnIds = [];
    
    
    
    //CONSTANTS
    const store = useAppStore()
    const state=reactive({
        dialog: false,
        allowAction: false
    });
    const data = reactive({
        rowData: null,
        pinnedTopRowData: [],
        pinnedBottomRowData: []
    
    })
    const components = {
        AgGridVue
    };
    const columnDefs=ref([])
    const defaultColDef=reactive({
        //resizable: true,
        editable: true,
        sortable: true,
        filter: true,
        floatingFilter: false,
        suppressSizeToFit: true,
        filterParams: {
            closeOnApply: false,
            buttons: ['reset', 'apply'],
            debounceMs: 0
        },
    })
    const gridOptions = {
        pagination: false,
        rowSelection: 'multiple',
        suppressAutoSize: false,
        suppressColumnVirtualisation: true,
        skipHeaderOnAutoSize: false,
        overlayNoRowsTemplate: "<strong>No notifications</strong>",
        suppressClickEdit: true,
        stopEditingWhenCellsLoseFocus: false,
        getRowNodeId: data => {
            return data.id;
        },
        rowClassRules: {
            "notification-unseen": params => !params.data.seen,
            "notification-seen": params => params.data.seen
        },
        onGridReady: (params) => {
            gridApi = params.api;
           
            gridOptions.columnApi.getAllColumns().forEach(function(column) {
                allColumnIds.push(column.colId);
            });
            gridOptions.columnApi.autoSizeColumns(allColumnIds);
        },
        onRowClicked: (params) => {
            store.seeNotifications(params.data.id);
        },
        onSelectionChanged: (params) => {
            if (gridApi.getSelectedNodes().length>0){
                state.allowAction=true
            }else{
                state.allowAction=false
            }
        }
    }
    const setupGrid = () => {
        data.rowData = convertedNotifications;
    }
    
    
    
    //EVENTS
    onBeforeMount(() => {
        columnDefs.value = [
            {headerName: 'Id', field: 'id', 
                filter: 'agNumberColumnFilter', 
                minWidth: 70, 
                editable: false,
                hide: true
                //checkboxSelection: true
            },
            {headerName: '', field: '', 
                filter: false,
                width: 3, 
                editable: false,
                cellClass: params => {
                    if (params.data.type === 'error') {
                        return 'error';
                    }
                    if (params.data.type === 'success') {
                        return 'success';
                    }
                    return null;
                }                
            },
            {headerName: 'Notification', field: 'notification', 
                flex: 1,
                filter: 'agTextColumnFilter', 
                minWidth: 200,
                suppressSizeToFit: false,                
                autoHeight: true
                //cellClass: 'ag-compact-text'
            },
            {headerName: 'Date & Time', field: 'added',
                minWidth: 150,
                filter: 'agDateColumnFilter'
            }
        ]
        setupGrid();
    })  

    
    
    //COMPUTED
    const onScreenNotifications = computed(() => {
        let osn=[];
        store.notifications.map((item) => {
            if (item.onScreen){
                osn.push(item);
            }
        });
        return osn;
    })
    
    
    
    //METHODS
    const convertedNotifications = computed(() => {
        let nots=[];
        store.getAllNotifications.map((item,index) => {
            nots.push({id: item['id'], seen: item['seen'], notification: item['notification'], created: item['created'], viewed: item['viewed'], added: item['added'], type: item['type']});
        });
        return nots;
    });
    
    const showNotifications = () => {
       state.dialog = true
    }
    
    const hideNotifications = () => {
        state.dialog = false
    }
    
    const seeNotifications = () => {
        let rows=gridApi.getSelectedNodes();
        let selectedNotificationIds=[];
        rows.forEach(item => {
            selectedNotificationIds.push(item.data.id);
        });
        store.seeNotifications(selectedNotificationIds);
    }

    const deleteNotifications = () => {
        let rows=gridApi.getSelectedNodes();
        let selectedNotificationIds=[];
        rows.forEach(item => {
            selectedNotificationIds.push(item.data.id);
        });
        store.deleteNotifications(selectedNotificationIds);
    }
    const selectAllNotifications = () => {
        gridApi.selectAll();
    }
</script>
