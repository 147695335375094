<template>
    <v-toolbar :height="props.height" class="dynamicWidth" id="appMessageNotifications" @mouseover="mouseinHandler" @mouseout="mouseoutHandler" @click="clickHandler">
        <div class="notification-message-wrapper">
            <div class="notification-message" :style="{fontSize: props.font+'px'}"
                 v-if="currentMessage!=null" 
                 >
                <div class="notification-icon" :class="[currentMessage.type]">
                    <v-icon dense v-if="store.notificationMessages[0].type=='info'">mdi-information-outline</v-icon>
                    <v-icon dense v-if="store.notificationMessages[0].type=='success'">mdi-check-bold</v-icon>
                    <v-icon dense v-if="store.notificationMessages[0].type=='warning'">mdi-alert-outline</v-icon>
                    <v-icon dense v-if="store.notificationMessages[0].type=='error'">mdi-alert-circle-outline</v-icon>
                </div>
                <div class="notification-message-text" v-html="currentMessage.message"></div>
            </div>
        </div>
    </v-toolbar>
</template>

<script setup>
    //PROPS
    const props = defineProps({
        'height': {
            type: Number,
            required: false,
            default: 40
        },
        'font': {
            type: Number,
            required: false,
            default: 14
        }
    });

    
    
    //IMPORTS
    import { reactive, computed, onMounted, onBeforeMount, defineProps } from 'vue'
    import { useAppStore } from '@/stores/app'



    //CONSTANTS
    const store = useAppStore()
    const state=reactive({
        minimized: true
    });

    
    
    //EVENTS
    onBeforeMount(() => {})
    onMounted(()=>{})
    
    
    
    //COMPUTED
    const currentMessage = computed(() => {
        let msg=null;
        if (store.notificationMessages.length){
            msg={
                type: store.notificationMessages[0].type,
            };
            if (state.minimized){
                msg.message=store.notificationMessages[0].message.replaceAll('<br>','. ');
            }else{
                msg.message=store.notificationMessages[0].message;
            }
        }
        return msg;
    })
    
    
    
    //METHODS
    const mouseinHandler=()=>{
        state.minimized=false;
        store.pauseNotificationMessages();
    }
    const mouseoutHandler=()=>{
        state.minimized=true;
        store.resumeNotificationMessages();
    }
    const clickHandler=()=>{
        store.nextNotificationMessage(true);
    }
</script>
