import { useCommon } from '@/components/aggrid/common/useCommon';
import GridSelectEditorNeo from '@/components/aggrid/cell_editors/GridSelectEditorNeo';
import GridTextEditor from '@/components/aggrid/cell_editors/GridTextEditor';
import GridSetFloatingFilter from '@/components/aggrid/floating_filters/GridSetFloatingFilter';
import GridNumberEditor from '@/components/aggrid/cell_editors/GridNumberEditor';
import DatePicker from '@/components/aggrid/cell_editors/GridDateEditor';
import GridDateFilter from '@/components/aggrid/filters_new/GridDateFilter';
import { useUtilities } from  '@/composables/useUtilities';
import GridSelectWithIconFilter from '@/components/aggrid/filters_new/GridSelectWithIconFilter.vue';
import GridTextFloatingFilter from '@/components/aggrid/floating_filters_new/GridTextFloatingFilter.vue';
import GridSelectFloatingFilter from '@/components/aggrid/floating_filters_new/GridSelectFloatingFilter.vue';
import GridDateFloatingFilter from '@/components/aggrid/floating_filters_new/GridDateFloatingFilter'
import GridTextFilter from '@/components/aggrid/filters_new/GridTextFilter'
import _ from 'lodash';
import ReferenceListValidator from '@/validations/ReferenceListValidator';



export function useReferenceDefinition(store, dayJs){



    //COMPOSABLES
    const { utilities } = useUtilities(store);



    const getReferenceDefinition = () => {

        const { common } = useCommon();

        const quantityReturnedReleased = (params) => {
            if (params.data.quantity==='' || isNaN(params.data.quantity)){ return ''; }
            let total='';
            if (_.has(params, 'data.return_stock_containers__stock_container') || _.has(params, 'data.release_stock_containers__stock_container')){
                if (params.data.type==='return'){
                    total += params.data.return_stock_containers__stock_container.filter(item => {
                      return item.stock_container_status_id==2 ? true : false;
                    }).length;
                }else{
                    total += params.data.release_stock_containers__stock_container.filter(item => {
                        return item.stock_container_status_id==5 ? true : false;
                    }).length;
                }
            }else{
                total = '?';
            }
            return total + '/' + params.data.quantity;
        }

        const quantityTooltipComponentParams = (params) =>{
            return {
                type: 'grid',
                source: 'url',
                sourceData: function (data) {
                    return data.id ? "reference/"+data.id +"/stockContainer" : null;
                },
                extractData: function(response){
                    return response.data.models.stockContainers.filter(item => item.unit_number || (params.data.type==='return' && item.gate_in_date) || (params.data.type==='release' && item.gate_out_date) );
                },
                columns: [
                    {headerName: 'Unit Number', field: 'unit_number', minWidth: 100, initialSort: 'desc'},
                    {headerName: 'Gate-In Date', hide: params.data.type === 'release', field: 'gate_in_date', minWidth: 100, valueFormatter: function (params) {
                        return utilities.isDateTimeValid(params.value) ? utilities.formatDateTime(params.value) : '';
                    }},
                    {headerName: 'Gate-Out Date', hide: params.data.type === 'return', field: 'gate_out_date', minWidth: 100, valueFormatter: function (params) {
                        return utilities.isDateTimeValid(params.value) ? utilities.formatDateTime(params.value) : '';
                    }}
                ],
                title: 'Reference containers'
            }
        }

        return [
            ...common.columnDefs(),
            ...[
            {headerName: 'New', field: 'new',
                hide: true
            },
            {headerName: 'status', field: 'valid',
                hide: true
            },
            {headerName: 'containers', field: 'returnStock_containers__stock_container',
                hide: true
            },
            {headerName: 'containers', field: 'releaseStock_containers__stock_container',
                hide: true
            },

            {headerName: 'Id', field: 'id',
                hide: false,
                minWidth: 100,
                editable: false,
                //Filter
                filter: GridTextFilter,
                filterParams: {
                    pattern: '^\\d+$'
                    /*allowedCharPattern: '\\d\\-\\,',
                    numberParser: text => {
                        return text == null ? null : parseFloat(text.replace(',', '.'));
                    }*/
                },
                //Floating
                floatingFilterComponent: GridTextFloatingFilter,
                floatingFilterComponentParams: {}
            },

            {headerName: 'Type', field: 'type',
                editable: false,
                //Editor
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: {valueList: []},
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,

                //Filter
                filter: GridSelectWithIconFilter,
                filterParams: {
                    options: [
                        {
                            id: 'return',
                            name: 'Return',
                            icon: 'mdi-truck-delivery',
                            color: 'success'
                        },
                        {
                            id: 'release',
                            name: 'Release',
                            icon: 'mdi-truck-delivery',
                            color: 'error',
                            style: {transform: 'scaleX(-1);'}
                        }
                    ]
                },
                //Floating
                floatingFilterComponent: GridSelectFloatingFilter,
                floatingFilterComponentParams: {}
            },

            {headerName: 'Reference', field: 'reference',
                //Editor
                cellEditor: GridTextEditor,
                //headerComponentParams: { menuIcon: 'fa-external-link-alt' },
                //Filter
                //Floating
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                },                
                //Custom
                custom: {
                    //required: true
                }
            },

            {headerName: 'Freight Kind', field: 'freight_kind',
                //headerClass: 'each-word-on-its-own-line',
                //Editor
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: {valueList: []},
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSelectWithIconFilter,
                filterParams: {
                    options: [
                        {
                            id: 'empty',
                            name: 'Empty',
                            icon: 'mdi-moon-new',
                            color: 'primary'
                        },
                        {
                            id: 'loaded',
                            name: 'Loaded',
                            icon: 'mdi-moon-full',
                            color: 'primary'
                        }
                    ]
                },
                //Floating
                floatingFilterComponent: GridSelectFloatingFilter,
                floatingFilterComponentParams: {},
            },

            {headerName: 'Size Type', field: 'survey_size_type_id',
                //Editor
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: {
                    valueList: [],
                    default: ReferenceListValidator.isRequired('survey_size_type_id') ? 'first' : false,
                    actions: [
                        {
                            icon: 'pencil',
                            actionCall: function () {
                                //Open settings editor
                            }
                        }
                    ]
                },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSelectWithIconFilter,
                filterParams: function(params){
                    let options=[];
                    let item;
                    for (let i in params.colDef.cellEditorParams.valueList){
                        item = params.colDef.cellEditorParams.valueList[i];
                        options.push({
                            id: item.id,
                            name: item.name                            
                        });
                    }
                    return {
                        options: options
                    }
                },
                //Floating
                floatingFilterComponent: GridSelectFloatingFilter,
                floatingFilterComponentParams: {}
            },

            {headerName: 'Assigned Quantity', field: 'quantity',
                maxWidth: 100,
                //Editor
                cellEditor: GridNumberEditor,
                cellEditorParams: {
                    integerLength: 3,
                    decimalLength: 0
                },
                cellRenderer: quantityReturnedReleased,
                //Filter
                filter: GridTextFilter,
                filterParams: {
                    pattern: '^\\d+$'
                },
                //Floating
                floatingFilterComponent: GridTextFloatingFilter,
                floatingFilterComponentParams: {},
                //Tooltip
                tooltipComponentParams: quantityTooltipComponentParams,
                tooltipValueGetter: (params) => {
                    return params.value;
                },
                //onCellClicked: ,
                /*editable: (params) => {
                 if (params && params.node.data.id==''){
                 return true;
                 }else{
                 return false;
                 }
                 }*/
                 cellClass: function(params){
                    if (params.data.type=='return'){
                        return params.data.return_stock_containers__stock_container.filter(item => item.unit_number || item.gate_in_date).length>0 ? 'has-tooltip' : '';
                    }else{
                        return params.data.release_stock_containers__stock_container.filter(item => item.unit_number || item.gate_out_date).length>0 ? 'has-tooltip' : '';
                    }
                }
            },

            {headerName: 'Facility/Depot', field: 'depot_id',
                //Editor
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: {
                    valueList: [],
                    default: ReferenceListValidator.isRequired('depot_id') ? 'first' : false
                },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSelectWithIconFilter,
                filterParams: function(params){
                    let options=[];
                    let item;
                    for (let i in params.colDef.cellEditorParams.valueList){
                        item = params.colDef.cellEditorParams.valueList[i];
                        options.push({
                            id: item.id,
                            name: item.name                            
                        });
                    }
                    return {
                        options: options
                    }
                },
                //Floating
                floatingFilterComponent: GridSelectFloatingFilter,
                floatingFilterComponentParams: {}
            },

            {headerName: 'Shipping Line/Operator', field: 'operator_id',
                //Editor
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: {
                    valueList: [],
                    default: ReferenceListValidator.isRequired('operator_id') ? 'first' : false
                },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSelectWithIconFilter,
                filterParams: function(params){
                    let options=[];
                    let item;
                    for (let i in params.colDef.cellEditorParams.valueList){
                        item = params.colDef.cellEditorParams.valueList[i];
                        options.push({
                            id: item.id,
                            name: item.name                            
                        });
                    }
                    return {
                        options: options
                    }
                },
                //Floating
                floatingFilterComponent: GridSelectFloatingFilter,
                floatingFilterComponentParams: {}
            },

            {headerName: 'Leasing Company', field: 'leasing_company_id',
                //Editor
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: {
                    valueList: [],
                    default: ReferenceListValidator.isRequired('leasing_company_id') ? 'first' : false
                },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSelectWithIconFilter,
                filterParams: function(params){
                    let options=[];
                    let item;
                    for (let i in params.colDef.cellEditorParams.valueList){
                        item = params.colDef.cellEditorParams.valueList[i];
                        options.push({
                            id: item.id,
                            name: item.name                            
                        });
                    }
                    return {
                        options: options
                    }
                },
                //Floating
                floatingFilterComponent: GridSelectFloatingFilter,
                floatingFilterComponentParams: {},
            },

            {headerName: 'Inspection Criteria', field: 'inspection_criteria',
                //Editor
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: {
                    valueList: [],
                    default: ReferenceListValidator.isRequired('inspection_criteria') ? 'first' : false
                },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSelectWithIconFilter,
                filterParams: function(params){
                    let options=[];
                    let item;
                    for (let i in params.colDef.cellEditorParams.valueList){
                        item = params.colDef.cellEditorParams.valueList[i];
                        options.push({
                            id: item.id,
                            name: item.name                            
                        });
                    }
                    return {
                        options: options
                    }
                },
                //Floating
                floatingFilterComponent: GridSelectFloatingFilter,
                floatingFilterComponentParams: {}
            },

            {headerName: 'Transport Status', field: 'transport_status_id',
                //Editor
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: {
                    valueList: [],
                    default: ReferenceListValidator.isRequired('transport_status_id') ? 'first' : false
                },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSelectWithIconFilter,
                filterParams: function(params){
                    let options=[];
                    let item;
                    for (let i in params.colDef.cellEditorParams.valueList){
                        item = params.colDef.cellEditorParams.valueList[i];
                        options.push({
                            id: item.id,
                            name: item.name                            
                        });
                    }
                    return {
                        options: options
                    }
                },
                //Floating
                floatingFilter: true,
                floatingFilterComponent: GridSelectFloatingFilter,
                floatingFilterComponentParams: {},
                //floatingCellFormatter: common.lookupName,
                //Custom
                custom: {
                    //required: true
                }
            },

            {headerName: 'Cargo Condition', field: 'cargo_condition_id',
                //minWidth: 170,
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: {
                    valueList: [],
                    default: ReferenceListValidator.isRequired('cargo_condition_id') ? 'first' : false
                },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSelectWithIconFilter,
                filterParams: function(params){
                    let options=[];
                    let item;
                    for (let i in params.colDef.cellEditorParams.valueList){
                        item = params.colDef.cellEditorParams.valueList[i];
                        options.push({
                            id: item.id,
                            name: item.name                            
                        });
                    }
                    return {
                        options: options
                    }
                },
                //Floating
                floatingFilter: true,
                floatingFilterComponent: GridSelectFloatingFilter,
                floatingFilterComponentParams: {}
            },

            {headerName: 'Container Older', field: 'container_older',
				hide: true,
                //Editor
                cellEditor: GridNumberEditor,
                cellEditorParams: {valueList: []},
                //filter: 'agNumberColumnFilter',
                filterParams: {
                    allowedCharPattern: '\\d\\-\\,',
                    numberParser: text => {
                        return text == null ? null : parseFloat(text.replace(',', '.'));
                    }
                },
            },

            {headerName: 'Container Younger', field: 'container_younger',
				hide: true,
                //Editor
                cellEditor: GridNumberEditor,
                cellEditorParams: {valueList: []},
                //filter: 'agNumberColumnFilter',
                filterParams: {
                    allowedCharPattern: '\\d\\-\\,',
                    numberParser: text => {
                        return text == null ? null : parseFloat(text.replace(',', '.'));
                    }
                },
            },

            {headerName: 'Booking Category', field: 'booking_category_id',
                editable: (params) => params.data.type == 'release',
                //minWidth: 170,
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: {
                    valueList: [],
                    default: ReferenceListValidator.isRequired('booking_category_id') ? 'first' : false
                },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSelectWithIconFilter,
                filterParams: function(params){
                    let options=[];
                    let item;
                    for (let i in params.colDef.cellEditorParams.valueList){
                        item = params.colDef.cellEditorParams.valueList[i];
                        options.push({
                            id: item.id,
                            name: item.name                            
                        });
                    }
                    return {
                        options: options
                    }
                },
                //Floating
                floatingFilter: true,
                floatingFilterComponent: GridSelectFloatingFilter,
                floatingFilterComponentParams: {}
            },

            {headerName: 'Creation Date', field: 'created',
                //minWidth: 140,
                //Edit
                hide: true,
                editable: false,
                comparator: utilities.dateComparator,
                valueFormatter: function (params) {
                    return params.value === '' ? '' : utilities.formatDateTimeSecond(params.value);
                },
                //Filter
                filter: GridDateFilter,
                filterParams: { },
                //Floating
                /*floatingFilterComponent: GridDateFloatingFilter,
                 floatingFilterComponentParams: {},*/
            },

            {headerName: 'Validity', field: 'validity',
                minWidth: 150,
                cellEditor: DatePicker,
                cellEditorParams: function(params){
                    return {
                        'format': 'date',
                        'buttons': true,
                        config: {
                            //minDate: params.data.id=='' ? dayJs().add(1, 'day').toDate() : null
                            minDate: dayJs().add(1, 'day').tz(store.user.config.timezone).toDate()
                        }
                    };
                },
                comparator: utilities.dateComparator,
                valueFormatter: function (params) {
                    return params.value ? utilities.formatDate(params.value) : "";
                },
                //Filter
                filter: GridDateFilter,
                 filterParams: {},
                //Floating
                //floatingFilterComponent: GridDateFloatingFilter,
                floatingFilterComponentParams: {},
                //Custom
                custom: {
                    //required: true
                }
            },

            {headerName: 'Generic Reference Comment', field: 'comment',
                cellEditor: GridTextEditor,
                //Floating
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },

            {headerName: 'Last Modified', field: 'updated_at',
                editable: false,
                sort: 'desc',
                comparator: utilities.dateComparator,
                valueFormatter: function (params) {
                    return utilities.isDateTimeValid(params.value) ? utilities.formatDateTime(params.value) : '';
                },
                tooltipComponentParams: {
                    type: 'text',
                    source: 'attribute',
                    sourceData: '',
                    columns: [
                        {headerName: 'Creation date:', field: 'created_at', 
                            valueFormatter: function (params) {
                                return utilities.isDateTimeValid(params.value) ? utilities.formatDateTime(params.value) : '';
                            }
                        }
                    ]
                },
                tooltipValueGetter: (params) => {
                    return params.value;
                },
                //Filter
                filter: GridDateFilter,
                 filterParams: {},
                //Floating
                //floatingFilterComponent: GridDateFloatingFilter,
                floatingFilterComponentParams: {},
                //Custom
                custom: {
                    //required: true
                },
                cellClass: function(params){
                    return params.value ? 'has-tooltip' : '';
                }
            },

            {headerName: 'User', field: 'created_by',
                flex: 1,
                minWidth: 150,
                resizable: false,
                suppressSizeToFit: true,
                editable: false,
                cellEditor: GridTextEditor,
                valueFormatter: function (params) {
                    return params.value === 'coparn' ? 'COPARN' : params.value;
                },
                //Floating
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },

            {headerName: 'Created From', field: 'created_from',
                editable: false,
                //Editor
                cellEditorParams: {valueList: []},
                valueFormatter: function (params) {
                    return params.value === 'coparn' ? 'COPARN' : 'User';
                },
                //Floating
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },

                    /*{headerName: '', field: 'actions', pinned: 'right', filter: false, width: 40, cellStyle: { 'padding-left': 0, 'padding-right': 0 }, editable: false, resizable: false,
                     cellRenderer: '',
                     cellRendererParams: {
                     clicked: (field)=>{ }
                     }
                     }*/
            ]
        ];

    }

    return {
        getReferenceDefinition
    };

}