<template>
    <div class="main-content-area">
        <div class="main-content-playground" style="display: flex;justify-content: center; align-items: center; 
            background-size: cover; background-position: center;"
            :style="{ backgroundImage: backgroundImage }"
            >
            <v-container style="width:30%;background-color:rgba(255,255,255,0.9);min-width:300px;">
                <v-row>
                    <v-col>&nbsp;
                    </v-col>
                </v-row>                
                <v-row>
                    <v-col align='center'>
                        <v-img
                            src="logo-dark.png"
                            width="30px"
                        ></v-img>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="text-center" style="font-size: 14px;">
                        We are sorry but there is no page with this address.
                    </v-col>
                </v-row>
                
                <v-row>
                    <v-col class="info" style="font-weight: bold;">{{ message }}
                    </v-col>
                </v-row>
            </v-container>            
            
        </div>
    </div>
</template>

<script setup>

</script>
