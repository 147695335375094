<template>
    <div class="ag-floating-filter-input">
        <v-btn :icon="valueToIcon" size="x-small" color='primary' style='height:32px;' v-click="clickHandler" v-on:focusout="focusoutHandler" v-click.double="doubleClickHandler"></v-btn>
    </div>

</template>
    
<script>
    
    
    
    //IMPORTS
    //In colDef only set filter: CellDateFilter and it should work
    import { ref, computed, onBeforeMount } from 'vue';    
    
    
    
    export default {
        name: 'GridToggleFloatingFilterYesNo',
		setup(props, context){    
            //PROPS
            let initialValue=null;
            if (props.params.filterParams.combinedOptions){
                /* eslint-disable vue/no-setup-props-destructure */
                //Setting initialValue here might not be ok initialValue=props.params.filterParams.combinedOptions.combinedValue;
                /* eslint-enanble vue/no-setup-props-destructure */
            }
            
            const currentValue=ref('all');
            const valueToIcon = computed(() => {
                let icon='';
                let checkValue=currentValue.value;
                
                if (!checkValue || checkValue=='all'){
                    icon='mdi-check-all';
                }else{
                    if (combinedOptions!=null){
                        checkValue=combinedOptions[checkValue].value;
                    }
                    icon=combinedOptions[checkValue].icon;
                }
                return icon;
            });
            
            
            
            //VARIABLES
            let combinedOptions=[];
            let doubleClickTimerId=null;
            

            //WATCHES
            
            
            
            //EVENTS
            onBeforeMount(() => {
                let cOptions=props.params.filterParams.combinedOptions;
                if (cOptions){
                    for (let item1 of cOptions){
                        combinedOptions[item1.combinedValue] = {
                            value: item1.combinedValue,
                            icon: item1.icon
                        }
                        for (let item2 of item1.values){
                            combinedOptions[item2] = {
                                value: item1.combinedValue,
                                icon: item1.icon
                            }
                        }
                    }
                }
            });
            
            
            
            //METHODS
            const clickHandler = () => {
                if (doubleClickTimerId!=null){
                    clearTimeout(doubleClickTimerId);
                    doubleClickTimerId=null;
                }
                doubleClickTimerId=setTimeout(function(){
                    toggleValues();
                },200);
            }
            
            const onInputBoxChanged = () => {
                if (currentValue.value === '') {
                    // clear the filter
                    props.params.parentFilterInstance(instance => {
                        instance.onFloatingFilterChanged(null, null);
                    });
                    return;
                }
                
                //onFloatingFilterChanged must be defined in parent filter
                props.params.parentFilterInstance(instance => {
                    instance.onFloatingFilterChanged('greaterThan', currentValue.value);
                });
            }
            
            const onParentModelChanged = (parentModel) => {
                // When the filter is empty we will receive a null value here
                if (parentModel==null) {
                    currentValue.value = '';
                } else {
                    currentValue.value = parentModel.value;
                }
            }
            
            const afterGuiAttached = (params) => {
                //
            }

            const toggleValues = () => {
                if (currentValue.value=='all'){
                    currentValue.value='yes';
                }else if(currentValue.value=='yes'){
                    currentValue.value='no';
                }else{
                    currentValue.value='all';
                }
                onInputBoxChanged();
            }
            
            const doubleClickHandler = () => {
                if (doubleClickTimerId!=null){
                    clearTimeout(doubleClickTimerId);
                    doubleClickTimerId=null;
                }
                props.params.parentFilterInstance(instance => {
                    props.params.showParentFilter();
                });
            }

            return {
                currentValue,
                valueToIcon,
                onInputBoxChanged,
                onParentModelChanged,
                afterGuiAttached,
                clickHandler,
                doubleClickHandler
            }

        }
    }
</script>