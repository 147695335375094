<template>
    <Teleport to="#app" :disabled="props.connect!='root'">
        <div ref="root"  :class=" [ 'keypad-input', props.type ]" v-show="props.show" :style="{ zIndex: 4000, width: props.width }" v-resize="resizeHandler">
            <div v-if="props.backdrop==true" class="keypad-backdrop">
            </div>

            <div v-if="props.type!='simple'" class="keypad-side-list">
                <div  class="keypad-list">
                    <div v-for="(item, index) in allowedFilteredList" :key="index" class="keypad-list-item" :style="{ height: (itemHeight+'px'), fontSize: (itemWidth*0.5+'px') }" @click="selectItemFromList(item)">
                        {{  item }}
                    </div>
                </div>
            </div>

            <div ref="inputs" class="keypad-side-main" :style=" [ props.key_layout=='keypad' ? 'align-items: center' : '' ] ">
                <div v-if="input_selection==true" class="keypad-input-selection">
                    <div v-for="(item, index) in inputCharacters" :key="index" class="keypad-item" :style="{ width: (itemWidth+'px'), height: (itemHeight+'px') }">
                            <div class="keypad-item-text" :style="{ fontSize: (itemWidth*0.7+'px') }" v-text=" (finalValue && index>=inputPosition) ? '_' : ( item=='' ? '_' : item ) ">
                            </div>
                        </div>
                </div>

                <div :class=" [ props.key_layout=='normal' ? 'keypad-input-content' : 'keypad-input-content-keypad' ] " :style="{ width: (containerWidth), height: (containerHeight) }">
                    <div class="keypad-input-container">
                        <div v-for="(item) in allCharacters" :key="item" :class=" ['keypad-item', item.enabled ? '' : 'disabled'] " :style="{ width: (itemWidth+'px'), height: (itemHeight+'px') }" @click="keyPressedHandler(item.char, item.enabled)">
                            <div class="keypad-item-text" :style="{ fontSize: (itemWidth*0.7+'px') }">
                                {{ item.char }}
                            </div>
                        </div>

                        <!--<div v-if="allCharacters.length%2==1" :key="none" class="keypad-item" :style="{ width: (itemWidth+'px'), height: (itemHeight+'px') }">
                        </div>-->

                        <div :class=" [ 'keypad-item', inputPosition==0 ? 'delete-disabled' : 'delete' ]" :style="{ width: (itemWidth+'px'), height: (itemHeight+'px') }"  @click="deletePressedHandler">
                            <div class="keypad-item-text" :style="{ fontSize: (itemWidth*0.7+'px') }">
                                <div class="mdi-keyboard-backspace mdi"></div>
                            </div>
                        </div>
                        <div v-if="props.done_button" :class=" ['keypad-item', allowDone ? 'enter' : 'delete'] " :style="{ width: (itemWidth+'px'), height: (itemHeight+'px') }"  @click="enterPressedHandler">
                            <div class="keypad-item-text" :style="{ fontSize: (itemWidth*0.7+'px') }">
                                <div :class="['mdi', allowDone ? 'mdi-check' : 'mdi-window-close']"></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            
        </div>
    </Teleport>
</template>

<script setup>
    
    
    
    //IMPORTS
    import { FLOWBASEANNOTATION_TYPES, numberLiteralTypeAnnotation } from '@babel/types';
    import { ref, reactive, defineProps, defineEmits, computed, onMounted, onUnmounted, onBeforeMount, watch, nextTick } from 'vue';
            
    
    
    //PROPS
    const props = defineProps({
        'show': {
            type: Boolean,
            required: false,
            default: true
        },
        'backdrop': {
            type: Boolean,
            required: false,
            default: true
        },
        'allowed': {
            type: Array,
            required: true
        },
        'all': {
            type: [String, Array],
            required: false,
            default: function(){ return [" ", "!", "#", "$", "%", "&", "'", "(", ")", "*", "+", ",", "-", ".", "/", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ":", ";", "<", "=", ">", "?", "@", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "[", "]", "^", "_", "`", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", "{", "|", "}", "~"]; }
        },
        'type': {
            type: String,
            required: false,
            default: 'simple'   //simple, list-left, list-right
        },
        'characters': {
            type: Number,
            required: false,
            default: 999
        },
        'characters_from': {
            type: String,
            required: false,
            default: 'start'
        },
        key_layout: {
            type: String,
            required: false,
            default: 'normal'   //normal, keypad
        },
        input_selection: {
            type: Boolean,
            required: false,
            default: false
        },
        width: {
            type: String,
            required: false,
            default: 'auto'
        },
        connect: {
            type: String,
            required: false,
            default: 'root'     //root, parent
        },
        delete_button: {
            type: Boolean,
            required: false,
            default: true
        },
        done_button: {
            type: Boolean,
            required: false,
            default: true
        },
        done_only_on_final: {
            type: Boolean,
            required: false,
            default: false
        },
        autocomplete: {
            type: Boolean,
            required: false,
            default: false
        },
        reset_on_open: {
            type: Boolean,
            required: false,
            default: false
        }

    });
    
    
    
    //EMITS
    const emits = defineEmits(['update', 'done','cancel']);

    

    //CONSTANTS
    const allCharacters = ref([]);
    const itemWidth=ref(0);
    const itemHeight=ref(0);
    const containerWidth=ref('auto');
    const containerHeight=ref('auto');
    const inputCharacters = ref([]);
    const inputPosition = ref(0);
    const finalValue = ref(false);
    const characters = ref(props.characters);
    const charactersFrom = ref(props.characters_from);
    


    //VARIABLES
    let tree=null;
    let treePointer = null;



    //REFERENCES
    const root = ref(null); 
    const inputs = ref(null);
    


    //WATCHERS
    watch(() => props.show, (newShow) => {
        if (newShow==true){
            nextTick(() => {
                if (props.reset_on_open){
                    for (let i=0;i<inputCharacters.value.length;i++){
                        inputCharacters.value[i]='';
                    }
                    inputPosition.value = 0;
                    treePointer = tree;
                }
                resizeHandler();
                updateEnableDisableButtons();
            })
        }
    });
    watch(() => props.allowed, (newAllowed) => {
        refreshAllowedList();
        updateEnableDisableButtons();
    });



    //COMPUTED
    const allowDone = computed(() => {
        return (!props.done_only_on_final || (props.done_only_on_final && finalValue.value) );
    })

    const allowedFilteredList = computed(() => {
        let len = inputPosition.value;
        let str = inputCharacters.value.join('').slice(0, len);

        if (props.characters_from=='start'){
            return props.allowed.filter(function(item){
                return item.slice(0, len)==str;
            });
        }else if (props.characters_from=='end'){
            return props.allowed.filter(function(item){
                if (len<props.characters){
                    return item.slice(-props.characters, len-props.characters)==str;
                }else{
                    return item.slice(-len)==str;
                }
            });
        }else{
            return props.allowed.filter(function(item){
                return item.includes(str);
            });    
        }
    })



    //EVENTS
    onBeforeMount(() => {
        refreshAllowedList();
    })

    onMounted(() => {
        resizeHandler();
        updateEnableDisableButtons();
    })
        

    
    //METHODS
    const refreshAllowedList = () => {
        let allowed = props.allowed;

        //CALCULATE THE INPUT LENGTH
        let newInputCharacters = [];
        let inpLen = 0;
        if (props.characters==999){
            for (let i=0;i<allowed.length;i++){
                if (allowed[i].length>inpLen) {
                    inpLen = allowed[i].length;
                }
            }
        }else{
            inpLen = props.characters;
        }
        for (let i=0;i<inpLen;i++){
            newInputCharacters.push('');
        }
        inputCharacters.value = newInputCharacters;


        //LIMIT LENGTH TO X CHARACTERS
        if (props.characters!=999){
            if (props.characters_from=='start'){
                for (let i=0;i<allowed.length;i++){
                    allowed[i] = allowed[i].slice(0, props.characters);
                }
            }
            if (props.characters_from=='end'){
                for (let i=0;i<allowed.length;i++){
                    allowed[i] = allowed[i].slice(-props.characters);
                }
            }
        }

        //CREATE IN ADVANCE THE LIST OF ALL CHARACTERS FROM THE ACTUAL LIST
        let inAdvanceAllCharacters = [];

        //CREATE TREE OF POSIBILITIES
        let newTree = {
            value: null,
            parent: null,
            children: {}
        };
        let char='';
        
        for (let i=0;i<allowed.length;i++){
            let treePointer = newTree;
            let max = allowed[i].length;
            for (let j=0;j<max;j++){
                char = allowed[i][j];
                inAdvanceAllCharacters[char] = true;
                if (char in treePointer.children){
                    treePointer = treePointer.children[char];
                }else{
                    treePointer.children[char] = {
                        value: char,
                        parent: treePointer,
                        children: {},
                        final: j==max-1
                    }
                    treePointer = treePointer.children[char];
                }
            }
        }
        tree = newTree;
        treePointer = tree;
        
        //Build possible character list
        let newAllCharacters=[];
        if (props.all==='content'){
            let listOfChars = [];
            for (const [key] of Object.entries(inAdvanceAllCharacters)) {
                listOfChars.push(key);
            }
            listOfChars = listOfChars.sort();
            for (let i=0;i<listOfChars.length;i++){
                newAllCharacters.push({
                    char: listOfChars[i],
                    enabled: false
                });
            }
        }else{
            for (let i=0;i<props.all.length;i++){
                newAllCharacters.push({
                    char: props.all[i],
                    enabled: false
                });
            }
        }
        allCharacters.value = newAllCharacters;
    }

    const resizeHandler = () => {
        if (props.key_layout=='keypad'){
            let totalWidth = root.value.clientWidth;
            let totalHeight = root.value.clientHeight;
            let squareSize = totalWidth > totalHeight ? totalHeight : totalWidth;
            containerWidth.value = squareSize+'px';
            containerHeight.value = squareSize+'px';
        }

        nextTick(() => {
                let itemSize = getItemSize();
                itemWidth.value = itemSize;
                itemHeight.value = itemSize;
        });
    }

    const getItemSize = () => {
        let width = inputs.value.clientWidth;
        let height = inputs.value.clientHeight;
        if (width>0 && height>0){
            if (props.key_layout=='keypad'){
                if (width>height){
                    width = height;
                }else{
                    height=width;
                }
            }
            
            let n = allCharacters.value.length+2;   //2 is for the delete button and the enter button
            let ratio = width / height;
            let b = Math.sqrt(ratio * n);
            let a = n / b;
            let bigItems = width/b;
            let smallItems = height/a;
            let itemSize = Math.floor(smallItems<bigItems ? smallItems : bigItems);

            if (props.input_selection==true){
                itemSize = itemSize*a / (a+1);
            }
            itemSize = itemSize*0.9;

            /*console.log('--------------------------------');
            console.log('Width: '+width);
            console.log('Height: '+height);
            console.log('Ratio: '+ratio);
            console.log('a: '+a);
            console.log('b: '+b);
            console.log('n: '+n);
            console.log('Big items: '+bigItems);
            console.log('Small items: '+smallItems);
            console.log('Item size: '+itemSize);*/

            return itemSize;
        }        
    }

    const keyPressedHandler = (key, enabled) => {
        if (!enabled){ return }
        /*console.log(' ');
        console.log('TreePointer');
        console.log(treePointer);
        console.log('Key: '+key);*/
        do{
            if (Object.keys(treePointer.children).length>0){
                treePointer = treePointer.children[key];
                //console.log('New tree pointer');
                //console.log(treePointer);
                inputCharacters.value[inputPosition.value] = key;
                inputPosition.value++;
                key = Object.keys(treePointer.children)[0];
            }
        } while (Object.keys(treePointer.children).length==1 && props.autocomplete && !treePointer.final);
        updateEnableDisableButtons();
    }

    const deletePressedHandler = () => {
        if (treePointer.parent!=null){
            inputPosition.value--;
            treePointer = treePointer.parent;
            inputCharacters.value[inputPosition.value] = '_';
        }
        //console.log(treePointer.final);
        while (treePointer.parent!=null && treePointer.final==false){
            inputPosition.value--;
            treePointer = treePointer.parent;
            inputCharacters.value[inputPosition.value] = '_';
        }
        updateEnableDisableButtons();
    }
    
    const enterPressedHandler = () => {
        if (allowDone.value){
            emits('done');
        }else{
            for (let i=0;i<inputCharacters.value.length;i++){
                inputCharacters.value[i]='';
            }
            inputPosition.value = 0;
            treePointer = tree;
            emits('cancel');
        }
    }

    const updateEnableDisableButtons = () => {
        for (let i=0;i<allCharacters.value.length;i++){
            allCharacters.value[i].enabled = allCharacters.value[i].char in treePointer.children;
        }
        finalValue.value = treePointer.final;

        let val='';
        for (let i=0; i<inputPosition.value;i++){
            val = val + inputCharacters.value[i];
        }
        emits('update', {
            value: val,
            final: finalValue.value
        });
    }

    const selectItemFromList = (item) => {
        inputPosition.value = 0;
        for (let i=0;i<inputCharacters.value.length;i++){
            inputCharacters.value[i] = [];
        }
        treePointer = tree;
        for (let i=0;i<item.length;i++){
            treePointer = treePointer.children[item[i]];
            inputCharacters.value[inputPosition.value] = item[i];
            inputPosition.value++;
        }
        updateEnableDisableButtons();
        emits('done');
    }

</script>