<template>
    <div ref="root" class="ag-floating-filter-input" @keydown="keydownHandler">

        <v-list ref="listRef" style="padding:0;">
            <v-list-item 
                v-for="(item) in computedOptions"
                :key="item.id"
                :value="item"
                active-color="primary"
                :class="(exact && item.id==value) ? 'v-list-item-selected' : ''"
                @click="selectOption(item.id)"
                >
                <template v-slot:prepend v-if="item.icon">
                    <v-icon :icon="item.icon" :color="item.color" :style="item.style"></v-icon>
                </template>

                <v-list-item-title v-text="item.name" :style="{ marginLeft: ((item.icon && item.name ? '10' : '0') + 'px') }">
                </v-list-item-title>

            </v-list-item>
        </v-list>

    </div>
</template>

<script>
    
    import { ref, watch, computed } from 'vue';    
    


    export default {
        name: 'GridSelectWithIconFilter',
		setup(props, context){
            //PROPS



            //REFERENCES
            const root = ref(null);
            const listRef = ref(null);
            
    
            //CONSTANTS
            const value=ref();
            const exact = ref(false);
            

            
            //VARIABLES
            let lastIndex = -1;



            //WATCHERS
            watch(value, (newValue) => {
                props.params.filterChangedCallback()
            })
            
            

            //COMPUTED
            const computedOptions = computed(() => {
                let tmpItems=props.params.options;
                tmpItems.sort((a,b) => b.id==null ? 1 : ( a.id==null ? -1 : ((a.name>b.name) ? 1 : ((b.name > a.name) ? -1 : 0))) )
                return tmpItems;
            });



            //AGGRID METHODS
            const afterGuiAttached = (params) => {
                //For some reason the focus does not work onb the root element directly. Only by focusing on the first element in the select list.
                let el = root.value.children[0].children;
                if (el.length>0){
                    el[0].focus();
                }
            }

            const doesFilterPass = (params) => {
                let field=props.params.colDef.field;
                let val=params.data[field];
                if (!exact.value && props.params.colDef.valueFormatter){
                    val = props.params.colDef.valueFormatter({
                        colDef: props.params.column.colDef,
                        value: val
                    });
                }
                return value.value=='all' || (
                    val!=null && ( (exact.value && val==value.value) || (!exact.value && val.toString().toLowerCase().indexOf(String(value.value).toLowerCase()) >= 0) )
                );
            }
            
            const isFilterActive = () => {
                return value.value != null && value.value !== '';
            }
            
            const getModel = () => {
                if (!isFilterActive()) {
                    return null;
                }
                return { value: value.value };
            }
            
            const setModel = (model) => {
                value.value = model == null ? null : model.value;
            }
            
            /*Return something to show in the readonly area of the floating filter*/
            const getModelAsString = () => {
                if (!value.value){
                    return '';
                }
                if (props.params.colDef.valueFormatter){
                    let obj = {
                        colDef: props.params.column.colDef,
                        value: value.value
                    }
                    return props.params.colDef.valueFormatter(obj);
                }else{
                    return value.value;
                }
            }
            
            //Called by floating filter. It's defined in defualt components
            const onFloatingFilterChanged = (val) => {
                exact.value = false;
                value.value=val;
            }



            //METHODS
            const selectOption = (id) => {
                if (exact.value && value.value===id){
                    value.value = '';
                }else{
                    value.value = id;
                }
                exact.value = true;
            }

            const keydownHandler = (event) => {
                let firstIndex = -1;
                if (event.keyCode >= 32 && event.keyCode <= 126) {
                    let index=computedOptions.value.findIndex((item, i) => { 
                        if (firstIndex == -1 && item.name.length>0 && item.name[0].toUpperCase()==event.key.toUpperCase()){
                            firstIndex = i;
                        }
                        return item.name[0].toUpperCase()==event.key.toUpperCase() && i > lastIndex;
                    });
                    if (index < 0){
                        index = firstIndex;
                    }
                    if (index>=0){
                        listRef.value.$el.children[index].scrollIntoView({block: "start", inline: "nearest"});
                    }
                    lastIndex = index;
                }
            }

            return {
                exact,
                props,
                value,
                root,
                listRef,
                lastIndex, 
                doesFilterPass,
                isFilterActive,
                getModel,
                setModel,
                getModelAsString,
                onFloatingFilterChanged,
                selectOption,
                computedOptions,
                keydownHandler,
                afterGuiAttached
            }

        }
    }
    
</script>