<template>
    <div style="padding: 10px">
        <input 
            type="text" 
            v-model="value" 
            v-on:input="updateFilter()" 
            class="ag-input-field-input ag-text-field-input"
        />
    </div>
</template>

<script>
    
    //v-on:input="onInputBoxChanged()"
    
    //In colDef only set filter: CellDateFilter and it should work
    import { ref } from 'vue';    
    


    export default {
        name: 'GridSetFilter',
		setup(props, context){    
            //PROPS


            
            //CONSTANTS
            const value=ref('')
            const operation=ref()
            
            const updateFilter = () => {
                props.params.filterChangedCallback()
            }
            
            const doesFilterPass = (params) => {
                let field=props.params.colDef.field;
                let val = (typeof props.params.colDef.cellEditorParams === 'function') ? props.params.colDef.cellEditorParams(props.params).valueList : props.params.colDef.cellEditorParams.valueList;
                val = val.find(o => o.id === params.data[field]);
                return (val && ((val.name.toLowerCase().indexOf(value.value.toLowerCase())) >= 0));
            }
            
            const isFilterActive = () => {
            return value.value != null && value.value !== '';
            }
            
            const getModel = () => {
            if (!isFilterActive()) {
                return null;
            }
            return { value: value.value };
            }
            
            const setModel = (model) => {
                value.value = (model==null ? null : model.value);
            }
            
            /*Return something to show in the readonly area of the floating filter*/
            const getModelAsString = () => {
                return value.value !== null ? "" + value.value : "";
            }
            
            //Called by floating filter. It's defined in defualt components
            const onFloatingFilterChanged = (type, val) => {
                value.value=val;
                operation.value=type;
                props.params.filterChangedCallback();
            }

            return {
                value,
                operation,
                updateFilter,
                doesFilterPass,
                isFilterActive,
                getModel,
                setModel,
                getModelAsString,
                onFloatingFilterChanged
            }

        }
    }
    
</script>