<template>
    <div class="ag-floating-filter-input" ref="root">

        <v-container class="">
            <v-row>
                <v-col style="width:100%;display:flex;">
                    <v-btn-toggle v-model="operation" color="primary" mandatory density="compact" style="height:26px;flex:3;">
                        <v-btn icon="mdi-less-than" value="<" style="flex:1;"></v-btn>
                        <v-btn icon="mdi-greater-than" value=">" style="flex:1;"></v-btn>
                        <v-btn icon="mdi-equal" value="=" style="flex:1;"></v-btn>
                        <v-btn icon="mdi-code-tags" value="<>" style="flex:1;"></v-btn>
                        <v-btn icon="mdi-unfold-less-vertical" value="><" style="flex:1;"></v-btn>
                        <v-btn icon="mdi-alpha-a-circle-outline" value="A" style="flex:1;"></v-btn>
                        <v-btn icon="mdi-null" value="" style="flex:1;"></v-btn>
                    </v-btn-toggle>
                </v-col>
            </v-row>

            <v-row>
                <v-col style="width:135px" ref="inputParent1">
                    <input 
                        ref="input1" 
                        v-model="value1" 
                        class="ag-input-field-input ag-text-field-input" 
                        :disabled=" operation == '' "
                        @input = "onInput1Handler"
                    />
                </v-col>
                <v-col style="width:135px" ref="inputParent2">
                    <input 
                        ref="input2" 
                        v-model="value2" 
                        class="ag-input-field-input ag-text-field-input" 
                        :disabled=" operation != '<>' && operation != '><' "
                    />
                </v-col>
            </v-row>

            <v-row>
                <v-col class="justify-end" style="display: flex;">
                    <v-btn color="primary" icon="mdi-delete-outline" density="compact" value="x" @click="clearValues"></v-btn>
                </v-col>
            </v-row>

        </v-container>

    </div>
</template>

<script>
    
    import { ref, watch, inject, onMounted, onUnmounted, nextTick } from 'vue';    
    import { useAppStore } from '@/stores/app';
    import { useUtilities } from  '@/composables/useUtilities';



    export default {
        name: 'GridTextFilter',
        components: { },        
		setup(props, context){
            //PROPS


            
            //COMPOSABLES
            const { utilities } = useUtilities();



            //CONSTANTS
            const value1 = ref(null);
            const lastValue1 = ref(null);
            const value2 = ref(null);
            const lastValue2 = ref(null);
            const operation = ref('<>');
            const value=ref();
            let gridField=ref(null);
            const store = useAppStore();
            const root=ref(null);
            const input1 = ref(null);
            const input2 = ref(null);
            const inputParent1 = ref(null);
            const inputParent2 = ref(null);
            const regex = ref(new RegExp(props.params.pattern));
            

            
            //VARIABLES
            let updateFromFloatingFilter = false;
            let selectionStart = 0;
            let selectionEnd = 0;

            

            //WATCHERS
            watch( [() => value1.value, () => value2.value, () => operation.value], ([ newValue1, newValue2, newOperation ]) => {
                props.params.filterChangedCallback()
            });
            
            watch(value1, (newValue, oldValue) => {
                if (props.pattern!=''){
                    if (updateFromFloatingFilter){
                        updateFromFloatingFilter = false;
                    }else{
                        if (newValue=='' || newValue == null){
                            lastValue1.value = newValue;
                        }else{
                            let partialMatchRegex = regex.value.toPartialMatchRegex();
                            let result = partialMatchRegex.exec(newValue);
                            let match = regex.value.exec(newValue) ? "F" : result && result[0] ? "P" : "N";  //F - full match, P - Partial match, N - No match
                            if (match==='F' || match==='P'){
                                lastValue1.value = newValue;
                            }else{
                                if (newValue!=lastValue1.value){
                                    nextTick(()=>{
                                        let result = partialMatchRegex.exec(lastValue1.value);
                                        let match = regex.value.exec(lastValue1.value) ? "F" : result && result[0] ? "P" : "N";
                                        saveMetaDataInput1();
                                        if (match!='F' && match!='P'){
                                            value1.value = null;
                                        }else{
                                            value1.value = lastValue1.value;
                                        }
                                        nextTick(() => {
                                            applyMetaDataInput1();
                                        });
                                    });
                                }
                            }
                        }
                    }
                }
            })

            watch(value2, (newValue, oldValue) => {
                if (props.pattern!=''){
                    if (newValue=='' || newValue == null){
                        lastValue2.value = newValue;
                    }else{
                        let partialMatchRegex = regex.value.toPartialMatchRegex();
                        let result = partialMatchRegex.exec(newValue);
                        let match = regex.value.exec(newValue) ? "F" : result && result[0] ? "P" : "N";  //F - full match, P - Partial match, N - No match
                        if (match==='F' || match==='P'){
                            lastValue2.value = newValue;
                        }else{
                            if (newValue!=lastValue2.value){
                                saveMetaDataInput2();
                                nextTick(()=>{
                                    value2.value = lastValue2.value;
                                    nextTick(() => {
                                            applyMetaDataInput2();
                                    });
                                });
                            }
                        }
                    }
                }
            })
            
            const doesFilterPass = (params) => {
                let field=props.params.colDef.field;
                let cellVal=params.data[field];
                switch (operation.value) {
                    case '=':
                        return value1.value ? value1.value == cellVal : false;
                    case '<':
                        return cellVal <= value1.value;
                    case '>':
                        return cellVal >= value1.value;
                    case '<>':
                        if (value1.value && value2.value){
                            return cellVal >= value1.value && cellVal <= value2.value;
                        }else if (value1.value){
                            return cellVal >= value1.value;
                        }else{
                            return cellVal <= value2.value;
                        }
                    case '><':
                        if (value1.value && value2.value){
                            return cellVal <= value1.value && cellVal >= value2.value;
                        }else if (value1.value){
                            return cellVal <= value1.value;
                        }else{
                            return cellVal >= value2.value;
                        }
                    case 'A':
                        return String(cellVal).includes(String(value1.value));
                    case '':
                        return !cellVal;
                    default:
                        return false;
                }
            }
            
            const isFilterActive = () => {
                return value1.value != null || value2.value != null;
            }
            
            const getModel = () => {
                if (!isFilterActive()) {
                    return null;
                }
                return getModelAsString();
            }
            
            const setModel = (model) => {
                if (model == null){
                    value1.value = null;
                    value2.value = null;
                }
                //value.value = model == null ? null : model.value;*/
            }
            
            /*Return something to show in the floating filter*/
            const getModelAsString = () => {
                switch (operation.value){
                    case '<':
                        return '<= ' + value1.value;
                    case '>':
                        return '>= ' + value1.value;
                    case '=':
                        return value1.value || value2.value ? ( '= ' + (value1.value ? value1.value : value2.value) ) : '';
                    case '<>':
                        if (value1.value && value2.value){
                            return value1.value + ' ... ' + value2.value;
                        }else if (value1.value){
                            return '>= ' + value1.value;
                        }else{
                            return '<= ' +value2.value;
                        }
                    case '><':
                        if (value1.value && value2.value){
                            return '... ' + value1.value + ' , ' + value2.value + ' ...';
                        }else if (value1.value){
                            return '<= ' + value1.value;
                        }else{
                            return '>= ' +value2.value;
                        }
                    case 'A':
                        return value1.value;
                    case '':
                        return 'No Value';
                }
            }
            
            //Called by floating filter. It's defined in defualt components
            const onFloatingFilterChanged = (val) => {
                updateFromFloatingFilter = true;
                operation.value = 'A';
                value1.value = val;
                value2.value = null;
                props.params.filterChangedCallback();
            }



            //EVENTS



            //METHODS
            const clearValues = () => {
                value1.value = null;
                value2.value = null;
            }
            const saveMetaDataInput1 = () => {
                selectionStart = input1.value.selectionStart;
                selectionEnd = input1.value.selectionEnd;
            }
            const applyMetaDataInput1 = () => {
                input1.value.setSelectionRange(selectionStart-1, selectionEnd-1);
            }
            const saveMetaDataInput2 = (input) => {
                selectionStart = input2.value.selectionStart;
                selectionEnd = input2.value.selectionEnd;
            }
            const applyMetaDataInput2 = (input) => {
                input2.value.setSelectionRange(selectionStart-1, selectionEnd-1);
            }
            const onInput1Handler = () => {
                updateFromFloatingFilter = false;
            }


            //CODE
            RegExp.prototype.toPartialMatchRegex = utilities.toPartialMatchRegex;

            

            return {
                root,
                props,
                value,
                value1,
                value2,
                operation,
                input1,
                input2,
                inputParent1,
                inputParent2,
                doesFilterPass,
                isFilterActive,
                getModel,
                setModel,
                getModelAsString,
                onFloatingFilterChanged,
                clearValues,
                saveMetaDataInput1,
                saveMetaDataInput2,
                applyMetaDataInput1,
                applyMetaDataInput2,
                onInput1Handler
            }

        }
    }
    
</script>