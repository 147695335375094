<template>
  <div class="container">
    <div class="row">
      <div class="column" v-if="params.value.color">
        <i class="mdi mdi-palette" :style="colorIconStyle"></i>
        <span class="small-text">{{ params.value.color }}</span>
      </div>
      <div class="column" v-if="params.value.floor">
        <i class="mdi mdi-floor-plan icon-color"></i>
        <span class="small-text">{{ params.value.floor }}</span>
      </div>
    </div>
    <div class="row">
      <div class="column-vertical" v-if="params.value.temperature">
        <i class="mdi mdi-thermometer icon-color"></i>
        <b>{{ params.value.temperature }}</b>
      </div>
      <div class="column-vertical" v-if="params.value.humidity">
        <i class="mdi mdi-water-percent icon-color"></i>
        <b>{{ params.value.humidity }}</b>
      </div>
      <div class="column-vertical" v-if="params.value.ventilation">
        <i class="mdi mdi-fan icon-color"></i>
        <b>{{ params.value.ventilation }}</b>
      </div>
    </div>
  </div>
</template>

<style>
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.column {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 8px;
}

.column-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 8px;
}

.small-text {
  font-size: 0.8rem; /* Adjust the font size as needed */
}

.icon-color {
  color: #4E6F8E;
  font-size: 1.3em;
}
</style>

<script>
export default {
  props: ['params'],
  computed: {
    colorTextStyle() {
      return {
        color: this.params.value.color,
      };
    },
    colorIconStyle() {
      return {
        color: this.params.value.color,
      };
    },
  },
};
</script>