<template>
    <v-text-field
        v-model="value"
        :ref="'input'" 
        label=""
        variant="contained" 
        class="positionAdjust"
        @input = "saveMetaData"
        @update = "applyMetaData"
    ></v-text-field>
</template>

<script>
    import {ref, onMounted, watch, nextTick } from 'vue';



    export default {
        name: 'GridNumberEditor',
		setup(props, context){    
            const value=ref(null);
            const lastValue = ref(null);

            const cancelBeforeStart=ref(true);
            const highlightAllOnFocus=ref(true);
            const numberType = props.params.type ? props.params.type : 'integer';
            const integerLength = props.params.integerLength ? props.params.integerLength : '*';
            const decimalLength = props.params.decimalLength || props.params.decimalLength>0 ? props.params.decimalLength : 'no';
            const reg = "^[-+]$|^([-+])?\\d"+ (integerLength!='*' ?  "{1,"+integerLength+"}" : "*") + (decimalLength!="no" ? "(\\.\\d{0,"+decimalLength+"})?" : "") +"$";

            const input = ref(null)

            let selectionStart = 0;
            let selectionEnd = 0;

            const getValue = () => {
                if (value.value=='-' || value.value=='+'){
                    return 0;
                }
                if (value.value[0]=='+'){
                    return value.value.substring(1);
                }
                return value.value;
            }

            const isCancelBeforeStart = () => {
                return cancelBeforeStart.value;
            }

            const setInitialState = (params) => {
                let startValue;
                highlightAllOnFocus.value = true;

                if (params.key === 'Backspace' || params.key === 'Delete') {
                    // if backspace or delete pressed, we clear the cell
                    startValue = '';
                } else if (params.charPress) {
                    // if a letter was pressed, we start with the letter
                    startValue = params.charPress;
                    highlightAllOnFocus.value = false;
                } else {
                    // otherwise we start with the current value
                    startValue = params.value;
                    if (params.key === 'F2') {
                        highlightAllOnFocus.value = false;
                    }
                }

                value.value = startValue;
            }

            watch(value, (newValue, oldValue) => {
                if (newValue !== null){
                    newValue = String(newValue);
                    //eslint-disable-next-line
                    let ok = newValue == '' || (newValue.match(reg) !== null);
                    if (ok){
                        let newVal = newValue;
                        if (newVal.includes(',')){
                            newVal = newVal.replace(',', '.');
                            nextTick(()=>{
                                value.value = newVal;
                            });
                        }
                        lastValue.value = newVal;
                    }else{
                        nextTick(()=>{
                                value.value = oldValue;
                        });
                    }
                }
            })

            const saveMetaData = () => {
                selectionStart = input.value.selectionStart;
                selectionEnd = input.value.selectionEnd;
            }

            const applyMetaData = () => {
                input.value.setSelectionRange(selectionStart, selectionEnd);
            }

            const isCancelAfterEnd = () => {
                return false;
            }

            const onKeyDown = (event) => {
                const char = event.key;
                let charOk = false;

                if (isLeftOrRight(event) || deleteOrBackspace(event)) {
                    event.stopPropagation();
                    return;
                }
                charOk = /\d/.test(char) || char == '.' || char == ',';
                if (!finishedEditingPressed(event) && !charOk) {
                    if (event.preventDefault) event.preventDefault();
                }
            }

            const keyUpHandler = (event) => {
                const char = event.key;
                if (value.value!=''){
                    if (numberType === 'integer'){
                        value.value=isNaN(parseInt(value.value)) ? '' : parseInt(value.value) ;
                    }
                    if (numberType === 'float'){
                        if (char == '.' || char == ','){
                            value.value = parseFloat((value.value+'').replace(',', '.')+'0');
                        }else{
                            value.value = parseFloat((value.value+'').replace(',', '.'));
                        }
                    }
                }
            }

            const finishedEditingPressed = (event) => {
                const key = event.key;
                return key === 'Enter' || key === 'Tab';
            }

            const deleteOrBackspace = (event) => {
                return ['Delete', 'Backspace'].indexOf(event.key) > -1;
            }

            const isLeftOrRight = (event) => {
                return ['ArrowLeft', 'ArrowRight'].indexOf(event.key) > -1;
            }
            
            //This method is called when pressing the TAB key when editing
            const activateCellEditor = () => {
                input.value.focus();
            }
            
            onMounted(() => {
                setInitialState(props.params);
                cancelBeforeStart.value = props.params.charPress && '1234567890'.indexOf(props.params.charPress) < 0;
            });

            return{
                value,
                cancelBeforeStart,
                highlightAllOnFocus,
                input,
                getValue,
                isCancelBeforeStart,
                setInitialState,
                isCancelAfterEnd,
                onKeyDown,
                keyUpHandler,
                finishedEditingPressed,
                deleteOrBackspace,
                isLeftOrRight,
                activateCellEditor,
                saveMetaData,
                applyMetaData
            }

        }
    }
</script>

<style>
    .positionAdjust .v-field__input{
        position: absolute;
        top: 0;
        padding: 0;
        display: flex;
    }
    .positionAdjust .v-field__input input{
        margin-left: 10px;
        margin-right: 10px;            
        flex: 1;
    }
</style>