//IMPORTS
import { onMounted, onUnmounted } from "vue"
import dayJs from 'dayjs';



export function useUtilities(store=null) {
    
    /*Help functions*/    
    const isDateTimeValid = (datetime) => {
        return (dayJs(datetime).isValid() && typeof datetime!=='undefined');
    }
    const formatDateTimeGeneral = (value, format, timezone = store.user.config.timezone) => {
        return dayJs(value).isValid() ? dayJs.utc(value).tz(timezone).format(format) : 'Invalid Date'
    }
    const formatDate = (value, timezone) => {
        return formatDateTimeGeneral(value, store.user.config.dateFormat, timezone);
    }
    const formatTime = (value, timezone) => {
        return formatDateTimeGeneral(value, store.user.config.timeFormat, timezone);
    }
    const formatDateTime = (value, timezone) => {
        return formatDateTimeGeneral(value, store.user.config.dateTimeFormat, timezone);
    }
    const formatDateTimeSecond = (value, timezone) => {
        return formatDateTimeGeneral(value, store.user.config.dateTimeSecondFormat, timezone);
    }
    const formatDateTimeSecondMillisecond = (value, timezone) => {
        return formatDateTimeGeneral(value, store.user.config.dateTimeSecondMillisecondFormat, timezone);
    }
    const parseDate = (datestr, timezone = store.user.config.timezone) => {
        return dayJs.utc(datestr).tz(timezone);
    }

    const formatGridDate = (params) => {
        if (params.value===null){
            return '';
        }
        let dateAsString = params.value;
        if (dateAsString==='' || dateAsString===undefined) return '';
        if (dateAsString.length>19){
            dateAsString=dateAsString.substring(0,19).replace('T',' ');
        }
        let parts = dateAsString.split(' ');
        let dateParts=parts[0].split('-');
        let ret=`${dateParts[0]}-${dateParts[1]}-${dateParts[2]}`;
        if (params.colDef.valueFormatterParams && params.colDef.valueFormatterParams.format!='date'){
            let timeParts=parts[1].split(':');
            ret+=' '+`${timeParts[0]}:${timeParts[1]}`+(params.colDef.valueFormatterParams && params.colDef.valueFormatterParams.seconds?':'+timeParts[2]:'');
        }
        return ret;
    }    
    
    const dateToNumber = (date)  =>{
        if (date === undefined || date === null/* || date.length !== 10*/) {
            return null;
        }
        let yearNumber = date.substring(0, 4);
        let monthNumber = date.substring(5, 7);
        let dayNumber = date.substring(8, 11);
        let result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
        // 29/08/2004 => 20040829
        return result;
    }
    
    const fromUtcToLocalTimezone = (datestr) => {
        let v = dayJs(datestr);
        if (v.isValid()){
            v = dayJs.tz(datestr, 'UTC').tz(store.user.config.timezone);
        }
        return v;
    }

    const fromLocalToUtcTimezone = (date) => {
        let v = dayJs(date);
        if (v.isValid()){
            v = dayJs.tz(date, store.user.config.timezone).tz('UTC');
        }
        return v;
    }

    const capitalizeFirstLetter = (string) =>{
        return String(string).charAt(0).toUpperCase() + String(string).slice(1);
    }

    const generateUnitNumberCheckNumber = (containerId) => {
        const charMap = [
            10, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 34, 35, 36, 37, 38
        ];
        const weights = [1, 2, 4, 8, 16, 32, 64, 128, 256, 512];
        const digits = [];
    
        const getNumber = char => {
            return charMap[parseInt(char, 36) - 10];
        };
    
        for (let i = 0; i <= 3; i++) {
            digits.push(getNumber(containerId[i]));
        }
    
        for (let i = 4; i <= 9; i++) {
            digits.push(parseInt(containerId[i]));
        }
    
        let total = 0;
        for (let i = 0; i < 10; i++) {
            total += digits[i] * weights[i];
        }
    
        return (total % 11) % 10;
    }

    const toPartialMatchRegex = function() {
        "use strict";

        let re = this,
            source = this.source,
            i = 0;

        function process () {
            let result = "",
                tmp;

            function appendRaw(nbChars) {
                result += source.substr(i, nbChars);
                i += nbChars;
            }

            function appendOptional(nbChars) {
                result += "(?:" + source.substr(i, nbChars) + "|$)";
                i += nbChars;
            }

            while (i < source.length) {
                switch (source[i])
                {
                    case "\\":
                        switch (source[i + 1])
                        {
                            case "c":
                                appendOptional(3);
                                break;

                            case "x":
                                appendOptional(4);
                                break;

                            case "u":
                                if (re.unicode) {
                                    if (source[i + 2] === "{") {
                                        appendOptional(source.indexOf("}", i) - i + 1);
                                    } else {
                                        appendOptional(6);
                                    }
                                } else {
                                    appendOptional(2);
                                }
                                break;

                            case "p":
                            case "P":
                                if (re.unicode) {
                                    appendOptional(source.indexOf("}", i) - i + 1);
                                } else {
                                    appendOptional(2);
                                }
                                break;

                            case "k":
                                appendOptional(source.indexOf(">", i) - i + 1);
                                break;

                            default:
                                appendOptional(2);
                                break;
                        }
                        break;

                    case "[":
                        tmp = /\[(?:\\.|.)*?\]/g;
                        tmp.lastIndex = i;
                        tmp = tmp.exec(source);
                        appendOptional(tmp[0].length);
                        break;

                    case "|":
                    case "^":
                    case "$":
                    case "*":
                    case "+":
                    case "?":
                        appendRaw(1);
                        break;

                    case "{":
                        tmp = /\{\d+,?\d*\}/g;
                        tmp.lastIndex = i;
                        tmp = tmp.exec(source);
                        if (tmp) {
                            appendRaw(tmp[0].length);
                        } else {
                            appendOptional(1);
                        }
                        break;

                    case "(":
                        if (source[i + 1] == "?") {
                            switch (source[i + 2])
                            {
                                case ":":
                                    result += "(?:";
                                    i += 3;
                                    result += process() + "|$)";
                                    break;

                                case "=":
                                    result += "(?=";
                                    i += 3;
                                    result += process() + ")";
                                    break;

                                case "!":
                                    tmp = i;
                                    i += 3;
                                    process();
                                    result += source.substr(tmp, i - tmp);
                                    break;

                                case "<":
                                    switch (source[i + 3])
                                    {
                                        case "=":
                                        case "!":
                                            tmp = i;
                                            i += 4;
                                            process();
                                            result += source.substr(tmp, i - tmp);
                                            break;

                                        default:
                                            appendRaw(source.indexOf(">", i) - i + 1);
                                            result += process() + "|$)";
                                            break;        
                                    }
                                    break;
                            }
                        } else {
                            appendRaw(1);
                            result += process() + "|$)";
                        }
                        break;

                    case ")":
                        ++i;
                        return result;

                    default:
                        appendOptional(1);
                        break;
                }
            }

            return result;
        }

        return new RegExp(process(), this.flags);
    };



    /*Comparators*/
    const dateComparator = (date1, date2) => {
        return dayJs(date1).diff(dayJs(date2));
    }



    //EVENTS
    onMounted(() => {
        //
    });

    onUnmounted(() => {
        //
    });



    return {
        utilities: {
            isDateTimeValid,
            formatDate,
            formatTime,
            formatDateTime,
            formatDateTimeSecond,
            formatDateTimeSecondMillisecond,
            parseDate,
            dateToNumber,
            capitalizeFirstLetter,
            formatGridDate,
            dateComparator,
            generateUnitNumberCheckNumber,
            toPartialMatchRegex,
            fromUtcToLocalTimezone,
            fromLocalToUtcTimezone
        }
    }

}