<template>
    <div>No Records</div>
</template>

<script setup>
    import { defineProps } from 'vue';

    //Props
    const props = defineProps({
        click: {
            type: Function,
            required: false,
            default: ()=>{}
        }
    })    
</script>