import useValidate from '@vuelidate/core';
import { helpers, required, maxLength } from '@vuelidate/validators';
import constants from '@/constants/constants';
import Validator from '@/validations/Validator';
import { inspection_criterias, types, freights } from '@/constants/constants';



class ReferenceListValidator extends Validator {

    constructor() {
        super();

        
        
        //CUSTOM VALIDATIONS
        this.isType = function(value){
            value.includes('return', 'release');
        };



        //VALIDATIONS
        this.validations = {
            type: {
                required: helpers.withMessage('Reference <strong>Type</strong> must be Return or Release', required),
                isType: helpers.withMessage('Reference <strong>Type</strong> must be Return or Release', this.isType),
            },
            reference: { 
                required: helpers.withMessage('<strong>Reference</strong> must be set', required)
            },
            freight_kind: {
                required: helpers.withMessage('<strong>Freight kind</strong> must be set', required)
            },
            survey_size_type_id: {
                required: helpers.withMessage('<strong>Survey size type</strong> must be set', required)
            },
            depot_id: {
                required: helpers.withMessage('<strong>Depot</strong> must be set', required)
            },
            leasing_company_id: {
                required: helpers.withMessage('<strong>Leasing Company Id</strong> must be set', required)
            },
            transport_status_id: {
                required: helpers.withMessage('<strong>Transport status</strong> must be set', required)
            },
            operator_id: {
                required: helpers.withMessage('<strong>Shipping Line/Operator</strong> must be set', required)
            }
        };
        
        
        
        this.$v = useValidate(this.validations, this.validationData);        
    }

}

export default new ReferenceListValidator();