import { useCommon } from '@/components/aggrid/common/useCommon';
import GridSelectEditorNeo from '@/components/aggrid/cell_editors/GridSelectEditorNeo';
import GridTextEditor from '@/components/aggrid/cell_editors/GridTextEditor';
import GridSetFilter from '@/components/aggrid/filters/GridSetFilter';
import GridSetFloatingFilter from '@/components/aggrid/floating_filters/GridSetFloatingFilter';
import DatePicker from '@/components/aggrid/cell_editors/GridDateEditor';
import { useUtilities } from '@/composables/useUtilities';
import { computed } from 'vue';
import YamaxValidator from '@/validations/YamaxValidator';



export function useYamaxStockListDefinition(store, dayJs) {



    //COMPOSABLES
    const { utilities } = useUtilities(store);



    const getYamaxStockListDefinition = () => {

        const { common } = useCommon();

        const toolTipValueGetter = (params) => {
            return params.value
        };

        return [
            ...common.columnDefs(),
            ...[
            {
                headerName: 'Status', field: 'valid',
                hide: true
            },
            {
                headerName: 'Id', field: 'id', hide: true,
                editable: false,
                filterParams: {
                    allowedCharPattern: '\\d\\-\\,',
                    numberParser: text => {
                        return text == null ? null : parseFloat(text.replace(',', '.'));
                    }
                }
            },
            {
                headerName: 'SC Flow ID', field: 'id', hide: true,
                cellEditor: 'agTextCellEditor',

            },
            {
                headerName: 'Unit Number', field: 'unit_number',
                cellEditor: GridTextEditor,
                cellEditorParams: {
                    pattern: "^[a-z|A-Z]{4}[0-9]{7}$",
                    emptyStringIsNull: true,
                    onUpdate: (oldValue, newValue) => {
                        if (newValue.length == oldValue.length - 1 && newValue == oldValue.substring(0, oldValue.length - 1)) {
                            //It's a character delete so don't do anything
                            return newValue;
                        } else {
                            if (newValue && newValue.length == 10) {
                                return newValue + utilities.generateUnitNumberCheckNumber(newValue);
                            } else if (newValue && newValue.length == 11) {
                                //Next two lines need to work when we have a way of notifying the user that the check number might be wrong
                                let newValue10 = newValue.substring(0, 10);
                                let newValue10Checknumber = utilities.generateUnitNumberCheckNumber(newValue10);
                                if (newValue.substring(10) != newValue10Checknumber) {
                                    store.addNotificationMessage("Unit number <b>" + newValue + "</b> has wrong check number", 'warning');
                                }
                                return newValue;
                            } else {
                                return newValue;
                            }
                        }
                    }
                }
            },
            {
                headerName: 'Yard Position', field: 'position', hide: false,
                cellEditor: 'agTextCellEditor'
            },
            {
                headerName: 'Customer', field: 'matched_shipping_line', hide: hideCustomerColumn,
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: { valueList: [] },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSetFilter,
                filterParams: {},
                //Floating
                floatingFilterComponent: GridSetFloatingFilter,
                floatingFilterComponentParams: {},
            },
            {
                headerName: 'Build Year', field: 'construction_year', hide: false,
                editable: false,
                cellEditor: 'agTextCellEditor'
            },
            {
                headerName: 'ISO Code', field: 'iso_code', hide: true,
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: {
                    valueList: [],
                    default: YamaxValidator.isRequired('iso_code') ? 'first' : false
                },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSetFilter,
                filterParams: {},
                //Floating
                floatingFilterComponent: GridSetFloatingFilter,
                floatingFilterComponentParams: {},
            },
            {
                headerName: 'Facility/Depot', field: 'depot_id', hide: true,
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: {
                    valueList: [],
                    default: YamaxValidator.isRequired('depot_id') ? 'first' : false
                },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSetFilter,
                filterParams: {},
                //Floating
                floatingFilterComponent: GridSetFloatingFilter,
                floatingFilterComponentParams: {},
            },
            {
                headerName: 'Leasing Company', field: 'matched_leasing_company', hide: true,
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: { valueList: [] },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSetFilter,
                filterParams: {},
                //Floating
                floatingFilterComponent: GridSetFloatingFilter,
                floatingFilterComponentParams: {},
            },
            {
                headerName: 'Gate-In Date', field: 'gate_in_date', hide: true,
                editable: false,
                cellEditor: 'agTextCellEditor',
                valueFormatter: function (params) {
                    return params.value === '' ? '' : utilities.formatDateTime(params.value);
                },
            },
            {
                headerName: 'Inbound Transport Mode', field: 'ib_actual_visit_mode', hide: true,
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: { valueList: [] },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSetFilter,
                filterParams: {},
                //Floating
                floatingFilterComponent: GridSetFloatingFilter,  //CellFloatingFilter
                floatingFilterComponentParams: {},
            },
            {
                headerName: 'Inbound Carrier', field: 'inbound_carrier_id', hide: true,
                //hide: props.reference.type==='release',
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: { valueList: [] },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSetFilter,
                filterParams: {},
                //Floating
                floatingFilterComponent: GridSetFloatingFilter,  //CellFloatingFilter
                floatingFilterComponentParams: {}
            },
            {
                headerName: 'Inbound Transport ID', field: 'ib_actual_visit', hide: true,
                cellEditor: 'agTextCellEditor'
            },
            {
                headerName: 'Gate-Out Date', field: 'gate_out_date', hide: true,
                cellEditor: DatePicker,
                cellEditorParams: {
                    'format': 'datetime',
                    config: {
                        maxDate: dayJs().toDate()
                    }
                },
                comparator: utilities.dateComparator,
                valueFormatter: function (params) {
                    return params.value === '' ? '' : utilities.formatDateTime(params.value);
                },
            },
            {
                headerName: 'Outbound Transport Mode', field: 'ob_actual_visit_mode', hide: true,
                editable: false,
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: { valueList: [] },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSetFilter,
                filterParams: {},
                //Floating
                floatingFilterComponent: GridSetFloatingFilter,  //CellFloatingFilter
                floatingFilterComponentParams: {},
            },
            {
                headerName: 'Outbound Carrier', field: 'outbound_carrier_id', hide: true,
                //hide: props.reference.type==='return',
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: { valueList: [] },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSetFilter,
                filterParams: {},
                //Floating
                floatingFilterComponent: GridSetFloatingFilter,  //CellFloatingFilter
                floatingFilterComponentParams: {}
            },
            {
                headerName: 'Outbound Transport ID', field: 'ob_actual_visit', hide: true,
                editable: false,
                cellEditor: 'agTextCellEditor'
            },
            {
                headerName: 'Cleaning Status', field: 'cleaning_status', hide: false,
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: { valueList: [] },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSetFilter,
                filterParams: {},
                //Floating
                floatingFilterComponent: GridSetFloatingFilter,  //CellFloatingFilter
                floatingFilterComponentParams: {}
            },
            {
                headerName: 'Age', field: 'age', hide: false,
                editable: false,
                valueFormatter: function (params) {
                    if (params.data.construction_year) {
                        //return diffrence in years and months bettween today and construction_year
                        let today = dayJs();
                        let moment = require('moment');
                        let construction_date = moment(params.data.construction_year, "MM-YYYY");
                        let construction_year = dayJs(construction_date, "MM-YYYY");
                        let years = today.diff(construction_year, 'year');
                        construction_year.add(years, 'years');
                        let months = today.diff(construction_year, 'months') - years * 12;
                        if (years || months) {
                            return years + 'y ' + months + 'm';
                        }
                        return '?';
                    } else {
                        return '';
                    }
                }
            },
            {
                headerName: 'Days in Depot', field: 'days_in', hide: true,
                editable: false,
                valueFormatter: function (params) {
                    if (params.data.gate_in_date) {
                        return dayJs().diff(dayJs(params.data.gate_in_date), 'days');
                    } else {
                        return "";
                    }
                }
            },
            {
                headerName: 'Color', field: 'color', hide: true,
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: { valueList: [] },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSetFilter,
                filterParams: {},
                //Floating
                floatingFilterComponent: GridSetFloatingFilter,  //CellFloatingFilter
                floatingFilterComponentParams: {}
            },
            {
                headerName: 'Floor', field: 'floor', hide: true,
                cellEditor: GridSelectEditorNeo,
                cellEditorParams: { valueList: [] },
                valueFormatter: common.lookupName,
                valueParser: common.lookupKey,
                //Filter
                filter: GridSetFilter,
                filterParams: {},
                //Floating
                floatingFilterComponent: GridSetFloatingFilter,  //CellFloatingFilter
                floatingFilterComponentParams: {}
            },
            {
                headerName: '', field: 'temperature', hide: false,
                cellEditor: 'agTextCellEditor',
                headerComponentParams: { template: '<span><i large class="mdi-thermometer mdi" style="font-size: 1.5rem"></em>' },
                tooltipComponentParams: {
                    type: 'simple'
                },
                headerTooltip: 'Temperature'

            },
            {
                headerName: '', field: 'humidity', hide: false,
                cellEditor: 'agTextCellEditor',
                headerComponentParams: { template: '<span><i class="mdi-water-percent mdi" style="font-size: 1.5rem"></em></span>' },
                tooltipComponentParams: {
                    type: 'simple'
                },
                headerTooltip: 'Humidity',
            },
            {
                headerName: '', field: 'ventilation', hide: false,
                cellEditor: 'agTextCellEditor',
                headerComponentParams: { template: '<span><i class="mdi-fan mdi" style="font-size: 1.5rem"-procent></em></span>' },
                tooltipComponentParams: {
                    type: 'simple'
                },
                headerTooltip: 'Ventilation',
            },
            {
                headerName: 'Comment', field: 'comment', hide: false,
                cellEditor: 'agTextCellEditor',
                minWidth: 100
            },
            {
                headerName: 'Crun ID', field: 'crun_id',
                hide: true,
                editable: false,
                cellEditor: 'agTextCellEditor',

            },
            {
                headerName: 'Created', field: 'created_at', hide: true,
                editable: false,
                cellEditor: 'agTextCellEditor',
                valueFormatter: function (params) {
                    return utilities.isDateTimeValid(params.value) ? utilities.formatDateTime(params.value) : '';
                },
            },
            {
                headerName: 'Updated', field: 'updated_at', hide: true,
                flex: 1,
                minWidth: 150,
                resizable: false,
                suppressSizeToFit: true,
                editable: false,
                cellEditor: 'agTextCellEditor',
                valueFormatter: function (params) {
                    return params.value === '' ? '' : utilities.formatDateTime(params.value);
                },
            },
            {
                headerName: 'System', field: 'system', hide: true,
                cellEditor: 'agTextCellEditor',

            },
            {
                headerName: 'Line Operator', field: 'operator_name', hide: true,
                cellEditor: 'agTextCellEditor'
            },
            {
                headerName: 'Yard Position', field: 'position', hide: true,
                cellEditor: 'agTextCellEditor'
            },
            {
                headerName: 'Condition', field: 'condition', hide: true,
                cellEditor: 'agTextCellEditor'
            },
            {
                headerName: 'Last OnHire Date', field: 'last_on_hire_date', hide: true,
                cellEditor: 'agTextCellEditor'
            },
            {
                headerName: 'Cleaning Data', field: 'report_report', hide: true,
            }
            ]
        ];

    }

    const hideCustomerColumn = computed(() => {
        // get the user permissions
        const userPermissions = store.user.permissions;
        // check if the user has the permission to view the customer column
        const hasPermission = userPermissions.includes('System Root/CSO Stacker App/Customer Column-Read');
        // return true if the user has the permission, false otherwise
        return !hasPermission;
    });

    return {
        getYamaxStockListDefinition
    };

}