<template>
    <div style="display: flex; justify-content: center;">

        <div v-if="gate_in_visuals!=null" style="flex: 1;">
            <a :href="value_in" target='_blank' rel="noreferrer noopener" @click="cancelOpen($event, value_in)">
                <v-badge v-if="props.params.data.gate_in_job_status_id!=4" content="" color="white">
                    <template #badge>
                        <v-icon dense color="error" :icon="gate_in_visuals.status_icon" v-tippy="{ content: gate_in_visuals.tooltip }"></v-icon>
                    </template>
                    <span>
                        <v-icon dense color="primary" :class="gate_in_visuals.class" v-tippy="{ content: gate_in_visuals.tooltip }">mdi-login-variant</v-icon>
                    </span>
                </v-badge>
                <v-icon v-else dense color="primary" :class="gate_in_visuals.class" v-tippy="{ content: gate_in_visuals.tooltip }">mdi-login-variant</v-icon>
            </a>
        </div>

        <div v-if="gate_out_visuals!=null" style="flex: 1;">
            <a :href="value_out" target='_blank' rel="noreferrer noopener" @click="cancelOpen($event, value_out)">
                <v-badge v-if="props.params.data.gate_out_job_status_id!=4" content="" color="white">
                    <template #badge>
                        <v-icon dense color="error" :icon="gate_out_visuals.status_icon" v-tippy="{ content: gate_out_visuals.tooltip }"></v-icon>
                    </template>
                    <span>
                        <v-icon dense color="primary" :class="gate_out_visuals.class" v-tippy="{ content: gate_out_visuals.tooltip }">mdi-logout-variant</v-icon>
                    </span>
                </v-badge>
                <v-icon v-else dense color="primary" :class="gate_out_visuals.class" v-tippy="{ content: gate_out_visuals.tooltip }">mdi-logout-variant</v-icon>
            </a>
        </div>
    </div>

</template>

<script setup>



    //IMPORTS
    import {ref, defineProps, onBeforeMount} from 'vue';
    import dayJs from 'dayjs';



    //PROPS
    const props = defineProps({
        params:{}
    })



    //CONSTANTS
    const value_in = ref(null);
    const value_out = ref(null);
    const gate_in_visuals = ref(null);
    const gate_out_visuals = ref(null);



    //EVENTS
    onBeforeMount(() => {
        if (props.params.data.stock_container_status_id!=1){
            let visuals;
            gate_in_visuals.value = getVisuals(props.params.data.gate_in_job_status_id, 'in');        
            gate_in_visuals.value.class='';
            if (props.params.data.gate_in_job_status_id==4 && dayJs(props.params.data.gate_in_date)>dayJs('2023-02-11') ){
                //value_in.value = global.config.the_flow_url +'/document/download/gate_in/' + props.params.data.id;
            }else{
                //value_in.value = null;
                gate_in_visuals.value.class = "disabled-with-events";
            }

            value_in.value = global.config.the_flow_url +'/document/download/gate_in/' + props.params.data.id;
        }

        if (props.params.data.stock_container_status_id!=1 && props.params.data.stock_container_status_id!=2){
            gate_out_visuals.value = getVisuals(props.params.data.gate_out_job_status_id, 'out');        
            gate_out_visuals.value.class='';
            
            if (props.params.data.gate_out_job_status_id==4){
                //value_out.value = global.config.the_flow_url +'/document/download/gate_out/' + props.params.data.id;
            }else{
                //value_in.value = null;
                gate_out_visuals.value.class = "disabled-with-events";
            }
            value_out.value = global.config.the_flow_url +'/document/download/gate_out/' + props.params.data.id;
        }
    });



    //METHODS
    const cancelOpen = (event, v) => {
        if (v==null) event.preventDefault();
    }
    const getVisuals = (jobStatus, type) => {
        switch (jobStatus){
            case null:
                return {
                    tooltip: 'No job assigned',
                    status_icon: 'mdi-close-circle'
                };
            case 1:
                return {
                    tooltip: 'Sending assigned',
                    status_icon: 'mdi-plus-circle'
                };
            case 2:
                return {
                    tooltip: 'Sending scheduled',
                    status_icon: 'mdi-clock-outline'
                };
            case 3:
                return {
                    tooltip: 'Sending in progress',
                    status_icon: 'mdi-progress-clock'
                };
            case 4:
                return {
                    tooltip: 'Open Gate-'+type+' receipt',
                    status_icon: ''
                };
            case 5:
                return {
                    tooltip: 'Sending failed',
                    status_icon: 'mdi-alert-circle'
                };
        }
    }
 </script>