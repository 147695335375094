<template>
    <div class="main-content-area">
        <div class="main-content-toolbar">
            <v-toolbar :height="50">
                <v-btn icon="mdi-content-save" size="small" variant="text" 
                       @click="saveSettings"></v-btn>
            </v-toolbar>
            <v-toolbar :height="50">
                <h3>Settings</h3>
            </v-toolbar>
            <v-toolbar :height="50">
                <v-btn icon="mdi-close-thick" size="small" variant="text" @click="closeSettings"></v-btn>
            </v-toolbar>
        </div>
        <div class="main-content-playground">
            <!--Id is important for the few jquery selects that we are doing below in order not to affect another grid-->
            <ag-grid-vue
                id="settingsGrid"
                ref="settingsGrid"
                style="width: 100%; height: 100%;"
                class="ag-theme-alpine"
                :columnDefs="columnDefs"
                :defaultColDef="defaultColDef"
                :components="components"
                :rowData="data.rowData"
                :gridOptions="gridOptions"
                :editType="editType"
                @contextmenu="stopEditingOnRightclick"
                >
            </ag-grid-vue>
        </div>
        <div class="main-content-footer">
            <AppMessageNotifications />
            <v-toolbar :height="40">
            </v-toolbar>            
            <v-toolbar :height="40">
            </v-toolbar>            
        </div>
    </div>
</template>

<script setup>



    //IMPORTS
    import jQuery from "jquery";
    import { inject, ref, reactive } from 'vue'
 
    import { useAppStore } from '@/stores/app'

    import DataLoading from '@/components/ui/DataLoading';
    import NoData from '@/components/ui/NoData';
    import GridTooltip from '@/components/aggrid/GridTooltip';
    import { Preferences } from '@capacitor/preferences';
    import { useRouter } from 'vue-router';
    import { useCommon } from '@/components/aggrid/common/useCommon';
    import { AgGridVue } from "ag-grid-vue3";

    
    
    //REFEENCES
    const settingsGrid = ref(null);


    
    //INJECTIONS
    const axios=inject('axios');
    const router = useRouter();
    
    

    //VARIABLES
    let gridApi=null;
    let gridApi2=ref(null);
    let columnApi=null;
    
    

    //CONSTANTS
    const $ = jQuery;
    const store = useAppStore()
    const editType='fullRow';
    const data = reactive({
        rowData: null
    })
    const state = reactive({
        dialog: false,
        firstVisibleColumn: null
    })
    const components = {
        AgGridVue
    };



    //COMPOSABLES
    const { common } = useCommon(settingsGrid, state, gridApi2);



    //CONSTANTS
    const columnDefs=ref([])
    const defaultColDef=reactive({
        resizable: true,
        editable: true,
        sortable: true,
        filter: false,
        floatingFilter: false,
        suppressMenu: true,        //To be set to true, hides filter in header
        filterParams: {
            debounceMs: 500,
            suppressAndOrCondition: true
        },
        floatingFilterComponentParams: {
            suppressFilterButton: true //To be set to true, hides filter button in floating filter
        },
        tooltipComponent: 'GridTooltip',
        suppressKeyboardEvent: function(params) {
            let event = params.event;
            let key = event.key;
            let deniedKeys = ['Escape'];
            let suppress = params.editing && deniedKeys.indexOf(key) >= 0;
            return suppress;
        },
    })
    const gridOptions = /*reactive(*/{
        pagination: false,
        stopEditingWhenCellsLoseFocus: false,
        enableCellChangeFlash: false,
        suppressAutoSize: false,
        suppressColumnVirtualisation: true,
        skipHeaderOnAutoSize: false,
        suppressClickEdit: true,
        tooltipShowDelay: 0,
        tooltipHideDelay: 10000,
        getRowNodeId: d => { return d.id; },
        rowClassRules: {
            "unvalidated": params => !params.data.valid
        },
        loadingOverlayComponent: DataLoading,
        loadingOverlayComponentParams: {
            type: 2
        },        
        noRowsOverlayComponent: NoData,
        
        onCellContextMenu: (params) => {
            gridApi.startEditingCell({rowIndex: params.rowIndex, colKey: state.firstVisibleColumn, rowPinned: params.rowPinned});
        },
        
        onGridReady: (params) => {
            gridApi = params.api;
            gridApi2.value = params.api;
            columnApi = params.columnApi;

            columnDefs.value = [

                {headerName: 'Valid', field: 'valid', 
                    hide: true
                },
                
                {headerName: 'Id', field: 'id', 
                    hide: true,
                    editable: false
                },

                {headerName: 'Name', field: 'name', 
                    editable: false
                },

                {headerName: 'Key', field: 'key', 
                    editable: false
                },

                {headerName: 'Value', field: 'value', 
                    flex: 1,
                    cellEditor: 'agTextCellEditor'
                },
            ]
            state.firstVisibleColumn = common.getFirstVisibleColumn(columnDefs);
            populateGridWithData();
        },
        onRowDataChanged: (params) => {
            columnApi.autoSizeAllColumns();
        },
        
        onCellValueChanged(params){
            columnApi.autoSizeColumns([params.column.getColId()], false/*Skip headers*/);
            if (params.newValue!=''){
                const setName = async () => {
                  await Preferences.set({
                    key: params.data.key,
                    value: params.newValue,
                  });
                  
                  if (params.data.key=='the_flow_url'){
                      global.config.the_flow_url=params.newValue;
                      axios.defaults.baseURL = global.config.the_flow_url;
                  }
                      
                };                
                setName();
            }else{
                const removeName = async () => {
                  await Preferences.remove({ key: params.data.key });
                };                
                removeName();
            }
        },
        onComponentStateChanged(params){},
        onRowEditingStarted: (params) => {},
        onRowEditingStopped: (params) => {},
        onRowDataUpdated: (params) => {},
        onRowValueChanged: (params) => {},
        onCellMouseDown: (params) =>{
            params.event.preventDefault();
        },        
    }
    
    
    
    //EVENTS
    
    
    
    //METHODS
    const populateGridWithData = () => {
        setupGrid([
            {
                id: 1,
                name: 'Flow Url',
                key:  'the_flow_url',
                value: global.config.the_flow_url
            },
            {
                id: 2,
                name: 'Username',
                key:  'username',
                value: '1'
            },
            {
                id: 3,
                name: 'Password',
                key:  'password',
                value: '2'
            }            
        ]);
    }

    const setupGrid = (response) => {
        if (data.rowData==null){
            data.rowData=[];
        }
        response.map(item=>{
            data.rowData.push({
                valid: true,
                id: item.id,
                name: item.name, 
                key: item.key,
                value: item.value
            });
        });
    }
    
    const closeSettings = () => {
        router.push({ name: 'RsoApp' });
    }
    
    const saveSettings = () => {
        gridApi.stopEditing();
    }
    
    const stopEditingOnRightclick = () => {
        gridApi.stopEditing();
    }
    
    const validateRecord = (params) => {    
        return true;
    }
    
</script>