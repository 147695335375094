import { useCommon } from '@/components/aggrid/common/useCommon';
import GridSelectEditorNeo from '@/components/aggrid/cell_editors/GridSelectEditorNeo';
import GridTextEditor from '@/components/aggrid/cell_editors/GridTextEditor';
import GridSetFilter from '@/components/aggrid/filters/GridSetFilter';
import GridSetFloatingFilter from '@/components/aggrid/floating_filters/GridSetFloatingFilter';
import DatePicker from '@/components/aggrid/cell_editors/GridDateEditor';
import ReportCellRenderer from '@/components/aggrid/cell_renderers/ReportCellRenderer';
import GateInOutReceiptCellRenderer from '@/components/aggrid/cell_renderers/GateInOutReceiptCellRenderer';
import SendTestMail from '@/components/admin/SendTestMail';
import TestComponent from '@/components/admin/TestComponent';



export function useAdminPageDefinition(store, dayJs) {

    const getAdminPageDefinition = (modelSubmodelMap) => {

        const { common } = useCommon();

        const toolTipValueGetter = (params) => {
            return params.value
        };

        return [
            ...common.columnDefs(),
            ...[
            {
                headerName: 'Id', field: 'id', hide: false,
                editable: false,
                sortable: true,
                sort: "asc",
                filterParams: {
                    allowedCharPattern: '\\d\\-\\,',
                    numberParser: text => {
                        return text == null ? null : parseFloat(text.replace(',', '.'));
                    }
                }
            },
            {
                headerName: 'Option', field: 'name',
                editable: false,
                cellEditor: 'agTextCellEditor'
            },

            //Setting Value
            {
                headerName: 'Value', field: 'value',
                hide: false,
                editable: true,

                cellEditorSelector: function(params){
                    let optionId = params.data.id;
                    switch (optionId){
                        case 1: 
                            return { component: GridSelectEditorNeo };
                        case 2: 
                            return { component: GridTextEditor };
                        default:
                            return { component: GridTextEditor };
                    }
                },

                cellEditorParams: function(params){
                    let optionId = params.data.id;
                    let cellEditorParams = {};
                    switch (optionId){
                        case 1: 
                            cellEditorParams.valueList = params.data.options;
                            break;
                    }
                    return cellEditorParams;
                },

                /*cellRenderer: function(params){
                    let value = params.data.value;
                    switch (value){
                        case 1: 
                            return "Val: " + value;
                    }
                    return value;
                },
                cellRendererParams: {
                },*/

                valueFormatter: function(params){
                    let optionId = params.data.id;
                    switch (optionId){
                        case 1: 
                            return common.lookupName(params);
                        case 2:
                        case 3:
                            return "";
                    }
                    return params.value;
                },
                valueParser: function(params){
                    let optionId = params.data.id;
                    switch (optionId){
                        case 1: 
                            return common.lookupKey(params);
                    }
                    return params.value;
                }
            },

            //Setting Actions
            {
                headerName: '', field: 'actions',
                editable: false,
                cellRendererSelector: function(params){
                    let optionId = params.data.id;
                    switch (optionId){
                        case 2: 
                            return { component: SendTestMail, params: {} };
                        default:
                            return "";
                    }
                }
            },
            
            {
                headerName: '', field: 'options',
                hide: true,
                editable: false
            },
            {
                headerName: 'Description', field: 'description',
                editable: false,
                width: 700
            }
            ]
        ];

    }

    return {
        getAdminPageDefinition
    };

}