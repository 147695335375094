<template>
    {{ icon.message }}
    <v-tooltip v-if="icon.message!=null" open-delay="500" transition="none">
        <template v-slot:activator="{ props }">
            <v-icon :color="icon.color" :style="icon.styles" :class="icon.classes" v-bind="props"> {{`${icon.value}`}} </v-icon>
        </template>
        <span>{{ icon.message }}</span>
    </v-tooltip>
    <v-icon v-else :color="icon.color" :style="icon.styles" :class="icon.classes" v-bind="props"> {{`${icon.value}`}} </v-icon>
</template>

<script>
import { reactive, onMounted } from 'vue';    
    


export default {
        name: 'GridIconRenderer',
		setup(props, context){
            // //PROPS

            

            //CONSTANTS
            const icon=reactive({
                value: null,
                color: null,
                styles: null,
                classes: null, 
                message: null
            });
            
            onMounted(() => {
                let val=props.params[props.params.colDef.cellRendererParams.valueToUse];
                let valueList=props.params.colDef.cellRendererParams.valueList;
                if (valueList[val]){
                    icon.value=valueList[val].icon;
                    icon.color=valueList[val].color;
                    icon.styles=valueList[val].styles;
                    icon.classes=valueList[val].classes;
                    icon.message=valueList[val].message;
                }
            });
            
            const onParentModelChanged = (parentModel) => {
                // When the filter is empty we will receive a null value here
            }
            
            return {
                props,
                icon,
                onParentModelChanged
            }

        }
}
    
</script>