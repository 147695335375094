<template>
    <Teleport to="#app">
    <div v-if="backdrop==true" class="dialog-backdrop" :always-on-top="props.alwaysOnTop=='true'?'true':null" :style="{zIndex: state.zIndex, display: (props.show!=true)?'none':'flex'}">
    </div>
    <div class="dialog-container" 
         :always-on-top="props.alwaysOnTop=='true'?'true':null" 
         :style="{zIndex: state.zIndex+1, display: (props.show!=true)?'none':'flex'}"
         @click="hideOnClick"
         >
        <div 
            class="dialog-wrapper" 
            :style="{'width': dialogWidth, 'height': dialogHeight}"
            @mouseleave="hideOnMouseLeave"
            >
            <div v-if="props.dialogHeader==true" class="dialog-header">
                <div class="dialog-toolbar-left">
                    <slot name="dialog-header-left"/>
                </div>
                <div class="dialog-title">
                    {{ dialogTitle }}
                </div>
                <div class="dialog-toolbar-right">
                    <slot name="dialog-header-right"/>
                    <!--<v-btn v-for="(button, index) in dialogButtons" v-bind:key="index" v-bind:icon="button.icon" size="small" variant="text" @click="$emit(button.emit)"></v-btn>
                    <v-btn v-if="dialogButtons.length==0" icon="mdi-close-thick" size="small" variant="text" @click="$emit('close')"></v-btn>-->
                </div>
            </div>
            <div class="dialog-content">
                <slot name="content"/>
            </div>
            <div v-if="dialogFooter===true" class="dialog-footer">
                <div class="dialog-footer-left">
                    <slot name="footer-left"/>
                </div>
                <div>
                    <slot name="dialog-footer-center"/>
                </div>
                <div class="dialog-footer-right">
                    <slot name="footer-right"/>
                </div>
            </div>
        </div>
    </div>
    </Teleport>
</template>

<script setup>
    
    
    
    //IMPORTS
    import { reactive, defineProps, defineEmits, computed, onMounted, onUnmounted, onBeforeMount, watch } from 'vue';
    import { useAppStore } from '@/stores/app'
    const store = useAppStore();
    
    
    
    //PROPS
    const props = defineProps({
        'show': {
            type: Boolean,
            required: false,
            default: false
        },
        zIndex: {
            type: Number,
            required: false,
            default: null
        },
        alwaysOnTop: {
            type: Boolean,
            required: false,
            default: false
        },
        'dialogTitle':{
            type: String,
            required: false,
            default: ''
        },
        'dialogWidth':{
            type: String,
            required: false,
            default: "25%"
        },
        'dialogHeight':{
            type: String,
            required: false,
            default: "50%"
        },
        'dialogButtons':{
            type: Array,
            default: () => []
        },
        'dialogFooter':{
            type: Boolean,
            required: false,
            default: false
        },
        'dialogHeader':{
            type: Boolean,
            required: false,
            default: true
        },
        'backdrop':{
            type: Boolean,
            required: false,
            default: true
        },
        'hideOnClick':{
            type: Boolean,
            required: false,
            default: false
        },
        'hideOnMouseLeave':{
            type: Boolean,
            required: false,
            default: false
        }
    });
    
    
    
    //EMITS
    const emits = defineEmits(['hide','show'])
    
    

    //CONSTANTS
    const state=reactive({
        zIndex: (props.zIndex!=null?props.zIndex:1),
        display: (!props.show)?'none':'block'
    });

    const getWidth = computed(() => {
        return props.dialogWidth;
    })

    const openDialog = () => {
        emits('show');
    }
    const closeDialog = () => {
        emits('hide');
    }
        
        
        
    //WATCHERS
    watch(() => state.display, (newDisplay) => {
        if (props.alwaysOnTop && newDisplay){
            state.zIndex = getMaxZIndex()+1;
        }
    }, { deep: true })

    
    
    //EVENTS
    onMounted(() => { })
    onUnmounted(() => { })
    onBeforeMount(() => {
        if (props.zIndex==null){
            state.zIndex = getMaxZIndex()+1;
        }
    })
    
    
    //METHODS
    function getMaxZIndex() {
        let max = Math.max(...Array.from(document.querySelectorAll('#app *:not(*[always-on-top])'), el => parseFloat(window.getComputedStyle(el).zIndex)).filter(zIndex => !Number.isNaN(zIndex)),0);
        return max<1000?1000:max;
    }        
    
    const hideOnClick = () => {
        if (props.hideOnClick=='true') {
            emits('hide');
        }
    }
    
    const hideOnMouseLeave = () => {
        if (props.hideOnMouseLeave=='true') {
            emits('hide');
        }
    }

</script>