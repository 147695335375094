import { useCommon } from '@/components/aggrid/common/useCommon';
import GridSelectEditorNeo from '@/components/aggrid/cell_editors/GridSelectEditorNeo';
import GridTextEditor from '@/components/aggrid/cell_editors/GridTextEditor';
import GridSetFilter from '@/components/aggrid/filters/GridSetFilter';
import GridSetFloatingFilter from '@/components/aggrid/floating_filters/GridSetFloatingFilter';
import DatePicker from '@/components/aggrid/cell_editors/GridDateEditor';
import ReportCellRenderer from '@/components/aggrid/cell_renderers/ReportCellRenderer';
import GateInOutReceiptCellRenderer from '@/components/aggrid/cell_renderers/GateInOutReceiptCellRenderer';


export function useSettingsPageDefinition(store, dayJs) {

    const getSettingsPageDefinition = () => {

        const { common } = useCommon();

        const toolTipValueGetter = (params) => {
            return params.value
        };

        return [
            ...common.columnDefs(),
            ...[
            {
                headerName: 'Status', field: 'valid',
                hide: true
            },
            {
                headerName: 'Task Status', field: 'task_status',
                hide: true, cellEditor: 'agTextCellEditor',
            },
            {
                headerName: 'Id', field: 'id', hide: true,
                editable: false,
                filterParams: {
                    allowedCharPattern: '\\d\\-\\,',
                    numberParser: text => {
                        return text == null ? null : parseFloat(text.replace(',', '.'));
                    }
                }
            },
            {
                headerName: 'ID', field: 'id', hide: true,
                cellEditor: 'agTextCellEditor',

            },
            {
                headerName: 'Setting', field: 'setting',
                cellEditor: 'agTextCellEditor'
            },
            {
                headerName: 'Value', field: 'value',
                editable: true,
                cellRenderer: ReportCellRenderer,
                cellRendererParams: {
                }
            }
            ]
        ];

    }

    return {
        getSettingsPageDefinition
    };

}