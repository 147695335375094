import { computed, ref, onUnmounted } from "vue";

let processing = false;
let notifications = [];
const currentNotification = ref(null);

export function useNotification() {
    
    let timeoutId = null;

    //Axios call
    const notify = (type, time, message, onComplete) => {
        notifications.push({
            type: type,
            time: time,
            message: message,
            onComplete: onComplete
        });
        process();
    };

    const process = () => {
        if (processing) {
            return;
        }

        processing = true;
        if (notifications.length > 0) {
            currentNotification.value = notifications.shift();

            timeoutId = setTimeout(function(){
                if (typeof currentNotification.value.onComplete === 'function') {
                    currentNotification.value.onComplete();
                }
                processing = false;
                currentNotification.value = null;
                process();
            }, currentNotification.value.time * 1000);
        }

        processing = false;

        onUnmounted(() => {
            clearTimeout(timeoutId);
        });        
    }

    const notification = computed(() => {
        return currentNotification.value;
    });

    return {
        notification: {
            notify,
            notification
        }
    };
            
}